<template>
  <div>
    <ModalWrapper v-model="isVisible">
      <CModal :visible="isVisible" backdrop="static" @close="close">
        <CModalHeader>
          <CModalTitle>{{ $t("user_settings") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <DynamicFormWrapper :form="form" @submit="onSubmit" />
          <div class="d-grid">
            <CButton
              variant="outline"
              color="success"
              class="mt-4"
              @click="showChangePasswordModal = true"
            >
              <i class="fa fa-lock me-2" />
              {{ $t("change_password") }}
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="close">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" type="submit" :form="form.id">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
    <FormModal
      :show="showChangePasswordModal"
      :title="$t('change_password')"
      :form="changePasswordForm"
      :ok-button-text="$t('save')"
      :close-after-submit="false"
      @reset="showChangePasswordModal = false"
      @submit="onChangePasswordSubmit"
    >
      <template #before-body>
        <span class="text-danger" v-html="errors.changePassword" />
      </template>
      <template #after-body>
        <span class="text-danger" v-html="errors.confirmPassword" />
      </template>
    </FormModal>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { watchEffect } from "vue"
import { userStore } from "@/store"
import type { User } from "@/interfaces"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import FormModal from "@/components/modals/FormModal.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()
interface Props {
  show: boolean
}

const { show = false } = defineProps<Props>()

const emit = defineEmits(["close", "updated", "open"])

let showChangePasswordModal = $ref(false)
const errors = $ref({
  confirmPassword: null,
  changePassword: null,
})
let changePasswordInputs = $ref({
  current_password: null,
  password: null,
  confirm_password: null,
})

let isVisible = $computed({
  get() {
    return show && !showChangePasswordModal
  },
  set(v: boolean) {
    emit(v ? "open" : "close")
  },
})
const form = $computed(() => ({
  id: "user-setting-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: userStore.current?.id,
    },
    username: {
      type: "text",
      label: i18n.t("username"),
      defaultValue: userStore.current?.username,
      disabled: true,
    },
    email: {
      type: "text",
      label: i18n.t("email"),
      defaultValue: userStore.current?.email,
      disabled: true,
    },
    first_name: {
      type: "text",
      label: i18n.t("first_name"),
      placeholder: "",
      defaultValue: userStore.current?.first_name,
    },
    middle_name: {
      type: "text",
      label: i18n.t("middle_name"),
      placeholder: "",
      defaultValue: userStore.current?.middle_name,
    },
    last_name: {
      type: "text",
      label: i18n.t("last_name"),
      placeholder: "",
      defaultValue: userStore.current?.last_name,
    },
  },
}))
const changePasswordForm = $computed(() => ({
  id: "user-change-password-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: userStore.current?.id,
    },
    current_password: {
      type: "password",
      label: i18n.t("old_password"),
      validations: ["required"],
      defaultValue: changePasswordInputs.current_password,
    },
    password: {
      type: "password",
      label: i18n.t("new_password"),
      validations: ["required"],
      defaultValue: changePasswordInputs.password,
    },
    confirm_password: {
      type: "password",
      label: i18n.t("confirm_password"),
      validations: ["required"],
      defaultValue: changePasswordInputs.confirm_password,
    },
  },
}))

watchEffect(() => {
  if (!showChangePasswordModal) {
    errors.changePassword = null
    errors.confirmPassword = null
  }
})

function onChangePasswordSubmit(values: any) {
  errors.confirmPassword = null
  errors.changePassword = null
  changePasswordInputs = {
    current_password: values.current_password,
    password: values.password,
    confirm_password: values.confirm_password,
  }

  if (values.password != values.confirm_password)
    return (errors.confirmPassword = i18n.t("retype_pass_not_same"))

  userStore.update({
    id: userStore.current.id,
    current_password: values.current_password,
    password: values.password,
  } as User)
    .then((data) => {
      userStore.current = data
      changePasswordInputs = {
        current_password: null,
        password: null,
        confirm_password: null,
      }
      showChangePasswordModal = false
    })
    .catch(() => {
      errors.changePassword = i18n.t("wrong_old_pasword")
    })
}

function onSubmit(values: User) {
  isVisible = false
  userStore.update(values).then((data) => {
    userStore.current = data
    emit("updated")
  }).catch()
}
function close() {
  isVisible = false
}
</script>
