<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('customers')"
      :subtitle="`${$t('all')} ${$t('customers')}`"
      :add-text="$t('add_customer')"
      :edit-text="$t('edit_customer')"
      :columns="tableColumns"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="customerStore.mappedData"
      :add="customerStore.add"
      :update="customerStore.update"
      :default-sort="{ column: 'name', ascending: true }"
    >
      <template #actions="{ row }">
        <div class="button-box col-lg-12 d-flex">
          <Popover
            :text="$t('add_customer_user')"
          >
            <CButton
              class="btn-space btn-sm"
              color="secondary"
              @click.stop="showModal('userAdd', getId(row))"
            >
              <i class="fa fa-user-plus" />
            </CButton>
          </Popover>
          <Popover
            :text="$t('view_customer_users')"
          >
            <CButton
              class="btn-space btn-sm"
              color="secondary"
              @click.stop="showModal('userList', getId(row))"
            >
              <i class="fa fa-users" />
            </CButton>
          </Popover>

          <Popover
            :text="$t('view_addresses')"
          >
            <CButton
              class="btn-space btn-sm"
              color="secondary"
              @click.stop="showModal('addressList', getId(row))"
            >
              <i class="fa fa-location-dot" />
            </CButton>
          </Popover>
          
          <Popover
            v-if="pricingEnabled"
            :text="$t('set_pricing')"
          >
            <CButton
              class="btn-space btn-sm"
              color="secondary"
              @click.stop="showModal('pricingPicker', getId(row))"
            >
              <i class="fa fa-money" />
            </CButton>
          </Popover>
        </div>
      </template>
      <template #manufacturers="{ row }">
        {{ (row?.manufacturers || []).map(m => m.name).join(", ") }}
      </template>
    </VTables>

    <FormModal
      :title="$t('add_user')"
      :show="showModals.userAdd"
      :form="userForm"
      @reset="showModals.userAdd = false"
      @submit="(values) => customerStore.addUser(selectedCustomer, values)"
    />
    <ModalWrapper v-model="showModals.userList">
      <CModal
        size="lg"
        :visible="showModals.userList"
        backdrop="static"
        @close="showModals.userList = false"
      >
        <CModalHeader>
          <CModalTitle>Customer's Users</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div v-if="customerStore.users?.length > 0">
            <CCard v-for="(customerUser, i) in customerStore.users" :key="i" class="mt-2">
              <CCardBody>
                <CRow>
                  <CCol>
                    <h5>
                      <i class="fa fa-user me-1" />
                      {{ customerUser.username }}
                    </h5>
                    <div class="small">{{ customerUser.id }}</div>
                  </CCol>
                  <CCol class="d-flex flex-row mt-1 justify-content-end">
                    <div v-if="customerUser.email" class="small mb-1 me-3">
                      {{ customerUser.email }}
                      <i class="fa fa-envelope ms-2" />
                    </div>
                    <div v-if="customerUser.first_name" class="small mb-1">
                      {{ customerUser.first_name }}
                    </div>
                    <div v-if="customerUser.middle_name" class="small mb-1">
                      {{ customerUser.middle_name }}
                    </div>
                    <div v-if="customerUser.last_name" class="small mb-1">
                      {{ customerUser.last_name }}
                    </div>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </div>
        </CModalBody>
        <CModalFooter class="justify-content-end">
          <CButton color="secondary" @click="showModals.userList = false">
            {{ $t("close") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
    <PricingPickerModal
      v-if="pricingEnabled"
      v-model="showModals.pricingPicker"
      :selected="getId(selectedCustomerObject?.pricing)"
      @save="v => customerStore.update({ id: selectedCustomer, pricing: v })"
    />
    <AddressListModal
      :show="showModals.addressList"
      :data="selectedCustomerObject?.addresses"
      :add="
        v => organizationStore.addAndActivateAddress(selectedCustomer, v).then(
          () => {
            customerStore.fetchOne(selectedCustomer)
            addressStore.fetchByOrganizationId(selectedCustomer)
          }
        )
      "
      :update="
        v => addressStore.update(v, {
          withoutOptimistic: true,
          onSuccess: () => addressStore.fetchByOrganizationId(selectedCustomer)
        })
      "
      @close="showModals.addressList = false"
      @open="showModals.addressList = true"
    />
  </div>
</template>

<script lang="ts">
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import type { Customer } from "@/interfaces"
import { customerStore, authStore, pricingStore, organizationStore, addressStore } from "@/store"
import { defineComponent } from 'vue'
import { getId } from '@/interfaces'
import PricingPickerModal from "@/components/modals/pricing/PricingPickerModal.vue"
import VTables from "@/components/VTables.vue"
import FormModal from "@/components/modals/FormModal.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"
import Popover from "@/components/Popover.vue"
import AddressListModal from "@/components/modals/address/AddressListModal.vue"
import { booleanCustomSorting, booleanFilter, booleanFilterOptions, generateFilterAlgorithm } from "@/libraries/helpers"
import { featureConfigs } from "@/configs"

export default defineComponent({
  name: "Customers",
  components: {
    PricingPickerModal,
    VTables,
    FormModal,
    ModalWrapper,
    Popover,
    AddressListModal
  },
  data() {
    return {
      selectedCustomer: null,
      showModals: {
        userAdd: false,
        userList: false,
        pricingPicker: false,
        addressList: false
      },
    }
  },
  computed: {
    tableColumns() {
      let columns = [
        "name",
      ]
      if (authStore.authenticatedUser?.is_admin) {
        columns = [
          ...columns,
          "manufacturers",
          "boolean__is_manufacturer",
        ]
      }
      columns = [
        ...columns,
        "email",
        "phone",
        "domain",
        "company_number",
        "tax_number",
        "actions"
      ]
      return columns
    },
    tableOptions() {
      return {
        headings: {
          name: this.$t("name"),
          email: this.$t("email"),
          phone: this.$t("phone"),
          company_number: this.$t("company_number"),
          tax_number: this.$t("company_tax"),
          ...(authStore.authenticatedUser?.is_admin
            ? {
                manufacturers: this.$t("manufacturers"),
                boolean__is_manufacturer: this.$t("is_manufacturer"),
              }
            : {}
          ),
          actions: this.$t("actions"),
        },
        filterable: [
          ...[
            "name",
            "email",
            "domain",
            "phone",
            "company_number",
            "tax_number",
          ],
          ...(authStore.authenticatedUser?.is_admin ? ["manufacturers", "boolean__is_manufacturer"] : [])
        ],
        listColumns: {
          ...(authStore.authenticatedUser?.is_admin ? { boolean__is_manufacturer: booleanFilterOptions } : {})
        },
        get filterAlgorithm() {
          return {
            ...(authStore.authenticatedUser?.is_admin
              ? {
                  manufacturers(row: Client, query: string) {
                    const str = (row.manufacturers || []).map(m => m.name).join(", ").toLowerCase()
                    return str.includes(query.toLowerCase())
                  },
                  ...generateFilterAlgorithm(this.filterable, "boolean"),
                }
              : {}
            )
          }
        },
        customFilters: [
          {
            name: "all",
            callback(row: Customer, query: string) {
              return [
                row.name || "",
                row.email || "",
                row.phone || "",
                row.domain || "",
                row.company_number || "",
                row.tax_number || "",
                (row.manufacturers || []).map(m => m.name).join(", ") || "",
              ]
                .join("###")
                .toLowerCase()
                .includes(query.toLowerCase())
            },
          },
        ],
        sortable: [
          ...["name", "email", "phone", "domain", "company_number", "tax_number"],
          ...(authStore.authenticatedUser?.is_admin ? ["boolean__is_manufacturer", "manufacturers"] : [])
        ],
        customSorting: {
          ...(authStore.authenticatedUser?.is_admin
            ? { 
                boolean__is_manufacturer: (ascending: boolean) => (a: Customer, b: Customer) =>
                  booleanCustomSorting(ascending, a, b, "is_manufacturer")
              }
            : {}
          ),
        },
      }
    },
    makeForm() {
      return (data?: Customer) => ({
        id: "customers-page-form",
        fields: {
          id: {
            type: "hidden",
            defaultValue: data?.id,
          },
          name: {
            type: "text",
            label: this.$t("name"),
            placeholder: "",
            validations: ["required"],
            defaultValue: data?.name,
          },
          email: {
            type: "email",
            label: this.$t("email"),
            placeholder: "",
            validations: ["email"],
            defaultValue: data?.email,
          },
          phone: {
            type: "text",
            label: this.$t("phone"),
            placeholder: "",
            defaultValue: data?.phone,
          },
          domain: {
            type: "text",
            label: this.$t("domain"),
            placeholder: "",
            defaultValue: data?.domain,
          },
          company_number: {
            type: "text",
            label: this.$t("company_number"),
            placeholder: "",
            validations: ["required"],
            defaultValue: data?.company_number,
          },
          tax_number: {
            type: "text",
            label: this.$t("company_tax"),
            placeholder: "",
            defaultValue: data?.tax_number,
          },
        },
      })
    },
    userForm() {
      return {
        id: "user-form",
        fields: {
          username: {
            type: "text",
            label: this.$t("username"),
            placeholder: "",
            validations: ["required"],
            defaultValue: null,
          },
          email: {
            type: "email",
            label: this.$t("email"),
            placeholder: "",
            validations: ["required", "email"],
            defaultValue: null,
          },
          password: {
            type: "text",
            label: this.$t("password"),
            placeholder: "",
            validations: ["required"],
            defaultValue: null,
          },
          first_name: {
            type: "text",
            label: this.$t("first_name"),
            placeholder: "",
            validations: ["required"],
            defaultValue: null,
          },
          middle_name: {
            type: "text",
            label: this.$t("middle_name"),
            placeholder: "",
            validations: ["required"],
            defaultValue: null,
          },
          last_name: {
            type: "text",
            label: this.$t("last_name"),
            placeholder: "",
            validations: ["required"],
            defaultValue: null,
          },
        },
      }
    },
    selectedCustomerObject() {
      return customerStore.mappedData.find((v) => v.id === getId(this.selectedCustomer))
    }
  },
  methods: {
    showModal(modal: string, customer_id: number) {
      this.showModals[modal] = true
      this.selectedCustomer = customer_id
      if (modal === "addressList") addressStore.fetchByOrganizationId(customer_id)
      if (modal === "userList") customerStore.fetchUsers(customer_id)
    },
  },
  mounted() {
    customerStore.fetchAll()
    if (featureConfigs.pricings?.enabled) pricingStore.fetchAll()
  },
  setup() {
    const i18n = useI18n()
    useMeta({ title: i18n.t("customers") })

    return {
      authStore,
      customerStore,
      pricingStore,
      getId,
      organizationStore,
      addressStore,
      pricingEnabled: featureConfigs.pricings?.enabled || false
    }
  },
})
</script>
