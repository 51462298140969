<template>
  <tfoot>
    <tr class="multi-edit-row">
      <td colspan="5">
        <strong>{{ $t("total_selected", { total: selectedBatches.length }) }}</strong>
      </td>
      <td>
        <div>
          <input
            id="multi-edit-quantity"
            v-model.number="selectedBatchesOptionChoices.quantity"
            type="number"
            class="form-control"
            min="1"
            style="width: 80px"
            @click.stop
            @update:model-value="multiUpdateQuantity(selectedBatches, $event)"
          />
        </div>
      </td>
      <td>
        <div>
          <ImprovedVueMultiselect
            id="multi-edit-material"
            v-model="selectedBatchesOptionChoices.material"
            track-by="id"
            :options="selectedBatchesOptions.materials"
            label="name"
            :multiple="false"
            :show-labels="true"
            :placeholder="$t('select_or_start_typing')"
            :max-height="200"
            open-direction="bottom"
            style="width: 250px"
            :scrollable-container-selector="MULTISELECT_CONTAINER"
            :calculate-left="getMultiselectLeft"
            :calculate-top="getMultiLineMultiselectTop"
            @update:model-value="multiUpdateStock($event || { type: 'material', id: null })"
          />
        </div>
      </td>
      <td>
        <div @click.stop>
          <ImprovedVueMultiselect
            id="multi-edit-thickness"
            v-model="selectedBatchesOptionChoices.thickness"
            track-by="value"
            :options="selectedBatchesOptions.thicknesses"
            label="name"
            :multiple="false"
            :show-labels="true"
            :placeholder="selectedBatchesOptions.thicknesses?.length ? $t('select_or_start_typing') : 'N/A'"
            :max-height="200"
            open-direction="bottom"
            :disabled="!selectedBatchesOptions.thicknesses?.length"
            style="width: 250px"
            :scrollable-container-selector="MULTISELECT_CONTAINER"
            :calculate-left="getMultiselectLeft"
            :calculate-top="getMultiLineMultiselectTop"
            @update:model-value="multiUpdateStock($event || { type: 'size', id: null })"
          />
        </div>
      </td>
      <td>
        <div @click.stop>
          <ImprovedVueMultiselect
            id="multi-edit-process"
            v-model="selectedBatchesOptionChoices.process"
            track-by="id"
            :options="selectedBatchesOptions.processes"
            label="name"
            :multiple="false"
            :show-labels="true"
            :placeholder="$t('select_or_start_typing')"
            :max-height="200"
            :disabled="selectedBatchesOptions.processes?.length === 1"
            open-direction="bottom"
            :allow-empty="false"
            style="width: 250px"
            :scrollable-container-selector="MULTISELECT_CONTAINER"
            :calculate-left="getMultiselectLeft"
            :calculate-top="getMultiLineMultiselectTop"
            @update:model-value="multiUpdateMachines($event)"
          />
        </div>
      </td>
      <td>
        <div @click.stop>
          <ImprovedVueMultiselect
            id="multi-edit-operations"
            v-model="selectedBatchesOptionChoices.operations"
            track-by="id"
            :options="selectedBatchesOptions.operations"
            label="name"
            :multiple="true"
            :show-labels="true"
            :placeholder="$t('select_or_start_typing')"
            :max-height="200"
            open-direction="bottom"
            style="width: 250px"
            :scrollable-container-selector="MULTISELECT_CONTAINER"
            :calculate-left="getMultiselectLeft"
            :calculate-top="getMultiLineMultiselectTop"
            @update:model-value="multiUpdateMachines($event)"
          >
            <template #tag="props">
              <span
                v-show="
                  props.option.is_visible ||
                  authStore.authenticatedUser?.is_manufacturer ||
                  authStore.authenticatedUser?.is_admin
                "
                :key="props.index"
                class="multiselect__tag"
              >
                <span>{{ props.option.name }}</span>
              </span>
            </template>
          </ImprovedVueMultiselect>
        </div>
      </td>
      <td>
        <div>
          <input
            id="multi-edit-reference"
            v-model="selectedBatchesOptionChoices['part.reference']"
            class="form-control"
            style="width:80px"
            @click.stop
            @update:model-value="multiUpdateReference(selectedBatches, $event)"
          />
        </div>
      </td>
      <td>
        <div @click.stop>
          <ImprovedVueMultiselect
            id="multi-edit-certificates"
            v-model="selectedBatchesOptionChoices.certificateOptions"
            :reposition="true"
            :options="
              selectedBatchesOptions.certificates.map(c => ({
                id: c,
                name: c.replace(/.*(\d)(\d)/, '$1.$2'),
              }))
            "
            track-by="id"
            label="name"
            :multiple="true"
            :show-labels="true"
            :placeholder="$t(selectedBatchesOptions.certificates.length > 0 ?'select_or_start_typing' : 'no_certificates_available')"
            :max-height="200"
            open-direction="bottom"
            style="width: 250px"
            :scrollable-container-selector="MULTISELECT_CONTAINER"
            :calculate-left="getMultiselectLeft"
            :calculate-top="getMultiLineMultiselectTop"
            :disabled="selectedBatchesOptions.certificates.length === 0"
            @update:model-value="multiUpdateCertificates($event.map(c => c.id))"
          />
        </div>
      </td>
      <td colspan="3" />
      <td class="text-center">
        <CButton @click.stop="deleteSelectedRows">
          <i class="fa fa-trash text-danger" />
        </CButton>
      </td>
    </tr>
  </tfoot>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { batchStore, authStore, partStore } from "@/store"
import { chunk, debounce, isEqual, at } from "lodash-es"
import sweetalert from "sweetalert2"
import type { InjectedBatchInterface } from "@/interfaces"
import pmap from "promise.map"
import { MULTISELECT_CONTAINER, getMultiselectLeft, getMultiLineMultiselectTop, getMultiselectTop } from "../helpers"
import ImprovedVueMultiselect from "@/components/ImprovedVueMultiselect.vue"

const optionsKeys = ["materials", "thicknesses", "processes", "operations", "certificates", "stock"] as const
type OptionsDict = {
  [key in typeof optionsKeys[number]]: InjectedBatchInterface["options"][key]
}

export default defineComponent({
  name: "MultiEditRow",
  props: {
    selectedBatches: {
      required: true,
      type: Array<InjectedBatchInterface>
    },
    updateStock: {
      required: true,
      type: Function
    },
    updateMachine: {
      required: true,
      type: Function
    }
  },
  components: {
    ImprovedVueMultiselect
  },
  emits: ["deleted"],
  setup() {
    const multiUpdateQuantity = debounce(async (selectedBatches: InjectedBatchInterface[], quantity) => {
      const selectedChunks = chunk(selectedBatches, 8)

      for (const chunk of selectedChunks) {
        await Promise.allSettled(chunk.map(batch => batchStore.update({ id: batch.id, quantity })))
      }
    }, 500)

    const multiUpdateReference = debounce(async (selectedBatches: InjectedBatchInterface[], reference) => {
      const selectedChunks = chunk(selectedBatches, 8)
      for (const chunk of selectedChunks) {
        await Promise.allSettled(
          chunk.map(batch =>
            batch.part ? partStore.update({ id: batch.part.id, reference }) : Promise.resolve()
          )
        )
      }
    }, 500)

    return {
      MULTISELECT_CONTAINER,
      getMultiselectTop,
      getMultiselectLeft,
      getMultiLineMultiselectTop,
      authStore,
      multiUpdateQuantity,
      multiUpdateReference
    }
  },
  computed: {
    selectedBatchesOptionChoices() {
      return this.selectedBatches?.reduce((choices, batch) => {
        const keys = Object.keys(choices)

        for (let key of keys) {
          const valueKey = ["material", "thickness"].includes(key) ? `injected_${key}` : key
          const val = at(batch as any, valueKey)[0]
          if (!isEqual(choices[key], val)) choices[key] = null
        }

        return choices
      }, {
        "quantity": this.selectedBatches[0].quantity,
        "material": this.selectedBatches[0].injected_material,
        "thickness": this.selectedBatches[0].injected_thickness,
        "process": this.selectedBatches[0].process,
        "operations": this.selectedBatches[0].operations,
        "part.reference": this.selectedBatches[0].part.reference,
        "certificateOptions": this.selectedBatches[0].certificateOptions,
      })
    },
    selectedBatchesOptions() {
      return this.selectedBatches
        ?.flatMap(batch => Object.entries(batch.options))
        .reduce<OptionsDict>((options, [key, option]) => {
          if (optionsKeys.includes(key as any)) {
            let identifier = "id"
            if (key === "thicknesses") identifier = "value"

            const ids = (option || []).map(o => key !== "certificates" ? o[identifier] : o)
            let existingValues = options[key]
            if (key === "materials" && existingValues) {
              const materialNames = existingValues.map(v =>  v.name.trim())
              existingValues = options["stock"].filter(stock => materialNames.includes(stock.name.trim()))
            }
            options[key] = (existingValues || [...option]).filter(v => ids.includes(key !== "certificates" ? v[identifier] : v))
          }
          return options
        }, {} as any)
    }
  },
  methods: {
    deleteSelectedRows() {
      sweetalert
        .fire({
          title: this.$t("remove"),
          text: this.$t("delete_rows_body"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(async result => {
          if (result.isConfirmed) {
            const chunks = chunk(this.selectedBatches, 8)

            for (const chunk of chunks) {
              await Promise.allSettled(chunk.map(batch => batchStore.remove(batch.id)))
            }
            this.$emit("deleted")
          }
        })
        .catch()
    },
    async multiUpdateStock(data: any) {
      let do_patch = true
      if (data.type !== "size" && !this.selectedBatchesOptionChoices.thickness) do_patch = false
      if (data.type === "size" && !this.selectedBatchesOptionChoices.material) do_patch = false
      pmap(this.selectedBatches, batch => this.updateStock(data, batch.id, do_patch), 8)
    },
    async  multiUpdateMachines(data) {
      const selectedChunks = chunk(this.selectedBatches, 8)

      for (const chunk of selectedChunks) {
        await Promise.allSettled(chunk.map(batch => this.updateMachine(data, batch.id)))
      }
    },
    async multiUpdateCertificates(certificates) {
      const selectedChunks = chunk(this.selectedBatches, 8)

      for (const chunk of selectedChunks) {
        await Promise.allSettled(
          chunk.map(batch => batchStore.update({ id: batch.id, certificates }))
        )
      }
    },
  },
})
</script>
