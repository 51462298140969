<template>
	<VueMultiselect
		ref="originalMultiselect"
		v-bind="$attrs"
		class="position-relative"
		@open="repositionDropDown"
  >
    <!--
      This is for handling slots added on component usage
    -->
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </VueMultiselect>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted } from 'vue'
import VueMultiselect from 'vue-multiselect'

interface Props {
  reposition?: Boolean
  scrollableContainerSelector?: keyof HTMLElementTagNameMap
  calculateTop?: (param: calculationParam) => number
  calculateLeft?: (param: calculationParam) => number
}

interface BodyParam {
  height: number
}
interface AppParam {
  scrollTop: number
  height: number
}
interface ElementParam {
  top: number
  width: number
}
interface ScrollableElementParam {
  left: number
}

interface calculationParam {
  body: BodyParam
  app: AppParam
  element: ElementParam
  scrollableElement
}

const {
  reposition = false,
  scrollableContainerSelector = null,
  calculateTop = () => 0,
  calculateLeft = () => 0
} = defineProps<Props>()
let scrollableContainer = $ref(null)
let originalMultiselect = $ref(null)

function repositionDropDown() {
  // reset
  if (originalMultiselect) {
    originalMultiselect.$refs.list.style.width = `120px`;
    originalMultiselect.$refs.list.style.height = `0px`;
      originalMultiselect.$refs.list.style.bottom = '0px';
  }
  nextTick(() => {
    const appElement = document.getElementById("app-body")
    const appElementBoundingRect: any = appElement.getBoundingClientRect() || {}

    const containerElement = appElement.children[0]
    const containerElementBoundingRect: any = containerElement?.getBoundingClientRect() || {}

    const element = originalMultiselect.$el.getBoundingClientRect();
    const width = originalMultiselect.$el.clientWidth;

    const param = {
      body: {
        height: document.body.offsetHeight
      },
      app: {
        top: containerElementBoundingRect?.top || 0,
        left: appElementBoundingRect?.left ? 225 : 0,
        scrollTop: appElement?.scrollTop || 0,
        height: appElementBoundingRect?.height || 0
      },
      element: {
        left: originalMultiselect.$el.offsetLeft,
        offsetTop: originalMultiselect.$el.offsetTop,
        top: element.top,
        width: originalMultiselect.$el.clientWidth,
        height: originalMultiselect.$refs.list.clientHeight
      },
      scrollableElement: {
        scrollLeft: scrollableContainer?.scrollLeft || 0,
        scrollTop: scrollableContainer?.scrollTop || 0,
      }
    }

    const left = calculateLeft(param)
    const top = calculateTop(param)
    if (originalMultiselect) {
      originalMultiselect.$refs.list.style.width = `${width}px`;
      originalMultiselect.$refs.list.style.minHeight = `100px`;
      originalMultiselect.$refs.list.style.height = `auto`;
      originalMultiselect.$refs.list.style.position = 'fixed';
      originalMultiselect.$refs.list.style.bottom = 'auto';
      originalMultiselect.$refs.list.style.top = `${top}px`;
      originalMultiselect.$refs.list.style.left = `${left}px`;
    }
    window.scrollTo(0,0)
  })
}

onMounted(() => {
  scrollableContainer =
    scrollableContainerSelector
      ? document.querySelector(scrollableContainerSelector) || window
      : window;

  scrollableContainer.addEventListener('scroll', repositionDropDown, { passive: true });
})

onUnmounted(() => {
  scrollableContainer.removeEventListener('scroll', repositionDropDown);
})
</script>