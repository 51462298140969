<template>
  <div class="app d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <p class="text-start logo-wrapper">
                    <img
                      class="navbar-brand-full"
                      :class="{ 'opacity-0': !manufacturerStore.logo }"
                      :src="manufacturerStore.logo"
                      height="50"
                      :alt="`${manufacturerStore.current?.name} logo`"
                      :data-fallback="BLANK_IMAGE_URL"
                      onerror="this.src=this.dataset.fallback;"
                    />
                  </p>
                  <p class="text-start text-muted">
                    {{ $t("sign_in_to_your_account") }}
                  </p>

                  <CInputGroup class="mb-3">
                    <CInputGroupText>
                      <CIcon icon="fa-user" />
                    </CInputGroupText>
                    <CFormInput
                      id="username-input"
                      v-model="username"
                      :placeholder="$t('username')"
                      :invalid="!!errors.username"
                      autocomplete="username"
                      @keyup.enter="checkForm"
                    />
                    <CFormFeedback invalid class="text-start">
                      {{ errors.username }}
                    </CFormFeedback>
                  </CInputGroup>
                  <CInputGroup>
                    <CInputGroupText>
                      <CIcon icon="fa-key" />
                    </CInputGroupText>
                    <CFormInput
                      id="password-input"
                      v-model="password"
                      type="password"
                      :placeholder="$t('password')"
                      :invalid="!!errors.password"
                      autocomplete="current-password"
                      @keyup.enter="checkForm"
                    />
                    <CFormFeedback invalid class="text-start">
                      {{ errors.password }}
                    </CFormFeedback>
                  </CInputGroup>

                  <CRow class="mt-4">
                    <CCol class="text-start">
                      <CButton color="primary" @click="checkForm">
                        {{ $t("login") }}
                      </CButton>
                      <CButton
                        color="primary"
                        class="ms-2 d-lg-none"
                        variant="outline"
                        @click="$router.push(register_url)"
                      >
                        {{ $t("register_now") }}!
                      </CButton>
                    </CCol>
                    <CCol class="d-none d-lg-flex align-items-center justify-content-end">
                      <a class="text-nowrap" :href="forgot_url">
                        {{ $t("forgot_password") }}?
                      </a>
                    </CCol>
                  </CRow>
                  <CRow class="d-lg-none mt-2">
                    <CCol class="text-end">
                      <a class="text-nowrap" :href="forgot_url">
                        {{ $t("forgot_password") }}?
                      </a>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard class="register text-white py-5 d-none d-lg-flex" style="width: 44%">
              <CCardBody class="text-center d-flex align-items-center">
                <div>
                  <h2>{{ $t("new_here") }}?</h2>
                  <p>{{ $t("sign_up_recommendation_text") }}</p>
                  <CButton color="primary" @click="router.push(register_url)">
                    {{ $t("register_now") }}!
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { onMounted } from "vue"
import { BLANK_IMAGE_URL } from "@/constants"
import { authStore, manufacturerStore, toastStore } from "@/store"

const i18n = useI18n()

useMeta({ title: i18n.t("login") })

const route = useRoute()
const router = useRouter()
const redirect = (route.query.returnTo as string) || "/dashboard"

// let account_locked = $ref(false)
let errors = $ref(
  {} as {
    username?: string
    password?: string
  }
)

const nickname = $ref((route.params.nickname || "") as string)
const prefix = nickname ? `/${nickname}` : ""

const username = $ref((route.query.username || "") as string)
const password = $ref("")

const forgot_url = $computed(() => {
  const url = `${prefix}/forgot`
  return username ? `${url}?account=${username}` : url
})

const register_url = $computed(() => {
  const url = `${prefix}/register`
  return username ? `${url}?account=${username}` : url
})

const submit = () => {
  authStore.login({ username, password })
    .then(() => router.push(redirect))
    .catch(() => toastStore.toasts.push({ color: "danger", message: i18n.t("wrong_username_or_password") }))
}

const checkForm = (event: UIEvent) => {
  event.preventDefault()

  let valid = true
  errors = {}

  if (!username) {
    errors.username = "Username required for login"
    valid = false
  }

  if (!password) {
    errors.password = "Password required for login"
    valid = false
  }

  if (valid) submit()
}

onMounted(() => {
  if (
    manufacturerStore.manufacturerNickname
    && route.path === "/login"
  ) {
    router.push(`/${manufacturerStore.manufacturerNickname}/login`)
  }
})
</script>

<style scoped>
.register {
  background-image: url("/img/bg/punch_scrap.jpeg");
  background-size: cover;
}
.app {
  background-image: url("/img/bg/pattern.svg");
  background-size: cover;
  height: 125vh;
}

.logo-wrapper {
  height: 50px;
}
</style>
