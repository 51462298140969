<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('webhooks')"
      :subtitle="$t('all') + ' ' + $t('webhooks')"
      :add-text="$t('add_webhook')"
      :edit-text="$t('edit_webhook')"
      :columns="tableColumns"
      :action-delete="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :make-form="makeForm"
      :merge-options="mergeOptions"
      :show-merge-options="true"
      :table-options="tableOptions"
      :data="webhookStore.all"
      :add="webhookStore.add"
      :update="webhookStore.update"
      :remove="({ id }) => webhookStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
      @merge-done="webhookStore.modifyData"
    >
      <template #events="{ row }">
        <span v-for="event in row.events" :key="event">
          <CBadge color="primary">{{ event }}</CBadge>
        </span>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { onMounted } from "vue"
import type { Webhook, MergerSchema } from "@/interfaces"
import { webhookEventOptions } from "@/interfaces"
import { webhookStore, authStore } from "@/store"
import VTables from "@/components/VTables.vue"
import {
  booleanCustomSorting,
  dateToFilterString,
  generateFilterAlgorithm,
  getListColumns,
} from "@/libraries/helpers"

const i18n = useI18n()

useMeta({ title: i18n.t("webhooks") })

const tableColumns = [
  "name",
  "endpoint",
  "events",
  "boolean__is_active",
  "signing_secret",
  "date__created",
  "date__updated",
]
const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    endpoint: i18n.t("endpoint"),
    events: i18n.t("events"),
    boolean__is_active: i18n.t("is_active"),
    signing_secret: i18n.t("signing_secret"),
    date__created: i18n.t("created"),
    date__updated: i18n.t("updated"),
  },
  sortable: tableColumns,
  filterable: tableColumns,
  get filterAlgorithm() {
    return {
      ...generateFilterAlgorithm(this.filterable, "boolean"),
      ...generateFilterAlgorithm(this.filterable, "date"),
    }
  },
  get listColumns() {
    return getListColumns(this.filterable)
  },
  customSorting: {
    boolean__is_active: (ascending: boolean) => (a: Webhook, b: Webhook) =>
      booleanCustomSorting(ascending, a, b, "is_active"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: Webhook, query: string) {
        return [
          row.name || "",
          row.endpoint || "",
          row.events || "",
          row.is_active,
          row.signing_secret || "",
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const makeForm = (data?: Webhook) => ({
  id: "webhooks-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      placeholder: "",
      defaultValue: data?.name,
      validations: ["required"],
    },
    endpoint: {
      type: "text",
      label: i18n.t("endpoint"),
      placeholder: "",
      defaultValue: data?.endpoint,
      validations: ["required"],
    },
    events: {
      type: "multiselect",
      label: i18n.t("events"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.events,
      mapResultTo: "id",
      options: [
        { id: "ORDER_CREATED", name: "order.created" },
        { id: "ORDER_UPDATED", name: "order.updated" },
        { id: "ORDER_DELETED", name: "order.deleted" },
      ],
      selectOptions: {
        multiple: true,
        trackBy: "id",
        label: "name",
        hideSelected: false,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
    },
    is_active: {
      type: "checkbox",
      label: i18n.t("is_active"),
      defaultValue: data?.is_active,
    },
  },
})

const mergeOptions = {
  whitelist: ["id", "name", "endpoint", "events", "is_active"],
  schema: {
    id: {
      type: "integer",
    },
    name: {
      type: "string",
    },
    endpoint: {
      type: "string",
    },
    events: {
      type: "array",
      options: [...webhookEventOptions],
    },
    is_active: {
      type: "boolean",
    },
  } as MergerSchema,
}
onMounted(() => {
  webhookStore.fetchAll()
})
</script>
