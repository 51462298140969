<template>
  <div class="container">
    <!-- x3d for displaying the model -->
    <x3d id="viewer" ref="viewer" show-stat="false" show-log="false">
      <scene>
        <TreeInline
          v-if="part.tree"
          :part-id="part.id"
          :tree="part.tree"
          @loaded="modelLoaded"
          @clicked="modelClicked"
        />
        <ModelInline v-else-if="model3D" ref="inlineModel" :part="part" />
      </scene>
    </x3d>

    <!-- x3d for displaying the xyz axes plane  -->
    <x3d id="origin" ref="origin" show-stat="false" show-log="false">
      <scene d-e-f="scene">
        <navigationinfo type="none" />
        <transform ref="transformation">
          <inline url="/models/CoordinateAxes.x3d" />
        </transform>
      </scene>
    </x3d>

    <!-- For displaying the list of items for assembly/folder -->
    <TreeItem
      v-if="part?.tree"
      class="assembly item"
      style="list-style-type: none"
      :tree="part?.tree"
      :selected="selectedTreeItem"
      @clicked="onTreeItemClicked"
      @toggled="onTreeItemToggled"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch } from "vue"
import { debounce as _debounce } from "lodash-es"
import type { Part } from "@/interfaces"
import { partStore } from "@/store"
import TreeItem from "./TreeItem.vue"
import TreeInline from "./TreeInline.vue"
import ModelInline from "@/components/part-image/ModelInline.vue"

type X3dColor = `${number},${number},${number}`

interface X3dMaterial extends HTMLElement {
  diffuseColor: X3dColor
}

interface Props {
  part: Part
}

const { part } = defineProps<Props>()

// $refs
const inlineModel = $ref<{ inlineElement: HTMLElement }>()
const origin = $ref<any>({})
const viewer = $ref<any>({})
const transformation = $ref(null)
let loading = $ref(!part.tree)
let selectedTreeItem: number = $ref(null)
let hiddenTreeItems: number[] = $ref([])

const model3D = $computed(() => partStore.all3DModels[part?.id])

watch(
  () => inlineModel,
  (newer, older) => {
    const childNodeInsertedListener = () => {
      inlineModel.inlineElement.removeEventListener(
        "DOMNodeInserted",
        childNodeInsertedListener
      )

      modelLoaded()
    }

    if (older?.inlineElement == null && newer?.inlineElement != null) {
      return inlineModel.inlineElement.addEventListener(
        "DOMNodeInserted",
        childNodeInsertedListener,
        false
      )
    }
  },
  { immediate: true }
)

const fitAll = _debounce(() => {
  if (loading) return
  if (!origin?.hasRuntime) return
  if (!viewer?.hasRuntime) return

  origin.runtime.fitAll()
  viewer.runtime.fitAll()
}, 250)

watch(() => [loading, origin, viewer], fitAll)

function modelLoaded() {
  const vp = viewer.querySelector("viewpoint")

  if (vp) {
    vp.addEventListener("viewpointChanged", updateView, false)
  }

  loading ? (loading = false) : fitAll()
}

function updateView(event) {
  const { orientation } = event
  transformation.rotation = `${[
    orientation[0].x,
    orientation[0].y,
    orientation[0].z,
    -orientation[1],
  ].join()} `
}

const onTreeItemClicked = _debounce((index: number) => {
  const newElement = document.getElementById(`model_${index}`)
  const oldElement = document.getElementById(`model_${selectedTreeItem}`)
  selectedTreeItem = selectedTreeItem !== index ? index : null

  if (oldElement !== null) {
    const oldMaterialElement: X3dMaterial = oldElement.querySelector(
      `Shape.solid > Appearance > Material`
    )
    if (oldMaterialElement !== null) {
      oldMaterialElement.diffuseColor = "0.65,0.65,0.65"
    }
  }

  if (selectedTreeItem && newElement !== null) {
    const newMaterialElement: X3dMaterial = newElement.querySelector(
      `Shape > Appearance > Material`
    )
    if (newMaterialElement !== null) {
      newMaterialElement.diffuseColor = "1,0.65,0"
    }
  }
}, 100)
function onTreeItemToggled(index: number) {
  if (hiddenTreeItems.includes(index))
    hiddenTreeItems = hiddenTreeItems.filter(v => v != index)
  else hiddenTreeItems.push(index)

  const partElement = document.getElementById(`model_${index}`)

  if (partElement !== null) {
    partElement.setAttribute("render", hiddenTreeItems.includes(index) ? "false" : "true")
  }
}
function modelClicked(e: X3dMouseEvent) {
  if (!part?.tree) return

  const { hitObject } = e
  if (hitObject !== undefined) {
    const parts = hitObject.parentElement.id.split("_")
    const index = parts[1]

    onTreeItemClicked(parseInt(index))
  }
}

onMounted(() => x3dom.reload())
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

#viewer {
  position: absolute;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#origin {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  width: 200px;
  height: 200px;
  pointer-events: none;
}

.assembly {
  pointer-events: none;
  position: absolute;
  overflow: scroll;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}
</style>
