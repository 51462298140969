<template>
  <div class="wrapped-with-lpypvnfoqk">
    <ModalWrapper v-model="isVisible">
      <CModal
        id="visible-column-modal"
        :visible="isVisible"
        backdrop="static"
        @close="isVisible = false"
      >
        <CModalHeader>
          <CModalTitle>Visible columns</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <VueDraggable
            v-model="localOptions"
            item-key="id"
            @start="drag = true"
            @end="drag = false"
          >
            <template #item="{ element }">
              <div class="mb-3">
                <div class="visible-column-modal__checkbox-container">
                  <!-- <CIcon icon="fa-up-down-left-right" style="vertical-align: 4; margin-right: -1rem" /> -->
                  <CFormCheck
                    :id="`checkbox-${element.name}`"
                    v-model="element.enabled"
                    class="form-check"
                    :button="undefined"
                    :label="capitalize(element.name)"
                    :checked="element.enabled"
                  />
                </div>
              </div>
            </template>
          </VueDraggable>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" @click="save">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import VueDraggable from "vuedraggable"
import { capitalize } from "lodash-es"
import { watch, onMounted } from "vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

interface Props {
  modelValue: any[]
  options: any
  show: boolean
}

const { modelValue = [], options = [], show = false } = defineProps<Props>()

const backupModelValue = modelValue
const emit = defineEmits(["update:modelValue", "show"])

const drag = $ref(false)
const value = {}
let localOptions = $ref(
  [...new Set(modelValue.concat(options))].map(option => ({
    name: option,
    enabled: modelValue.find(name => name === option),
  }))
)

let isVisible: any = $computed({
  get() {
    return show
  },
  set(v) {
    emit("show", v)
  },
})

watch(
  () => modelValue,
  () => {
    localOptions = localOptions.map(option => ({
      name: option.name,
      enabled: modelValue.find(name => name === option.name),
    }))
  },
  { deep: true }
)

function save() {
  isVisible = false
  emit(
    "update:modelValue",
    localOptions.filter(o => o.enabled).map(o => o.name)
  )
}

onMounted(() => {
  for (let i = 0; i < options.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(value, options[i]))
      value[options[i]] = modelValue.includes(options[i])
  }
})
</script>

<style lang="scss">
.wrapped-with-lpypvnfoqk {
  .visible-column-modal {
    &__checkbox-container {
      .form-check {
        display: inline-flex;
        input {
          cursor: pointer;
        }
        label {
          cursor: move;
          margin: 2px 0.5rem;
        }
      }
    }
  }
}
</style>
