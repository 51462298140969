<template>
  <div class="app d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :lg="5" :md="6" :sm="8">
          <CCard class="p-4">
            <CCardBody>
              <div class="d-flex justify-content-between mb-4 align-items-center">
                <h1>{{ $t("all_done") }}</h1>
                <CIcon icon="fa-check" size="4xl" />
              </div>
              <p class="text-muted">{{ $t("email_verified") }}</p>
              <CRow class="mt-4">
                <CCol class="text-end d-grid">
                  <CButton block color="primary" @click="router.push(login_url)">
                    {{ $t("login") }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { watchEffect } from "vue"

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

useMeta({ title: i18n.t("verify") })

const username = $computed(() => route.query.username || "")
const manufacturer_nickname = $computed(() => (route.params.nickname || "") as string)
const prefix = manufacturer_nickname ? `/${manufacturer_nickname}` : ""

const login_url = $computed(() => {
  const url = `${prefix}/login`
  return username ? `${url}?username=${username}` : url
})

watchEffect(() => {
  if (!username) router.push("/404")
})
</script>

<style scoped>
.app {
  background-image: url("/img/bg/pattern.svg");
  background-size: cover;
}
</style>
