<template>
  <CCard
    class="cardHover cursor-pointer clickable-card"
    :class="[`text-${color}`]"
  >
    <CCardBody
      @click="$emit('click')"
    >
      <slot />
    </CCardBody>
  </CCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "ClickableCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "secondary",
    }
  },
  emits: ["click"],
  setup() {},
})
</script>

<style lang="scss" scoped>
.cardHover {
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
}
.clickable-card {
  &.text-primary {
    border-color: var(--primary)
  }
}
</style>
