<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('pricings')"
      :subtitle="$t('all') + ' ' + $t('pricings')"
      :add-text="$t('add_pricing')"
      :edit-text="$t('edit_pricing')"
      :columns="tableColumns"
      :action-delete="true"
      :action-edit="true"
      :show-add-button="true"
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="pricingStore.mappedData"
      :add="pricingStore.add"
      :update="pricingStore.update"
      :remove="({ id }) => pricingStore.remove(id)"
      :default-sort="{ column: 'date__created', ascending: false }"
    >
      <template #created_by="{ row }">
        {{ row.created_by?.username }}
      </template>
      <template #customers="{ row }">
        <Popover
          :text="$t('apply_price_to_customers')"
          justify="left"
        >
          <CButton
            class="btn-space btn-sm"
            color="secondary"
            @click.stop="showCustomerPickerModal(row.id, row.customers)"
          >
            <i class="fa fa-users" />
          </CButton>
        </Popover>
      </template>
    </VTables>

    <CustomerMultiplePickerModal
      v-model="customerPicker.show"
      :selected="customerPicker.selected"
      @save="applyPricingToCustomers"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { customerStore, pricingStore } from "@/store"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { dateFilterAlgorithm, dateToFilterString } from "@/libraries/helpers"
import type { Pricing } from "@/interfaces"
import VTables from "@/components/VTables.vue"
import Popover from "@/components/Popover.vue"
import CustomerMultiplePickerModal from '@/components/modals/customer/CustomerMultiplePickerModal.vue'
import { getId } from '@/interfaces'
import { chunk, difference } from 'lodash-es'
import { PRICING_EQUATION_VARIABLES } from "@/constants"

export default defineComponent({
  name: "Pricings",
  components: {
    VTables,
    Popover,
    CustomerMultiplePickerModal
  },
  data() {
    return {
      selectedPrice: null,
      customerPicker: {
        show: false,
        selected: []
      },
    }
  },
  computed: {
    tableColumns() {
      return [
        "date__created",
        "created_by",
        "startup_amount",
        "discount_amount",
        "shipping_amount",
        "tax_rate",
        "customers",
        "actions"
      ]
    },
    tableOptions() {
      return {
        sortable: [
          "startup_amount",
          "discount_amount",
          "shipping_amount",
          "tax_rate",
          "created_by",
          "date__created",
        ],
        headings: {
          startup_amount: this.$t("startup_amount"),
          discount_amount: this.$t("discount_amount"),
          shipping_amount: this.$t("shipping_amount"),
          tax_rate: this.$t("tax_rate"),
          created_by: this.$t("created_by"),
          date__created: this.$t("created"),
        },
        filterAlgorithm: {
          date__created: (row: Pricing, query: string) => dateFilterAlgorithm(row, query, "created"),
        },
        customFilters: [
          {
            name: "all",
            callback(row: Pricing, query: string) {
              return [
                row.startup_amount || "",
                row.discount_amount || "",
                row.shipping_amount || "",
                row.tax_rate || "",
                row.created_by?.username || "",
                dateToFilterString(row, "created"),
              ]
                .join("###")
                .toLowerCase()
                .includes(query.toLowerCase())
            },
          },
        ],
      }
    },
    makeForm() {
      return (data?: Pricing) => ({
        id: "pricings-page-form",
        fields: {
          id: {
            type: "hidden",
            defaultValue: data?.id,
          },
          startup_amount: {
            type: "equation",
            label: this.$t("startup_amount"),
            defaultValue: data?.startup_amount,
            validations: [],
            variables: PRICING_EQUATION_VARIABLES
          },
          discount_amount: {
            type: "equation",
            label: this.$t("discount_amount"),
            defaultValue: data?.discount_amount,
            validations: [],
            variables: PRICING_EQUATION_VARIABLES
          },
          shipping_amount: {
            type: "equation",
            label: this.$t("shipping_amount"),
            defaultValue: data?.shipping_amount,
            validations: [],
            variables: PRICING_EQUATION_VARIABLES
          },
          tax_rate: {
            type: "number",
            label: this.$t("tax_rate"),
            defaultValue: data?.tax_rate,
            validations: [],
          },
        },
      })
    }
  },
  methods: {
    showCustomerPickerModal(id: number, customers = []) {
      this.selectedPrice = id
      this.customerPicker.show = true
      this.customerPicker.selected = customers.map(getId)
    },
    async applyPricingToCustomers(customerIds: number[]) {
      const removedCustomers = difference(this.customerPicker.selected, customerIds)
      const newlySelectedCustomers = difference(customerIds, this.customerPicker.selected)

      const updateCustomerRequests = [
        ...removedCustomers.map(async id => await customerStore.update({ id, pricing: null })),
        ...newlySelectedCustomers.map(async id => await customerStore.update({ id, pricing: this.selectedPrice })),
      ]
      const chunks = chunk(updateCustomerRequests, 8)

      for (const chunk of chunks) {
        await Promise.allSettled(chunk)
      }
    },
  },
  mounted() {
    pricingStore.fetchAll()
    customerStore.fetchAll()
  },
  setup() {
    const i18n = useI18n()
    useMeta({ title: i18n.t("pricings") })
    return {
      pricingStore
    }
  },
})
</script>
