<template>
  <div class="app d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :lg="5" :md="6" :sm="8">
          <CCard class="p-4">
            <CCardBody v-if="!passwordUpdated">
              <CForm>
                <h1 class="text-start">{{ $t("reset_password") }}</h1>
                <p class="text-muted text-start">
                  {{ $t("chose_new_password_text") }}
                </p>
                <CInputGroup>
                  <CInputGroupText>
                    <CIcon icon="fa-key" />
                  </CInputGroupText>
                  <CFormInput
                    v-model="model.password"
                    type="password"
                    :placeholder="$t('password')"
                    :invalid="!!errors.password"
                  />
                  <CFormFeedback invalid class="text-start">
                    {{ errors.password }}
                  </CFormFeedback>
                </CInputGroup>
                <CInputGroup class="mt-4">
                  <CInputGroupText>
                    <CIcon icon="fa-arrows-rotate" />
                  </CInputGroupText>
                  <CFormInput
                    v-model="model.retype_password"
                    type="password"
                    :placeholder="$t('confirm_password')"
                    :invalid="!!errors.retype_password"
                  />
                  <CFormFeedback invalid class="text-start">
                    {{ errors.retype_password }}
                  </CFormFeedback>
                </CInputGroup>
                <CRow class="mt-4">
                  <CCol class="text-end d-grid">
                    <CButton block color="primary" @click="checkForm">
                      <span v-if="!isLoading">{{ $t("reset") }}</span>
                      <CSpinner v-else size="sm" color="white" />
                    </CButton>
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol class="text-start">
                    <a class="text-nowrap" :href="login_url">
                      <CIcon icon="fa-arrow-left" class="me-2" />
                      {{ $t("back_to_login") }}
                    </a>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardBody v-else>
              <div class="d-flex justify-content-between mb-4 align-items-center">
                <h1>{{ $t("all_done") }}</h1>
                <CIcon icon="fa-check" size="4xl" />
              </div>
              <p class="text-muted">{{ $t("password_resetted") }}</p>
              <CRow class="mt-4">
                <CCol class="text-end d-grid">
                  <CButton block color="primary" @click="router.push(login_url)">
                    {{ $t("login") }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { userStore } from "@/store"
import type { User } from "@/interfaces"

const i18n = useI18n()
const route = useRoute()
const router = useRouter()

useMeta({ title: i18n.t("reset_password") })

let passwordUpdated = $ref(false)
let errors = $ref(
  {} as {
    password?: string
    retype_password?: string
  }
)
const model = $ref({
  password: "",
  retype_password: "",
  token: route.query.token || ("" as string),
  user_id: route.query.user_id || ("" as string),
  username: route.query.username || "",
})

const manufacturer_nickname = $computed(() => (route.params.nickname || "") as string)
const prefix = manufacturer_nickname ? `/${manufacturer_nickname}` : ""

const login_url = $computed(() => {
  const url = `${prefix}/login`
  return model.username ? `${url}?username=${model.username}` : url
})

let isLoading = $ref(false)

function submit() {
  isLoading = true
  userStore.resetPassword({
    password: model.password,
    token: model.token as string,
  })
    .then(() => (passwordUpdated = true))
    .finally(() => isLoading = false)
}
function checkForm(event: UIEvent) {
  event.preventDefault()

  errors = {}

  if (!model.password) errors.password = i18n.t("fill_new_password")

  if (model.password != model.retype_password)
    errors.retype_password = i18n.t("confirm_password_error_text")

  if (Object.keys(errors).length === 0) submit()
}
</script>

<style scoped>
.app {
  background-image: url("/img/bg/pattern.svg");
  background-size: cover;
}
</style>
