import { defineStore } from "pinia"
import { cloneDeep, forEach, pullAt } from "lodash-es"
import type { CreateLimit, CreateMachine, Limit, Machine } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { machineStore, manufacturerStore, userStore } from "@/store"
import type { OptimisticAddOptionInterface, OptimisticUpdateOptionInterface } from "@/interfaces"
import { limitStore, settingStore } from "."
import { optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch, getId, generateTemporaryId, addOrUpdate, bulkAddOrUpdate } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"

const cacheKey = "machines"

const mapData = (machine: Machine) => ({
  ...machine,
  limits: limitStore.mappedData.filter(limit => machine.limits?.includes(limit.id)),
  settings: settingStore.mappedData.filter(setting =>
    machine.settings?.includes(setting.id)
  ),
})

const updateManufacturerMachine = (machineId: number, index: number) => {
  if (
    userStore.mappedCurrent.availableManufacturers?.includes(
      manufacturerStore.manufacturerNickname
    )
  ) {
    const i = machineStore.manufacturerMachines.findIndex(m => m.id === machineId)
    if (i !== -1) {
      machineStore.manufacturerMachines[i] = machineStore.all[index]
      setCache(`${cacheKey}-manufacturer`, machineStore.all)
    }
  }
}

export default defineStore("machine", {
  state: () => ({
    all: [],
    manufacturerMachines: [],
  }),
  getters: {
    url: () => `/v1/machines`,
    mappedData: state => state.all.map(mapData),
    mappedManufacturerMachines: state => state.manufacturerMachines.map(mapData),
    getMappedMachineById() {
      return (id: number) => this.mappedData.find(m => m.id == id)
    },
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Machine[]
      this.manufacturerMachines = (getCache(`${cacheKey}-manufacturer`)?.data ||
        []) as Machine[]
    },
    modifyData(data = []) {
      const isSameManufacturer = userStore.mappedCurrent.availableManufacturers?.includes(
        manufacturerStore.manufacturerNickname
      )
      const objects = [cloneDeep(this.all)]

      if (isSameManufacturer) objects.push(cloneDeep(this.manufacturerMachines))
      const updatedObjects = bulkAddOrUpdate(objects, data)

      this.all = updatedObjects[0]
      if (isSameManufacturer) this.manufacturerMachines = updatedObjects[1]
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: "/v1/machines",
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchByManufacturerId(
      manufacturerId: number,
      { persist = false, prefetching = false } = {}
    ) {
      await paginatedFetch({
        url: `/v1/manufacturers/${manufacturerId}/machines`,
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchManufacturerMachines({ persist = false, prefetching = false } = {}) {
      const manufacturerId = manufacturerStore.current?.id
      if (!manufacturerId) return console.error("No manufacturer detected")
      await paginatedFetch({
        url: `/v1/manufacturers/${manufacturerId}/machines`,
        persist,
        prefetching,
        callback: data =>
          forEach(data || [], v => addOrUpdate(this.manufacturerMachines, v, ["id"])),
        runCallbackCondition: data =>
          this.manufacturerMachines.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(`${cacheKey}-manufacturer`, this.manufacturerMachines)
    },
    add(values: CreateMachine, options: OptimisticAddOptionInterface = {}) {
      const allObjects = [this.all]
      if (
        userStore.mappedCurrent.availableManufacturers?.includes(
          manufacturerStore.manufacturerNickname
        )
      )
        allObjects.push(this.manufacturerMachines)
      return optimisticAdd({
        allObjects,
        values,
        url: "/v1/machines",
        onSuccess: () => {
          if (!options?.withoutOptimistic) setCache(cacheKey, this.all)
        },
        ...options,
      })
    },
    update(values: Machine, options: OptimisticUpdateOptionInterface = {}) {
      const allObjects = [this.all]
      if (
        userStore.mappedCurrent.availableManufacturers?.includes(
          manufacturerStore.manufacturerNickname
        )
      )
        allObjects.push(this.manufacturerMachines)
      return optimisticUpdate({
        allObjects,
        values,
        url: `/v1/machines/${values.id}`,
        onSuccess: () => {
          if (!options?.withoutOptimistic) setCache(cacheKey, this.all)
        },
        ...options,
      })
    },
    remove(id: number) {
      const allObjects = [this.all]
      if (
        userStore.mappedCurrent.availableManufacturers?.includes(
          manufacturerStore.manufacturerNickname
        )
      )
        allObjects.push(this.manufacturerMachines)
      return optimisticDelete({
        allObjects,
        url: `/v1/machines/${id}`,
        id,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    addLimit(machineId: number, values: CreateLimit) {
      const index = this.all.findIndex(m => m.id === machineId)
      if (index === -1) return
      const tempId = generateTemporaryId()
      const newLimitIndex = this.all[index].limits.length
      values.materials = values.materials?.map(getId)
      return optimisticAdd({
        allObjects: [limitStore.all],
        url: `/v1/machines/${machineId}/limits`,
        values,
        tempId,
        optimisticAddCallback: () => this.all[index].limits.push(tempId),
      })
        .then(newLimit => {
          this.all[index].limits[newLimitIndex] = +(newLimit as Limit).id
          setCache(cacheKey, this.all)
          updateManufacturerMachine(machineId, index)
        })
        .catch(() => pullAt(this.all[index].limits, newLimitIndex))
    },
    removeLimit(machineId: number, id: number) {
      const index = this.all.findIndex(m => m.id === machineId)
      if (index === -1) return
      const limitIndex = this.all[index].limits.findIndex(l => l.id === id)
      optimisticDelete({
        allObjects: [limitStore.all],
        url: `/v1/limits/${id}`,
        id,
        optimisticDeleteCallback: () => pullAt(this.all[index].limits, [limitIndex]),
        onSuccess: () => {
          setCache(cacheKey, this.all)
          updateManufacturerMachine(machineId, index)
        },
      }).catch(() => this.all[index].limits.splice(limitIndex, 0, id))
    },
    addSetting(machineId: number, values: CreateLimit) {
      const index = this.all.findIndex(m => m.id === machineId)
      if (index === -1) return
      const tempId = generateTemporaryId()
      const newIndex = this.all[index].settings.length
      values.materials = values.materials?.map(getId)
      return optimisticAdd({
        allObjects: [settingStore.all],
        url: `/v1/machines/${machineId}/settings`,
        values,
        tempId,
        optimisticAddCallback: () => this.all[index].settings.push(tempId),
      })
        .then(newLimit => {
          this.all[index].settings[newIndex] = +(newLimit as Limit).id
          setCache(cacheKey, this.all)
          updateManufacturerMachine(machineId, index)
        })
        .catch(() => pullAt(this.all[index].settings, newIndex))
    },
    removeSetting(machineId: number, id: number) {
      const index = this.all.findIndex(m => m.id === machineId)
      if (index === -1) return
      const settingIndex = this.all[index].settings.findIndex(l => l.id === id)
      optimisticDelete({
        allObjects: [settingStore.all],
        url: `/v1/settings/${id}`,
        id,
        optimisticDeleteCallback: () => pullAt(this.all[index].settings, [settingIndex]),
        onSuccess: () => {
          setCache(cacheKey, this.all)
          updateManufacturerMachine(machineId, index)
        },
      }).catch(() => this.all[index].settings.splice(settingIndex, 0, id))
    },
  },
})
