<template>
  <div class="animated fadeIn wrapped-with-jyqvqqkvtk">
    <VTable
      :title="$t('files')"
      :subtitle="$t('files_subtitle')"
      :add-text="$t('add_files')"
      :columns="tableColumns"
      :action-delete="false"
      :show-add-button="true"
      :add-button-click="() => (showModals.upload = true)"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :data="fileStore.all"
      :row-click="() => {}"
    >
      <template #actions="{ row }">
        <div class="col-lg-12 button-box text-nowrap">
          <CButton
            v-if="row.extension"
            size="sm"
            color="primary"
            @click.stop="openViewer(row)"
          >
            <i class="fa fa-eye" />
            Preview
          </CButton>
          <CButton
            class="ms-2"
            color="primary"
            size="sm"
            @click.stop.prevent="fileStore.download(row.id)"
          >
            <i class="fa fa-download" />
            {{ $t("download") }}
          </CButton>
        </div>
      </template>
      <template #size="{ row }">
        <span>{{ (row.size / 1024).toFixed(2) }} KB</span>
      </template>
      <template #access="{ row }">
        <CBadge v-if="row.access.length === 0" color="secondary">
          {{ $t("private") }}
        </CBadge>
        <CBadge v-if="row.access.length === 1" color="secondary">
          {{ row.access.length }} {{ $t("user") }}
        </CBadge>
        <CBadge v-if="row.access.length > 1" color="secondary">
          {{ row.access.length }} {{ $t("users") }}
        </CBadge>
      </template>
    </VTable>

    <UploadModal
      v-model="showModals.upload"
      @uploaded="(_, file) => addOrUpdate(fileStore.all, file, ['id'])"
      @error="onFileError"
    />

    <!-- Viewer modal -->
    <!-- <b-modal :title="viewerModal.title" v-model="viewerModal.render" cancel-variant="hidden" :ok-title="$t('close')" class="viewer-modal" size="lg" ok-variant="secondary" body-class="viewer-modal-body">
      <smartpart-viewer v-if="viewerModal.render && viewerModal.type == 'step'" :fileId="viewerModal.fileId" ></smartpart-viewer>

      <div v-if="viewerModal.render && viewerModal.type == 'dxf'" style="text-align:center;">
        <div class="viewer" v-html="viewerModal.imageData"></div>
        <img class="origin" src="/models/CoordinateAxes.png"/>
      </div>

    </b-modal> -->
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted } from "vue"
import type { File } from "@/interfaces"
import { fileStore, toastStore } from "@/store"
import VTable from "@/components/VTables.vue"
import UploadModal from "@/components/modals/UploadModal.vue"
import { dateFilterAlgorithm, dateToFilterString, addOrUpdate, errorHandler } from "@/libraries/helpers"

const i18n = useI18n()

useMeta({ title: i18n.t("files") })

const showModals = $ref({
  upload: false,
  viewer: false,
})

// viewerModal: {
//   render: false,
//   title: "",
//   type: null,
//   imageData: "",
//   fileId: null
// }

const tableColumns = ["name", "extension", "date__created", "access", "size", "actions"]
const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    extension: i18n.t("extension"),
    date__created: i18n.t("created"),
    access: i18n.t("access"),
    size: i18n.t("size"),
    actions: i18n.t("actions"),
  },
  sortable: ["name", "date__created", "access", "size", "extension"],
  filterable: ["name", "date__created", "access", "size", "extension"],
  filterAlgorithm: {
    date__created: (row: File, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    size(row: File, query: string) {
      const str = (row.size / 1024).toFixed(2).toLowerCase()
      return str.includes(query.toLowerCase())
    },
    access(row: File, query: string) {
      let str = "private"
      if (row.access.length >= 1)
        str = `${row.access.length} user${row.access.length > 1 ? "s" : ""}`
      return str.includes(query.toLowerCase())
    },
  },
  customFilters: [
    {
      name: "all",
      callback(row: File, query: string) {
        const values = [
          row.name || "",
          row.extension || "",
          dateToFilterString(row, "created"),
          row.access?.length >= 1
            ? `${row.access.length} user${row.access.length > 1 ? "s" : ""}`
            : "private",
          (row.size / 1024).toFixed(2),
        ]
        return values.join("###").toLowerCase().includes(query.toLowerCase())
      },
    },
  ],
}

function openViewer(row: File) {
  console.log("openViewer", row)
}

// getFileImage(fileId) {
//   this.$store.dispatch('API_GET', '/v1/files/' + fileId + '/images/')
//   .then(response => {
//     this.viewerModal.imageData = response.data
//   })
//   .catch(error => {
//     this.$notify({
//       type: "error",
//       title: i18n.t('failed'),
//       text: i18n.t('failed_alert')
//     })
//   })
// },
// openViewer(fileData) {
//   console.log("[+] open viewer: " + fileData.id)

//   if (fileData.extension == "step" || fileData.extension == "stp") {
//     this.viewerModal.title = "STEP viewer";
//     this.viewerModal.type = "step";

//   } else if (fileData.extension == "dxf") {
//     this.viewerModal.title = "DXF viewer";
//     this.viewerModal.type = "dxf";
//     this.getFileImage(fileData.id);
//   }

//   this.viewerModal.fileId = fileData.id;
//   this.viewerModal.render = true;

//   console.log(fileData)
// },

function onFileError(file: any) {
  const { xhr: response } = file
  const status = response?.status || 500
  toastStore.toasts.push({
    color: "danger",
    message: i18n.t("failed_to_upload", { message: errorHandler.generateErrorMessage(status) || response?.message || JSON.stringify(response || {}) } )
  })
}

onMounted(() => {
  fileStore.fetchAll()
})
</script>

<style lang="scss">
.wrapped-with-jyqvqqkvtk {
  .viewer-modal .viewer {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
  }

  .viewer-modal img.origin {
    position: absolute;
    right: 0;
    bottom: 0;
    border: none;
    width: 200px;
    height: 200px;
    pointer-events: none;
  }
}
</style>
