<template>
  <div>
    <ModalWrapper v-model="isVisible">
      <CModal
        :visible="isVisible"
        size="lg"
        backdrop="static"
        @close="isVisible = false"
      >
        <CModalHeader>
          <CModalTitle>{{ title || $t("pick_address") }}</CModalTitle>
        </CModalHeader>
        <CModalBody class="d-flex flex-column">
          <div
            v-if="authStore.authenticatedUser?.is_moderator || authStore.authenticatedUser?.is_admin"
            class="mb-4"
          >
            <CButton style="float: right" color="success" @click="showForm = true">
              <b>+</b>
              {{ $t("add_new_address") }}
            </CButton>
          </div>
          <div v-if="activeAddresses.length > 0">
            <template v-for="(address, i) in activeAddresses" :key="i">
              <CCard
                v-if="(hideSelected && selected?.id != address.id) || !hideSelected"
                class="mb-2 address-card"
                :class="{
                  selectedCard: selected?.id == address.id,
                  cardHover: selected?.id != address.id,
                }"
                @click="pick(address)"
              >
                <CCardBody class="d-flex flex-row justify-content-between">
                  <p class="d-flex flex-column">
                    <span class="mb-4">{{ address.line_1 }} {{ address.line_2 }}</span>
                    <span>
                      {{ address.postcode }} {{ address.city }} {{ address.province }}
                    </span>
                    <span>{{ address.country }}</span>
                  </p>

                  <div v-if="selected?.id == address.id">
                    <CBadge color="primary">
                      {{ $t("picked") }}
                    </CBadge>
                  </div>
                </CCardBody>
              </CCard>
            </template>
          </div>
          <CCard v-else>
            <CCardHeader class="justify-content-between d-flex bg-light" />
            <CCardBody>
              <p class="text-center">{{ $t("no_data") }}</p>
            </CCardBody>
          </CCard>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("close") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>

    <AddressFormModal
      :show="showForm"
      :address="editAddress"
      :total="activeAddresses.length"
      :show-checkboxes="showCheckboxesOnForm"
      @submit="v => emit('add', v)"
      @close="() => (showForm = false)"
    />
  </div>
</template>

<script lang="ts" setup>
import type { Address } from "@/interfaces"
import { authStore } from "@/store"
import AddressFormModal from "./AddressFormModal.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

interface Props {
  show: boolean
  data: Address[]
  selected?: Address
  title?: string
  hideSelected?: boolean
  showCheckboxesOnForm?: boolean
}

const {
  show = false, data: addresses = [], selected, title, hideSelected = false,
  showCheckboxesOnForm = true,
} = defineProps<Props>()

const emit = defineEmits(["close", "open", "picked", "add"])

let visibleCards = $ref([])
const editAddress = $ref(null)
const showForm = $ref(false)

const isVisible: any = $computed({
  get() {
    return show && !showForm
  },
  set(v) {
    visibleCards = []
    emit(v ? "open" : "close")
  },
})

const activeAddresses: Address[] = $computed(() => addresses.filter((a) => a.is_active))

function pick(address: Address) {
  if (address.id == selected?.id) return
  emit("picked", address)
}
</script>

<style lang="scss">
.address-card {
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  }
}
</style>
