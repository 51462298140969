<template>
  <div class="excel-downloader">
    <div v-if="type == 'excel'" @click="exportXLSX(exportableData)">
      <slot />
    </div>
    <DownloadCSV v-if="type == 'csv'" :data="exportableData" :name="filename">
      <slot />
    </DownloadCSV>
  </div>
</template>

<script lang="ts" setup>
import DownloadCSV from "vue-json-csv"
import * as XLSX from "xlsx"

const types = ["csv", "excel"] as const

interface Props {
  type: typeof types[number]
  data: any[]
  filename: string
  fields: any // this includes any[]
}

const { type = "excel", data, filename, fields } = defineProps<Props>()

const exportableData = $computed(() => {
  if (!fields) return data

  const formattedDatas = []
  for (let i = 0; i < data.length; i++) {
    const d = data[i]
    const formatted = {}
    for (const property in fields) {
      const field = fields[property]
      if (!Object.prototype.hasOwnProperty.call(d, field.key)) continue

      formatted[property] = field.formatter ? field.formatter(d[field.key]) : d[field.key]
    }
    formattedDatas.push(formatted)
  }

  return formattedDatas
})

function exportXLSX(data) {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data, {
    WTF: true,
    cellStyles: true,
  })
  XLSX.utils.book_append_sheet(wb, ws, filename)
  XLSX.writeFile(wb, filename)
}
</script>
