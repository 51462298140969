import { defineStore } from "pinia"

type Color =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "dark"
  | "light"

type Type = 
  | "text"
  | "loading"

type Toast = {
  id?: string
  color: Color
  message: string
  delay?: number
  dismissable?: boolean
  type?: "loading"
}

export default defineStore("toasts", {
  state: () => ({
    toasts: [] as Toast[],
  }),
  actions: {
    init() {
      if (localStorage.error) this.toasts.push({ color: "danger", message: localStorage.error })
      localStorage.removeItem("error")
    },
  },
})
