<template>
  <div
    class="table-tree-expander"
    :class="{
      'table-tree-expander__expanded': isExpanded && totalChildren > 0,
      'table-tree-expander__children': isChild
    }"
    :style="`margin-left: ${deep * 2}rem;--total-children: ${totalVisibleChildren}`"
  >
    <template v-if="totalChildren > 0">
      <i
        v-if="!isExpanded"
        class="fa fa-chevron-right cursor-pointer"
        @click="$emit('expand')"
      />
      <i
        v-else
        class="fa fa-chevron-down cursor-pointer"
        @click="$emit('collapse')"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "TableTreeExpander",
  props: {
    isExpanded: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    },
    deep: {
      type: Number,
      default: 0
    },
    totalChildren: {
      type: Number,
      default: 0
    },
    totalVisibleChildren: {
      type: Number,
      default: 0
    }
  },
  emits: ["expand", "collapse"]
})
</script>

<style lang="scss">
.table-tree-expander {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  &__expanded::before {
    border-left: 1px dashed #333;
    content: "";
    left: 0.4rem;
    position: absolute;
    top: 2.4rem;
    height: calc(100% + 0.5rem + ((var(--total-children) - 1) * 4.645rem));
  }
  &__children
  {
    position: relative;
    &::after {
      border-top: 1px dashed #333;
      content: "";
      left: -1.6rem;
      position: absolute;
      width: 1.5rem;
    }
  }
}
</style>
