<template>
  <v-client-table
    ref="tableElement"
    :columns="columns"
    :data="batches"
    :options="tableOptions"
    @select="selectedBatchIds = $event.map(b => b.id)"
    @pagination="calculateTotalVisibleChildren"
    @filter="$nextTick(calculateTotalVisibleChildren)"
    @loaded="handleTableLoaded"
  >
    <template v-if="!isValid" #afterFilters>
      <tr class="VueTables__no-results">
        <td class="text-center" :colspan="columns.length + 1">
          <div class="text-danger small">
            <b>{{ $t("invalid_parts_text") }}</b>
          </div>
        </td>
      </tr>
    </template>
    <template #prependBody>
      <tr
        v-for="(file, index) of failedFiles"
        :key="index"
        class="VueTables__row"
      >
        <td :colspan="columns.length + 1">
          <div class="d-flex align-items-center">
            <span
              class="text-danger white-space__nowrap"
              v-html="$t('failed_to_process_filename', { filename: `${file.filename}`, error: `${file.error}` })"
            />
            <div class="d-flex align-items-center ms-4">
              <CButton
                color="danger"
                variant="outline" 
                size="sm"
                @click="$emit('remove-failed-file', file)"
              >
                {{ $t("remove") }}
              </CButton>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-for="file of duplicatedFiles"
        :key="file.id"
        class="VueTables__row"
      >
        <td :colspan="columns.length + 1">
          <div class="d-flex align-items-center">
            <span
              class="text-danger white-space__nowrap"
              v-html="$t('file_duplicated', { filename: `${file.filename}` })"
            />
            <div class="d-flex align-items-center ms-4">
              <CButton
                color="secondary"
                variant="outline" 
                size="sm"
                @click="$emit('process-duplicate', file)"
              >
                {{ $t("proceed") }}
              </CButton>
              <CButton
                class="ms-2"
                color="danger"
                variant="outline" 
                size="sm"
                @click="$emit('remove-failed-file', file)"
              >
                {{ $t("remove") }}
              </CButton>
            </div>
          </div>
        </td>
      </tr>
      <tr
        v-for="(file, index) of inProgressFiles"
        :key="index"
        class="VueTables__row"
      >
        <td :colspan="columns.length + 1">
          <div class="d-flex flex-column">
            <span
              class="fw-bold white-space__nowrap"
            >
              {{ file.filename }}
            </span>
            <div class="d-flex align-items-center my-2">
              <CProgress
                class="w-100"
              >
                <CProgressBar 
                  color="primary"
                  variant="striped"
                  animated
                  :value="file.progress"
                >
                  {{ file.progress || 0 }}%
                </CProgressBar>
              </CProgress>
            </div>
          </div>
        </td>
      </tr> 
    </template>

    <template #__group_meta="{ row }">
      ASSEMBLY {{ $t("filename") }}: {{ row.part?.filename }}, CAD
      {{ $t("name") }}: {{ row.part?.name }}
    </template>
    <template #index="{ index, column, row }">
      <div
        class="d-flex h-100 align-items-center"
      >
        <TableTreeExpander
          v-show="treeView"
          class="me-3"
          :is-expanded="!hideChildOfBatchIds.includes(row.id)"
          :isChild="!!row.parent"
          :deep="row.treeDeep"
          :total-children="row.childrenIds.length"
          :total-visible-children="totalVisibleChildren[row.id]"
          @expand="hideChildOfBatchIds = hideChildOfBatchIds.filter(id => id !== row.id)"
          @collapse="hideBatchId(row.id)"
        />
        <span :ref="column + index.toString()">
          {{ index }}
        </span>
      </div>
    </template>

    <template #parent="{ row }">
      <span>{{ row.parent }}</span>
    </template>

    <template #filename="{ row }">
      <div class="d-flex">
        <b class="d-flex align-items-center">
          {{ row.part?.filename }}
        </b>
      </div>
    </template>

    <template #preview="{ row }">
      <div
        style="width: 50px; height: 50px"
      >
        <PartImage
          v-if="!row.isTemporary"
          :batch="row"
          @click.stop=""
        />
        <CSpinner v-else />
      </div>
    </template>

    <template #name="{ row }">
      <b>
        {{ row.part?.name || row.part?.id }}
      </b>
      <div
        class="text-muted small white-space__nowrap"
        style="cursor: pointer"
        @click.stop="copyTextToClipboard(row.id, `${row.id} ${$t('copied_to_clipboard')}`)"
      >
        <i class="fa fa-copy" />
        {{ row.id }}
      </div>
    </template>

    <template #file="{ row }">
      {{ row.part?.name }}
    </template>

    <template #material="{ row }">
      <div style="width: 250px">
        <ImprovedVueMultiselect
          :id="`material-${row.id}`"
          v-model="row.injected_material"
          track-by="id"
          :options="row.options.materials"
          label="name"
          :multiple="false"
          :show-labels="true"
          :placeholder="
            row.options.materials.length
              ? $t('select_or_start_typing')
              : $t('no_material_available')
          "
          :max-height="200"
          open-direction="bottom"
          :disabled="!row.options.materials.length"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="updateStock($event || { type: 'material', id: null }, row.id)"
        />
      </div>
    </template>

    <template #thickness="{ row }">
      <div style="width: 250px" @click.stop>
        <ImprovedVueMultiselect
          :id="`thickness-${row.id}`"
          v-model="row.injected_thickness"
          track-by="value"
          :options="row.options.thicknesses"
          label="name"
          :multiple="false"
          :show-labels="true"
          :placeholder="row.options.thicknesses.length ? $t('select_or_start_typing') : 'N/A'"
          :max-height="200"
          open-direction="bottom"
          :disabled="!row.options.thicknesses.length"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="updateStock($event || { type: 'size', value: null }, row.id)"
        />
      </div>
    </template>

    <template #process="{ row }">
      <div style="width: 250px" @click.stop>
        <ImprovedVueMultiselect
          :id="`process-${row.id}`"
          v-model="row.process"
          track-by="id"
          :options="row.options.processes"
          label="name"
          :multiple="false"
          :show-labels="true"
          :placeholder="
            row.options.processes.length
              ? $t('select_or_start_typing')
              : $t('no_process_available')
          "
          :max-height="200"
          :disabled="!row.options.processes.length"
          open-direction="bottom"
          :allow-empty="false"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="updateMachines($event, row.id)"
        />
      </div>
    </template>

    <template #operations="{ row }">
      <div
        v-if="row.options.operations && row.operations"
        style="width: 250px"
        @click.stop
      >
        <ImprovedVueMultiselect
          :id="`operations-${row.id}`"
          v-model="row.operations"
          track-by="id"
          :options="row.options.operations"
          label="name"
          :multiple="true"
          :show-labels="true"
          :placeholder="
            row.options.operations.length
              ? $t('select_or_start_typing')
              : $t('no_operations_available')
          "
          :max-height="200"
          open-direction="bottom"
          :disabled="!row.options.operations.length"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          @update:model-value="updateMachines($event, row.id)"
        >
          <template #tag="props">
            <span
              v-show="
                props.option.is_visible || authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin
              "
              :key="props.index"
              class="multiselect__tag"
            >
              <span>{{ props.option.name }}</span>
            </span>
          </template>
        </ImprovedVueMultiselect>
      </div>
    </template>

    <template #certificates="{ row }">
      <div style="width: 250px" @click.stop>
        <ImprovedVueMultiselect
          :id="`certificates-${row.id}`"
          key="certificates"
          v-model="row.certificateOptions"
          :options="
            row.options.certificates.map(c => ({
              id: c,
              name: c.replace(/.*(\d)(\d)/, '$1.$2'),
            }))
          "
          track-by="id"
          label="name"
          :multiple="true"
          :show-labels="true"
          :placeholder="
            row.options.certificates.length
              ? $t('select_or_start_typing')
              : $t('no_certificates_available')
          "
          :max-height="200"
          open-direction="bottom"
          :scrollable-container-selector="MULTISELECT_CONTAINER"
          :calculate-left="getMultiselectLeft"
          :calculate-top="getMultiselectTop"
          :disabled="row.options.certificates.length === 0"
          @update:model-value="
            batchStore.update({
              id: row.id,
              certificates: $event.map(c => c.id),
            })
              .then(() => autoSelectOption([row.id]))
          "
        />
      </div>
    </template>

    <template #reference="data">
      <input
        v-if="data.row.part"
        :id="`reference-${data.row.id}`"
        :ref="data.column + data.index.toString()"
        v-model="data.row.part.reference"
        class="form-control"
        @click.stop
        @update:model-value="updateReference($event, data.row.part.id)"
      />
    </template>

    <template #boolean__is_valid="{ row }">
      <template v-if="!batchPriceLoading.includes(row.id)">
        <i
          v-if="row.is_valid == true"
          style="color: green"
          class="fa fa-check"
        />
        <i v-else style="color: red" class="fa fa-times" />
      </template>
      <CSpinner v-else />
    </template>

    <template #quantity="data">
      <div style="width: 80px">
        <input
          :id="`quantity-${data.row.id}`"
          :ref="data.column + data.index.toString()"
          v-model.number="data.row.quantity"
          type="number"
          class="form-control"
          min="1"
          @click.stop
          @update:model-value="updateQuantity($event, data.row.id)"
        />
      </div>
    </template>

    <template #price="{ row }">
      <div v-if="!batchPriceLoading.includes(row.id)">
        {{
          toCurrency(
            isNaN(row.unit_amount) || row.unit_amount == 0 ? 0 : row.unit_amount
          )
        }}
      </div>
      <CSpinner v-else />
    </template>

    <template #total="{ row }">
      <div v-if="!batchPriceLoading.includes(row.id)">
        {{
          toCurrency(
            isNaN(row.total_amount) || row.total_amount == 0
              ? 0
              : row.total_amount
          )
        }}
      </div>
      <CSpinner v-else />
    </template>

    <template #actions="{ row }">
      <CButton
        v-if="!row.isTemporary"
        @click.stop="deleteRow(row)"
      >
        <i class="fa fa-trash text-danger" />
      </CButton>
    </template>
    <template #appendBody>
      <tr
        v-show="loading"
        class="loading-placeholder-row"
      >
        <td class="text-center px-1 pt-2" colspan="3">
          <div class="loading-placeholder small" />
        </td>
        <td class="text-center px-1 pt-2">
          <div class="loading-placeholder small" />
          <div class="loading-placeholder small mt-1" />
        </td>
        <td class="text-center px-1 pt-2">
          <div class="loading-placeholder big" />
        </td>
        <td class="text-center px-1 pt-2" colspan="13">
          <div class="loading-placeholder" />
        </td>
      </tr>
      <tr class="table-body-filler" />
    </template>

    <template #afterBody>
      <MultiEditRow
        v-if="!!batchesSelected.length"
        :selected-batches="batchesSelected"
        :update-stock="updateStock"
        :update-machine="updateMachines"
        @deleted="() => selectedBatchIds = []"
      />
    </template>
  </v-client-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { getTableOptions } from "../helpers"
import ImprovedVueMultiselect from "@/components/ImprovedVueMultiselect.vue"
import PartImage from "@/components/part-image/PartImage.vue"
import { batchStore, partStore, globalStore } from "@/store"
import sweetalert from "sweetalert2"
import MultiEditRow from "./MultiEditRow.vue"
import { chunk, debounce } from "lodash-es"
import { copyTextToClipboard, getFilterEmitsOptions, sleep, toCurrency } from "@/libraries/helpers"
import { MULTISELECT_CONTAINER, getMultiselectLeft, getMultiLineMultiselectTop, getMultiselectTop } from "../helpers"
import TableTreeExpander from '@/components/TableTreeExpander.vue'
import { treeHelpers } from "../helpers"
import type { InjectedBatchInterface } from '@/interfaces'
import { uploadFileStatus } from '@/interfaces'

export default defineComponent({
  name: "EditBatchTable",
  components: {
    ImprovedVueMultiselect,
    PartImage,
    MultiEditRow,
    TableTreeExpander
  },
  props: {
    orderId: {
      type: Number
    },
    columns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    updateMachines: {
      type: Function,
      default: () => {}
    },
    updateStock: {
      type: Function,
      default: () => {}
    },
    treeView: {
      type: Boolean,
      default: false
    },
    globalSearch: {
      type: String,
      default: ""
    },
    autoSelectOption: {
      type: Function,
      default: (batchIds: []) => {}
    },
    files: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['remove-failed-file', "process-duplicate"],
  data: () => ({
    selectedBatchIds: [],
    hideChildOfBatchIds: [],
    totalVisibleChildren: {}
  }),
  computed: {
    batches() {
      return batchStore.getBatchesByOrderId(this.orderId)
    },
    tableOptions() {
      return getTableOptions({
        translator: this.$t,
        columns: this.columns as string[],
        loading: this.loading,
        treeView: this.treeView,
        batches: this.batches
      })
    },
    batchesSelected() {
      return this.batches.filter(b => this.selectedBatchIds.includes(b.id))
    },
    validBatches() {
      return batchStore.all.filter(b => b.injected_material && b.injected_thickness).map(b => b.id)
    },
    batchPriceLoading() {
      const ids = batchStore.updateRequests.queue.map(q => q.id)
      return this.validBatches.filter(id => ids.includes(id))
    },
    isOrderValid() {
      return this.batches.every(batch => !!batch.is_valid)
    },
    isValid() {
      return this.batches.every(batch => !!batch.is_valid)
    },
    failedFiles() {
      return this.files.filter((f: any) => f.status === uploadFileStatus.ERROR)
    },
    inProgressFiles() {
      return this.files.filter((f: any) => [uploadFileStatus.UPLOADING, uploadFileStatus.PROCESSING].includes(f.status))
    },
    duplicatedFiles() {
      return this.files.filter((f: any) => f.status === uploadFileStatus.DUPLICATED)
    }
  },
  watch: {
    batches: {
      async handler() {
        treeHelpers.handleBatchTree(this.batches, this.hideChildOfBatchIds)
        await sleep(100)
        this.calculateTotalVisibleChildren()
      },
      deep: true
    },
    hideChildOfBatchIds: {
      async handler() {
        treeHelpers.handleBatchTree(this.batches, this.hideChildOfBatchIds)
        await sleep(100)
        this.calculateTotalVisibleChildren()
      },
      deep: true
    },
    treeView() {
      this.hideChildOfBatchIds = []
    },
    globalSearch() {
      if (this.$refs.tableElement) (this.$refs.tableElement as any).setCustomFilters({ all: this.globalSearch })

      this.$nextTick(this.calculateTotalVisibleChildren)
    },
  },
  methods: {
    deleteRow(batch) {
      sweetalert
        .fire({
          title: this.$t("remove"),
          text: this.$t("delete_row_body"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: this.$t("delete"),
          cancelButtonText: this.$t("cancel"),
        })
        .then(result => {
          if (result.isConfirmed) {
            batchStore.remove(batch.id)
          }
        })
        .catch()
    },
    updateQuantity: debounce((quantity, id) => batchStore.update({ id, quantity }, {}), 1000),
    updateReference: debounce((reference, id) => partStore.update({ id, reference }), 1000),
    hideBatchId(id: number) {
      this.hideChildOfBatchIds = treeHelpers.hideBatchId(id, this.batches, this.hideChildOfBatchIds)
    },
    calculateTotalVisibleChildren() {
      this.totalVisibleChildren = treeHelpers.calculateTotalVisibleChildren(this.batches, this.totalVisibleChildren)
    },
    handleTableLoaded() {
      const tableElement: any = this.$refs.tableElement
      if (tableElement) {
        (this.$refs.tableElement as any).$.emitsOptions = {
          ...(this.$refs.tableElement as any).$.emitsOptions,
          ...getFilterEmitsOptions(this.tableOptions.filterable || []),
        }

        const totalData = (tableElement.allFilteredData || []).length
        let perPage = (tableElement.$refs?.table?.page || 1) * tableElement.options.perPage
        if (perPage > totalData) perPage = totalData
        globalStore.changeFooter({
          text: `Showing 1 to ${perPage} of ${totalData} records`,
          showPagination: false
        })
      }
    }
  },
  unmounted() {
    globalStore.reset(["footer"])
  },
  setup() {
    const multiUpdateQuantity = debounce(async (selectedBatches: InjectedBatchInterface[], quantity) => {
      const selectedChunks = chunk(selectedBatches, 8)

      for (const chunk of selectedChunks) {
        await Promise.allSettled(chunk.map(batch => batchStore.update({ id: batch.id, quantity })))
      }
    }, 500)

    return {
      batchStore,
      MULTISELECT_CONTAINER,
      getMultiselectTop,
      getMultiselectLeft,
      getMultiLineMultiselectTop,
      multiUpdateQuantity,
      toCurrency, copyTextToClipboard
    }
  }
})
</script>
