<template>
  <div>
    <ModalWrapper
      v-model="isVisible">
      <CModal
        :visible="isVisible"
        backdrop="static"
        size="lg"
        @close="isVisible = false"
      >
        <CModalHeader>
          <CModalTitle>{{ $t("addresses") }}</CModalTitle>
        </CModalHeader>
        <CModalBody class="d-flex flex-column">
          <div class="mb-4">
            <CButton style="float: right" color="success" @click="showAddressForm(null)">
              <b>+</b>
              {{ $t("add_new_address") }}
            </CButton>
          </div>
          <div v-if="activeAddresses.length > 0">
            <template
              v-for="(address, i) in activeAddresses"
              :key="`${address.id}-${address.updated}-${i}`"
            >
              <CCard class="mb-2">
                <CCardHeader
                  class="justify-content-between d-flex bg-light cursor-pointer align-items-center"
                  @click="
                    !visibleCards.includes(address.id)
                      ? visibleCards.push(address.id)
                      : (visibleCards = visibleCards.filter(v => v != address.id))
                  "
                >
                  <div class="d-flex justify-content-between w-100">
                    <span>{{ address?.line_1 }} {{ address?.line_2 }}</span>

                    <CSpinner v-if="loading" />
                    <div v-else>
                      <CBadge v-if="address.is_shipping" color="primary" class="me-2">
                        {{ $t("default_shipping_address") }}
                      </CBadge>
                      <CBadge v-if="address.is_billing" color="primary" class="me-2">
                        {{ $t("billing_address") }}
                      </CBadge>
                      <i
                        class="fa floatRight ms-2"
                        :class="{
                          'fa-chevron-down': !visibleCards.includes(address.id),
                          'fa-chevron-up': visibleCards.includes(address.id),
                        }"
                      />
                    </div>
                  </div>
                </CCardHeader>
                <CCollapse :visible="visibleCards.includes(address.id)">
                  <CCardBody class="d-flex justify-content-between">
                    <div>
                      <p class="d-flex flex-column">
                        <span class="mb-4">
                          {{ address.line_1 }} {{ address.line_2 }}
                        </span>
                        <span>
                          {{ address.postcode }} {{ address.city }} {{ address.province }}
                        </span>
                        <span>{{ address.country }}</span>
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-end flex-column"
                      :class="{
                        'justify-content-center': loading
                      }"
                    >
                      <template v-if="!loading">
                        <div
                          v-if="!address.is_billing || !address.is_shipping"
                          class="button-switch mb-4"
                        >
                          <CButton
                            v-if="!address.is_shipping"
                            color="primary"
                            type="button"
                            variant="outline"
                            @click.stop="update({ id: address.id, is_shipping: true })"
                          >
                            {{ $t("set_as_shipping") }}
                          </CButton>
                          <CButton
                            v-if="!address.is_billing"
                            color="primary"
                            type="button"
                            variant="outline"
                            @click.stop="update({ id: address.id, is_billing: true })"
                          >
                            {{ $t("set_as_billing") }}
                          </CButton>
                        </div>
                        <div class="button-switch">
                          <CButton
                            class="w-100px"
                            color="primary"
                            @click="showAddressForm(address)"
                          >
                            {{ $t("edit") }}
                          </CButton>
                          <CButton
                            class="w-100px"
                            color="danger" 
                            @click="remove(address)"
                          >
                            {{ $t("remove") }}
                          </CButton>

                        </div>
                      </template>
                      <CSpinner v-else />
                    </div>
                  </CCardBody>
                </CCollapse>
              </CCard>
            </template>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            @click="isVisible = false"
          >
            {{ $t("close") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
    <AddressFormModal
      :show="showForm"
      :address="editAddress"
      :total="activeAddresses.length"
      @submit="v => editAddress ? update(v) : add(v)"
      @close="() => (showForm = false)"
    />
    <AddressPickerModal
      :title="$t(`pick_new_${selected?.is_shipping && selected?.is_billing ? 'shipping_and_billing' : (selected?.is_shipping ? 'shipping' : 'billing')}_to_remove_address`)"
      :selected="selected"
      :show="showAddressPicker"
      :data="activeAddresses"
      :show-checkboxes-on-form="false"
      hide-selected
      @close="showAddressPicker = false"
      @open="showAddressPicker = true"
      @picked="handleNewShippingOrBillingPicked"
      @add="add"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import sweetalert from "sweetalert2"
import type { Address } from "@/interfaces"
import { addressStore, requestStore } from "@/store"
import AddressFormModal from "./AddressFormModal.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"
import AddressPickerModal from "./AddressPickerModal.vue"
import { pick } from "lodash-es"

const i18n = useI18n()
interface Props {
  show: boolean
  data: Address[]
  add: (v: any) => Promise<void>
  update: (v: any) => Promise<void>
}

const {
  show = false,
  data: addresses = [],
  add = () => {},
  update = () => {}
} = defineProps<Props>()

const emit = defineEmits(["close", "remove", "open"])

let showForm = $ref(false)
let visibleCards = $ref([])
let editAddress = $ref(null)

let showAddressPicker = $ref(false)
let selected = $ref(null)

const isVisible: any = $computed({
  get() {
    return show && !showForm && !showAddressPicker
  },
  set(v) {
    visibleCards = []
    emit(v ? "open" : "close")
  },
})
const activeAddresses = $computed(() => addresses?.filter(a => a.is_active))
const loading = $computed(() => !!requestStore.runningFetches.find(r => r.url.includes("addresses")))

function remove(address: any) {
  if (!address) return

  sweetalert
    .fire({
      title: i18n.t("remove"),
      text: i18n.t("setting_remove_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: i18n.t("yes_sure"),
      cancelButtonText: i18n.t("cancel"),
    })
    .then(result => {
      if (result.isConfirmed) {
        if (address.is_shipping || address.is_billing) {
          showAddressPicker = true
          selected = address
          return
        }
        addressStore.update({ id: address.id, is_active: false })
          .then(() => emit("remove", address))
          .catch()
      }
    }).catch()
}
function showAddressForm(address) {
  editAddress = address
  showForm = true
}
/**
 * if the old address has both default shipping and billing, update picked address to have both
 * if the old address has only default shipping, update picked address to have default shipping
 * if the old address has only default billing, update picked address to have default billing
 */
async function handleNewShippingOrBillingPicked(values: any) {
  showAddressPicker = false
  const payload = pick(values, ['id', 'is_shipping', 'is_billing'])
  if (selected?.is_shipping) payload.is_shipping = true
  if (selected?.is_billing) payload.is_billing = true
  Promise.all([update(payload), addressStore.update({ id: selected.id, is_active: false })])
    .then(() => emit("remove", selected))
    .catch()
}
</script>
