<template>
  <CProgress
    :height="4"
    class="global-progress"
    :style="{ opacity: +progress }"
  >
    <CProgressBar :value="progress" />
  </CProgress>
</template>

<script lang="ts" setup>
import { globalStore } from "@/store";

const progress = $computed(() => globalStore.progressBar)
</script>

<style scoped>
.global-progress {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--global-progressbar-z-index);
  opacity: 0;
  transition: opacity 0.5s ease 1s;
}
</style>
