<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('machines')"
      :subtitle="$t('all') + ' ' + $t('machines')"
      :add-text="$t('add_machine')"
      :edit-text="$t('edit_machine')"
      :columns="tableColumns"
      :action-delete="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin !== true"
      :show-merge-options="false"
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="machineStore.all"
      :add="machineStore.add"
      :update="machineStore.update"
      :remove="({ id }) => machineStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
      @merge-done="machineStore.modifyData"
    >
      <template #type="{ row }">
        <CBadge color="secondary">{{ row.type }}</CBadge>
      </template>
      <template #parts="{ row }">
        <CBadge color="primary">{{ row.parts }}</CBadge>
      </template>
      <template #process="{ row }">
        <CBadge color="secondary">{{ row.process }}</CBadge>
      </template>
      <template #settings="{ row }">
        <div class="col-lg-12 button-box text-nowrap d-flex">
          <Popover
            :text="$t('add_or_edit_machine_limits')"
          >
            <CButton
              class="btn-space btn-sm me-2"
              color="secondary"
              @click.stop="showFormModal(row, 'limitation')"
            >
              <i class="fa fa-tachometer" />
            </CButton>
          </Popover>
          <Popover
            :text="$t('add_or_edit_machine_settings')"
          >
            <CButton
              class="btn-space btn-sm"
              color="secondary"
              @click.stop="showFormModal(row, 'setting')"
            >
              <i class="fa fa-cog" />
            </CButton>
          </Popover>
        </div>
      </template>
    </VTables>
    <LimitationFormModal
      v-model="showForm.limitation"
      :title="selectedMachine?.name"
      :subtitle="selectedMachine?.description"
      :data="selectedMachine?.limits"
      @add="v => machineStore.addLimit(selectedMachineId, v)"
      @update="limitStore.update"
      @remove="v => machineStore.removeLimit(selectedMachineId, v.id)"
    />
    <SettingFormModal
      v-model="showForm.setting"
      :title="selectedMachine?.name"
      :subtitle="selectedMachine?.description"
      :data="selectedMachine?.settings"
      @add="v => machineStore.addSetting(selectedMachineId, v)"
      @update="settingStore.update"
      @remove="v => machineStore.removeSetting(selectedMachineId, v.id)"
    />
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { onMounted } from "vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import type { Machine } from "@/interfaces"
import {
  machineProcessOptions,
  machinePartsOptions,
  machineTypeOptions,
} from "@/interfaces"
import { authStore, machineStore, settingStore, limitStore, requestStore } from "@/store"
import VTables from "@/components/VTables.vue"
import LimitationFormModal from "@/components/modals/LimitationFormModal.vue"
import SettingFormModal from "@/components/modals/SettingFormModal.vue"
import { convertUnit, booleanCustomSorting, generateFilterAlgorithm, getListColumns } from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("machines") })

const log = console.log
const addSetting = () => log("add setting")
const updateSetting = () => log("update setting")
const removeSetting = () => log("remove setting")

const tableColumns = [
  "name",
  "reference",
  "type",
  "process",
  "parts",
  "cost",
  "priority",
  "boolean__is_optional",
  "boolean__is_available",
  "boolean__is_visible",
  "settings",
  "actions",
]

const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    reference: i18n.t("reference"),
    created: i18n.t("created"),
    actions: i18n.t("actions"),
    cost: i18n.t("cost"),
    boolean__is_optional: i18n.t("is_optional"),
    boolean__is_available: i18n.t("is_available"),
    boolean__is_visible: i18n.t("is_visible"),
    settings: i18n.t("is_valid"),
  },
  sortable: tableColumns.filter(column => !["actions", "settings"].includes(column)),
  get filterable() {
    return this.sortable
  },
  customSorting: {
    boolean__is_optional: (ascending: boolean) => (a: Machine, b: Machine) =>
      booleanCustomSorting(ascending, a, b, "is_optional"),
    boolean__is_available: (ascending: boolean) => (a: Machine, b: Machine) =>
      booleanCustomSorting(ascending, a, b, "is_available"),
    boolean__is_visible: (ascending: boolean) => (a: Machine, b: Machine) =>
      booleanCustomSorting(ascending, a, b, "is_visible"),
  },
  get filterAlgorithm() {
    return {
      ...generateFilterAlgorithm(this.filterable, "boolean"),
      cost(row: Machine, query: string) {
        const str = convertUnit({ value: row.cost, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
    }
  },
  get listColumns() {
    return getListColumns(this.filterable)
  },
  customFilters: [
    {
      name: "all",
      callback(row: Machine, query: string) {
        return [
          row.name || "",
          row.reference || "",
          row.type || "",
          row.process || "",
          row.parts || "",
          convertUnit({ value: row.cost, conversionRate: 1 }).toFixed(2),
          row.priority || "",
          row.is_optional,
          row.is_available,
          row.is_visible,
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const showForm = $ref({
  limitation: false,
  setting: false,
})
let selectedMachineId = $ref(null)
const selectedMachine = $computed(() => machineStore.getMappedMachineById(selectedMachineId))

const makeForm = (data?: Machine) => ({
  id: "machines-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    type: {
      type: "multiselect",
      label: i18n.t("type"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.type,
      options: machineTypeOptions.map(option => ({
        id: option,
        name: i18n.t(option.toLowerCase()),
      })),
      mapResultTo: "id",
      selectOptions: {
        multiple: false,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
      validations: ["required"],
      reloadOnChange: true,
    },
    process: {
      type: "multiselect",
      label: i18n.t("process"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.process,
      options: machineProcessOptions.map(option => ({
        id: option,
        name: i18n.t(option.toLowerCase()),
      })),
      mapResultTo: "id",
      selectOptions: {
        multiple: false,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
      visible: data?.type == "CUT",
    },
    parts: {
      type: "multiselect",
      label: i18n.t("parts"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.parts,
      options: machinePartsOptions.map(option => ({
        id: option,
        name: i18n.t(option.toLowerCase()),
      })),
      mapResultTo: "id",
      selectOptions: {
        multiple: false,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
      validations: ["required"],
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      placeholder: "",
      defaultValue: data?.name,
      validations: ["required"],
    },
    description: {
      type: "text",
      label: i18n.t("description"),
      placeholder: "",
      defaultValue: data?.description,
    },
    reference: {
      type: "text",
      label: i18n.t("reference"),
      placeholder: "",
      defaultValue: data?.reference,
    },
    cost: {
      type: "number",
      label: i18n.t("cost_per_sec_with_unit"),
      defaultValue: data?.cost,
      validations: ["required"],
    },
    priority: {
      type: "number",
      label: i18n.t("priority"),
      defaultValue: data?.priority,
    },
    is_optional: {
      type: "checkbox",
      label: i18n.t("is_optional"),
      defaultValue: data?.is_optional,
    },
    is_available: {
      type: "checkbox",
      label: i18n.t("is_available"),
      defaultValue: data?.is_available,
    },
    is_visible: {
      type: "checkbox",
      label: i18n.t("is_visible"),
      defaultValue: data?.is_visible,
    },
  },
})

function showFormModal(row, type) {
  if (!["limitation", "setting"].includes(type)) return
  selectedMachineId = row.id

  showForm[type] = true
}

onMounted(() => {
  if (!requestStore.isRequestExist(machineStore.url)) machineStore.fetchAll()
  limitStore.fetchAll()
  settingStore.fetchAll()
})
</script>
