<template>
  <dynamic-form
    :key="formKey"
    :form="formattedForm"
    @submitted="submit"
    @change="values => Object.assign(formValues, values)"
  >
    <template
      v-for="(field, i) in getFieldsByType('hidden')"
      :key="i"
      #[field.key]="{ control }"
    >
      <input v-model="control.value" type="hidden" :name="control.name" />
    </template>
    <template
      v-for="(field, i) in getFieldsByType('multiselect')"
      :key="i"
      #[field.key]="{ control, onChange, onFocus, onBlur }"
    >
      <VueMultiselect
        :id="control.name"
        v-model="control.value"
        :name="control.name"
        :options="field.options"
        :placeholder="control.placeholder || $t('select_or_start_typing')"
        :taggable="field.selectOptions?.taggable || false"
        :multiple="field.selectOptions?.multiple || false"
        :track-by="field.selectOptions?.trackBy || undefined"
        :label="field.selectOptions?.label || undefined"
        :hide-selected="field.selectOptions?.hideSelected || false"
        :searchable="field.selectOptions?.searchable == false ? false : true"
        :select-label="field.selectOptions?.selectLabel || $t('press_enter_select')"
        :selected-label="field.selectOptions?.selectedLabel || $t('selected')"
        :deselect-label="field.selectOptions?.deselectLabel || $t('press_enter_remove')"
        :close-on-select="field.selectOptions?.closeOnSelect == false ? false : true"
        :open-direction="field.selectOptions?.openDirection || undefined"
        @open="() => (multiselectOpened = true)"
        @close="() => (multiselectOpened = false)"
        @focus="onFocus"
        @blur="onBlur"
        @tag="tag => handleOnTag(tag, i)"
      />
      <template v-if="control.validations.length > 0">
        <div
          v-if="
            !control.value && control.validations.map(v => v.type).includes('required')
          "
          class="form-errors"
        >
          <p class="form-error">{{ control.errors?.required?.text }}</p>
        </div>
      </template>
    </template>
    <template
      v-for="(field, i) in getFieldsByType('table')"
      :key="i"
      #[field.key]="{ control, onFocus }"
    >
      <TableField
        :id="control.name"
        v-model="control.value"
        :table-options="field.tableOptions"
        @focus="onFocus"
      />
    </template>
    <template
      v-for="(field, i) in getFieldsByType('range')"
      :key="i"
      #[field.key]="{ control, onFocus, onBlur }"
    >
      <div :class="field.customClass">
        <div class="d-flex">
          <CInputGroup>
            <CInputGroupText>min</CInputGroupText>
            <CFormInput
              v-if="control.value"
              :id="control.name"
              :name="control.name"
              v-model="control.value.min"
              type="number"
              :placeholder="control.placeholder"
              :min="0"
              @input="
                e => {
                  control.value = {
                    min: parseInt(e.target.value),
                    max: parseInt(control.value.max),
                  }
                }
              "
              @focus="onFocus"
              @blur="onBlur"
            />
          </CInputGroup>
          <span class="mx-4" />
          <CInputGroup>
            <CInputGroupText>max</CInputGroupText>
            <CFormInput
              v-if="control.value"
              :id="`${control.name}_max`"
              :name="`${control.name}_max`"
              v-model="control.value.max"
              type="number"
              :placeholder="control.placeholder"
              :min="0"
              @input="
                e => {
                  control.value = {
                    min: parseInt(control.value.min),
                    max: parseInt(e.target.value),
                  }
                }
              "
              @focus="onFocus"
              @blur="onBlur"
            />
          </CInputGroup>
        </div>
        <div
          v-if="control.value && control.value.min > control.value.max"
          class="form-errors"
        >
          <p class="form-error">{{ $t("range_validation") }}</p>
        </div>
      </div>
    </template>
    <template
      v-for="(field, i) in getFieldsByType('equation')"
      :key="i"
      #[field.key]="{ control, onChange, onFocus, onBlur }"
    >
      <EquationField
        :name="control.name"
        v-model="control.value"
        :options="field"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
      />
    </template>
  </dynamic-form>
</template>

<script lang="ts" setup>
import { watch } from "vue"
import { v4 as uuidv4 } from "uuid"
import { cloneDeep, isObject, toArray, isEqual } from "lodash-es"
import { useI18n } from "vue-i18n"
import VueMultiselect from "vue-multiselect"
import {
  CheckboxField,
  CustomField,
  NumberField,
  required,
  SelectField,
  TextField,
  Validator,
  email,
  EmailField,
  PasswordField,
} from "@asigloo/vue-dynamic-forms"
import TableField from "./fields/TableField.vue"
import EquationField from "./fields/EquationField.vue"

const i18n = useI18n()

interface Props {
  form: any
}

const { form = {} } = defineProps<Props>()

const emit = defineEmits(["submit", "change", "reload"])

let formKey = $ref(uuidv4())
let formValues = $ref({})
const multiselectOpened = $ref(false)

const formattedForm = $computed(() => {
  const formattedFields = {}
  for (const key in form.fields) {
    const field = cloneDeep(form.fields[key])
    if (!field.type) continue
    formattedFields[key] = generateField(field, key)
  }
  return { ...form, fields: formattedFields }
})

watch(
  () => form,
  () => {
    formKey = uuidv4()
  },
  { deep: true }
)
watch(() => multiselectOpened, emitValues)
watch(() => formValues, emitValues, { deep: true })

function submit() {
  // emit('submit', formValues);
  emit("submit", emitValues())
  formValues = {}
}

function generateField(field: any, key: string) {
  const validations = []
  for (let i = 0; i < field.validations?.length; i++) {
    const validation = field.validations[i]
    if (validation == "required")
      validations.push(Validator({ validator: required, text: i18n.t("required_field") }))
    if (validation == "email")
      validations.push(Validator({ validator: email, text: i18n.t("invalid_email") }))
  }

  let customClass = field.customClass ? field.customClass : ""
  if (field.visible == false) customClass += " d-none"

  let generatedField = {}
  let { defaultValue } = field
  switch (field.type) {
    case "hidden":
      generatedField = CustomField({
        value: field.defaultValue,
      })
      break
    case "text":
      generatedField = TextField({
        label: field.label,
        placeholder: field.placeholder,
        value: field.defaultValue,
        customClass,
        validations,
        disabled: field.disabled,
        autocomplete: "on"
      })
      break
    case "password":
      generatedField = PasswordField({
        label: field.label,
        placeholder: field.placeholder,
        value: field.defaultValue,
        customClass,
        validations,
        disabled: field.disabled,
      })
      break
    case "email":
      generatedField = EmailField({
        label: field.label,
        placeholder: field.placeholder,
        value: field.defaultValue,
        customClass,
        validations,
        autocomplete: "on"
      })
      break
    case "number":
      generatedField = NumberField({
        label: field.label,
        placeholder: field.placeholder,
        value: field.defaultValue,
        customClass,
        validations,
      })
      break
    case "checkbox":
      generatedField = CheckboxField({
        label: field.label,
        placeholder: field.placeholder,
        value: field.defaultValue,
        customClass,
        validations,
        disabled: field.disabled || false
      })
      break
    case "select":
      generatedField = SelectField({
        label: field.label,
        options: field.options,
        placeholder: field.placeholder,
        value: formValues[key] || field.defaultValue,
        customClass,
        validations,
        autocomplete: "on"
      })
      break
    case "multiselect":
      defaultValue = field.defaultValue

      if (defaultValue && !Array.isArray(defaultValue) && field.selectOptions.multiple)
        throw (
          `multiselect "${field.label}" has option \`multiple\` enabled,` +
          ` but the defaultValue ${JSON.stringify(defaultValue)} is not an array.`
        )

      if (field.mapResultTo) {
        if (
          Array.isArray(defaultValue) &&
          defaultValue.length &&
          typeof defaultValue[0] !== "object"
        )
          defaultValue = field.options.filter(o =>
            defaultValue.includes(o[field.mapResultTo])
          )
        else if (typeof defaultValue !== "object")
          defaultValue = field.options.find(o => o[field.mapResultTo] === defaultValue)
      }

      generatedField = CustomField({
        label: field.label,
        placeholder: field.placeholder,
        value: defaultValue,
        customClass,
        validations,
      })
      break
    case "custom":
    case "table":
    case "equation":
      generatedField = CustomField({
        label: field.label,
        placeholder: field.placeholder,
        value: field.defaultValue,
        customClass,
        validations,
      })
      break
    case "range":
      defaultValue = field.defaultValue ? field.defaultValue : { max: 0, min: 0 }
      if (!Object.prototype.hasOwnProperty.call(defaultValue, "min")) defaultValue.min = 0
      if (!Object.prototype.hasOwnProperty.call(defaultValue, "max")) defaultValue.max = 0
      generatedField = CustomField({
        label: field.label,
        placeholder: field.placeholder,
        value: defaultValue,
        customClass,
        validations,
      })
      break
  }
  return generatedField
}

function getFieldsByType(type) {
  const fields = []
  for (const key in form.fields) {
    const field = form.fields[key]
    if (field?.type == type) {
      fields.push({
        key,
        ...field,
        // options: field.options || [],
        // selectOptions: field.selectOptions || {},
        // defaultValue: field.defaultValue || {}
      })
    }
  }
  return fields
}

function getMultiselectValue(value, key, options) {
  const { mapResultTo } = options
  if (typeof value === "object") {
    const shouldBeArray = options?.selectOptions?.multiple

    value = shouldBeArray ? [...Object.values(value)] : { ...value }
    if (mapResultTo)
      value = Array.isArray(value) ? value.map(v => v[mapResultTo]) : value[mapResultTo]
  }
  return value
}

function isReload(value, options) {
  let defaultValue = options.defaultValue
  if (options.type == 'multiselect') {
    defaultValue = getMultiselectValue(defaultValue, options.key, options)
  }
  return !isEqual(defaultValue, value)
}

function handleOnTag(tag: string, index: number) {
  const fields = getFieldsByType('multiselect')
  const key = fields[index].key
  const values = typeof formValues[key] === 'object' ? Object.values(formValues[key]) : formValues[key]
  values.push({ value: tag, text: tag })
  formValues[key] = values
}

function emitValues() {
  if (multiselectOpened) return
  
  // these keys are added by browser to handle input and are invalid to the app input
  const invalidKeys = ["isTrusted", "_vts"] 

  let reload = false
  let validValues = {}
  for (const key in formValues) {
    if (form.fields[key]?.disabled || invalidKeys.includes(key)) continue
    let v = formValues[key]
    if (form.fields[key]?.type === "multiselect")
      v = getMultiselectValue(v, key, form.fields[key])
    if (form.fields[key]?.type === "table") v = isObject(v) ? toArray(v).sort() : v
    if (form.fields[key]?.type === "range")
      v = { max: parseInt(v.max), min: parseInt(v.min) }
    if (typeof v === "object" && !Array.isArray(v) && Object.keys(v).length === 0)
      v = null
    // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
    if (isNaN(v) && typeof v === "number") v = null
    if (v || ["boolean", "number"].includes(typeof v)) validValues[key] = v

    if (form.fields[key]?.reloadOnChange) reload = isReload(v, form.fields[key])
  }
  if (
    Object.prototype.hasOwnProperty.call(form, "valueProcessor") &&
    typeof form.valueProcessor === "function"
  )
    validValues = form.valueProcessor(validValues)

  emit("change", validValues)

  if (reload) emit("reload", validValues)

  return validValues
}
</script>
