<template>
  <slot />
</template>

<script lang="ts" setup>
import { watch } from "vue"

interface Props {
  modelValue: boolean
}

const { modelValue } = defineProps<Props>()

const emit = defineEmits(["update:modelValue"])

watch(
  () => modelValue,
  () => {
    if (modelValue) setTimeout(() => document.addEventListener("click", onClick), 100)
    else document.removeEventListener("click", onClick)
  }
)

function onClick(event: any) {
  const allowedClasses = ["dz-hidden-input"]
  const parentElement = document.querySelector(`.modal-content`)
  if (!parentElement) return console.error(`.modal-content element not found`)

  let targetSelector = `.modal-content`
  if (Array.from(event.target.classList).length > 0)
    targetSelector += `  .${Array.from(event.target.classList).join(".")}`
  let targetElement = document.querySelector(targetSelector)
  if (!targetElement) targetElement = event.target

  if (
    !parentElement.contains(targetElement)
    && !allowedClasses.includes(event.target.className)
  )
    emit("update:modelValue", false)
}
</script>
