import { getId } from "@/interfaces"
import type { InjectedBatchInterface } from "@/interfaces"
import { isNil, uniq } from "lodash-es"
import { i18n } from "@/plugins/i18n"

export function getMergerOptions(batches) {
  return {
    whitelist: ["id", "reference", "quantity", "process", "operations", "certificates", "material", "thickness"],
    schema: {
      id: {
        type: "integer",
      },
      quantity: {
        type: "number",
      },
      material: {
        type: "namedObject",
        options: id => {
          const row = batches.find(d => d.id == id)
          return row.options?.materials || []
        },
      },
      thickness: {
        type: "enum",
        options: id => {
          const row = batches.find(d => d.id == id)

          // include empty string to allow empty value
          // empty string used because on excel, empty value is converted to empty string
          return [...(row.options?.thicknesses || []).map(thickness => thickness.value.toString()), ""]
        },
      },
      process: {
        type: "namedObject",
        options: id => {
          const row = batches.find(d => d.id == id)
          return row?.options?.processes ? row.options.processes : []
        },
      },
      operations: {
        type: "array",
        options: id => {
          const row = batches.find(d => d.id == id)
          return row?.options?.operations ? row.options.operations.map(o => o.name) : []
        },
      },
      reference: {
        type: "string",
      },
      certificates: {
        type: "array",
        options: id => {
          const row = batches.find(d => d.id == id)
          return row.options?.certificates || []
        },
      },
    },
    dataProcessor: (data: InjectedBatchInterface[]) =>
      data.map(d => ({
        id: d.id,
        quantity: d.quantity,
        material: d.injected_material,
        thickness: d.thickness?.value ? d.thickness.value : "",
        process: d.process,
        operations: d.operations?.map(operation => operation.name) || [],
        reference: d.part?.reference || "",
        certificates: d.certificates,
      })),
    customValidation: (values: any, errors: any) => {
      const mappedBatch = batches.find(b => b.id == values.id)
      if (!mappedBatch) return { errors, values }

      let { stock: stockOptions } = mappedBatch.options
      // thickness has to be number for the checking
      let thickness = values.thickness || values.thickness == 0 ? +values.thickness : null

      // if batch is 3D, thickness must be filled with the pattern thickness
      // if not filled with the pattern thickness, then it is an error
      // keep the stock options with the pattern thickness for material and certificates checking
      const patternThickness = !isNil(mappedBatch.part?.pattern?.thickness) ? parseFloat(mappedBatch.part?.pattern?.thickness.toFixed(3)) : null
      if (mappedBatch.is3D && thickness != patternThickness) {
        errors["thickness"] = i18n.t("3d_part_not_valid_option_with_options", {
          value: values.thickness,
          options: patternThickness,
        })
        thickness = +patternThickness
        stockOptions = stockOptions.filter(stock => stock.thickness === thickness)
      }
      // if no thickness set on merger table, remove thickness error because empty value always valid
      else if (!thickness && thickness !== 0) delete errors.thickness
      // if thickness is set on merger table and is valid, update stock options
      else if (stockOptions.some(stock => stock.thickness === thickness)) {
        stockOptions = stockOptions.filter(stock => stock.thickness === thickness)
        delete errors.thickness
      }

      // if material set on merger table, update stock options
      if (values.material) {
        let stockBasedOnMaterial = stockOptions.filter(
          stock => stock.name.trim() === (values.material?.name || values.material || "").trim()
        )

        // if valid, update stockOptions, if not valid, then material is not valid/error
        if (stockBasedOnMaterial.length) {
          stockOptions = stockBasedOnMaterial
          delete errors.material
        } else {
          errors["material"] = i18n.t("variable_not_valid_option_with_options", {
            value: values.material?.name || values.material || "",
            options: uniq(stockOptions.map(stock => stock.name).sort()).join("\n - "),
          })
        }
      }

      if ((values.certificates || []).length > 0) {
        // delete errors.certificates because we are going to revalidate
        delete errors.certificates

        const certificateOptions = uniq(stockOptions.map(stock => stock.certificates).reduce((a, b) => a.concat(b), []))
        const validCertificates = uniq(
          certificateOptions.filter(certificate => values.certificates.includes(certificate))
        )
        if (validCertificates.length !== values.certificates.length) {
          errors["certificates"] = i18n.t("variable_not_valid_option_with_options", {
            value: values.certificates.join(", "),
            options: certificateOptions.join("\n - "),
          })
        }
      }
      return { errors, values }
    },
    valueProcessor: (values: any) => values,
  }
}

export function generateMergerPayload({ id, batch, changes }) {
  const keyChecks = ["stock", "quantity", "machines", "certificates", "reference"]

  const batchPayload: Record<string, any> = {}
  const partPayload: Record<string, string> = {}

  let error = false

  for (let i = 0; i < keyChecks.length; i++) {
    const key = keyChecks[i]
    switch (key) {
      case "stock":
        if (!Object.prototype.hasOwnProperty.call(changes, "material") || !Object.prototype.hasOwnProperty.call(changes, "thickness")) {
          batchPayload.stock = null
          break
        }

        const stock = batch.options.stock.find((s) => (s.name || "").trim() === (changes.material?.name || changes.material || "").trim() && s.thickness === +changes.thickness)
        if (!stock) {
          console.error("no stock found with", { material: changes.material, thickness: changes.thickness })
          error = true
          break
        }
        batchPayload.stock = getId(stock)
        break
      case "machines":
        if (Object.prototype.hasOwnProperty.call(changes, "process")) {
          const machine = batch.options.machines.find(
            (m: any) => m.name.toUpperCase() === (changes.process?.name || changes.process || "").toUpperCase()
          )
          if (!machine) {
            error = true
            break
          }
          batchPayload.machines = [...(batchPayload.machines || []), machine]
        }

        if (Object.prototype.hasOwnProperty.call(changes, "operations")) {
          const machines = batch.options.machines.filter((m: any) =>
            changes.operations.includes(m.name)
          )
          if (machines.length !== changes.operations.length) {
            error = true
            break
          }
          batchPayload.machines = [...(batchPayload.machines || []), ...(machines || [])]
        }
        break

      default:
        if (Object.prototype.hasOwnProperty.call(changes, key)) {
          if (key !== "reference") batchPayload[key] = changes[key]
          else if (batch.part.reference !== changes[key]) partPayload[key] = changes[key]
        }
        break
    }
    if (error) break
  }

  return { error, batchPayload, partPayload }
}