<template>
  <div class="wrapped-with-fsghpnsidq">
    <CContainer fluid class="px-0">
      <CCard>
        <CCardHeader>
          <CRow class="my-3 d-flex align-items-center">
            <CCol
              class="d-flex flex-column justify-content-between align-self-start"
              :md="6"
            >
              <CCardTitle class="mb-0">{{ title }}</CCardTitle>
              <CCardText class="fw-light">{{ subtitle }}</CCardText>
            </CCol>
            <CCol :md="2" class="d-flex justify-content-end">
              <CButton
                v-if="showAddButton"
                color="success"
                style="height: 32px"
                @click="addButtonClickHandler"
              >
                <i class="fa fa-plus" />
                {{ addText }}
              </CButton>
            </CCol>
            <slot name="filters" :data="inputSearch">
              <CCol :sm="showMergeOptions ? '3' : '4'">
                <CInputGroup class="float-right with-button">
                  <CInputGroupText class="btn btn-secondary">
                    <i class="fa fa-search" />
                  </CInputGroupText>
                  <CFormInput
                    id="global-filter"
                    v-model="inputSearch"
                    type="text"
                    placeholder="Filter"
                  />
                </CInputGroup>
              </CCol>
            </slot>
            <CCol v-if="showMergeOptions" :sm="1">
              <MergerModal
                :title="title"
                :id-column="mergeOptions.idColumn"
                :set-row="setRow"
                :show="mergeModal"
                :can-validate="false"
                :schema="mergeOptions.schema"
                :data="data"
                :data-processor="mergeOptions.dataProcessor"
                :value-processor="mergeOptions.valueProcessor"
                :custom-validation="mergeOptions.customValidation"
                @close="mergeModal = false"
                @open="mergeModal = true"
                @done="data => emit('merge-done', data)"
              >
                <template #mergeTitle>
                  {{ $t("merge-component-title") }}
                </template>
              </MergerModal>
            </CCol>
          </CRow>

          <slot name="table-header" />
        </CCardHeader>
        <CCardBody class="v-tables-component">
          <p v-if="isLoading" class="loading">{{ $t("loading") }}</p>

          <v-client-table
            v-else
            ref="tableElement"
            :key="key"
            :data="computedData"
            :columns="columns"
            :options="options"
            @row-click="rowClickHandler"
            @loaded="handleTableLoaded"
          >
            <template #name="data">
              <b>{{ data.row.name }}</b>
              <div class="text-muted small" style="cursor: pointer">
                <i class="fa fa-copy" />
                {{ data.row.id }}
              </div>
            </template>

            <template #reference="data">
              <b>{{ data.row.reference }}</b>
              <div class="text-muted small" style="cursor: pointer">
                <i class="fa fa-copy" />
                {{ data.row.id }}
              </div>
            </template>

            <template #customer="data">
              <b v-if="data.row.customer">{{ data.row.customer.name }}</b>
              <b v-else>/</b>
              <div
                v-if="data.row.customer"
                class="text-muted small"
                style="cursor: pointer"
              >
                <i class="fa fa-copy" />
                {{ data.row.customer.id }}
              </div>
            </template>

            <template #order_id="data">
              <button type="button" class="text-left btn btn-link">
                {{ data.row.id }}
              </button>
            </template>

            <template #user="data">
              <b>{{ data.row.user }}</b>
              <div class="text-muted small" style="cursor: pointer">
                <i class="fa fa-copy" />
                {{ data.row.user }}
              </div>
            </template>

            <template #extension="data">
              <CBadge v-if="data.row.extension == 'dxf'" color="warning">.dxf</CBadge>
              <CBadge v-if="data.row.extension == 'step'" color="primary">.step</CBadge>
              <CBadge v-if="data.row.extension == 'stp'" color="primary">.stp</CBadge>
              <CBadge v-if="data.row.extension == 'pdf'" color="danger">.pdf</CBadge>
            </template>

            <!-- This is duplicate but different please check -->
            <!-- <template #delivery_date="data">
              <span>{{ data.row.delivery_date }}</span><br>
              <div class="text-muted small">{{ data.row.delivery_date }}</div>
            </template> -->

            <template #total_amount="data">
              <div v-if="data.row.total_amount != null">
                {{ Math.round(data.row.total_amount * 100) / 100 }}
              </div>
              <div v-if="data.row.total_amount == null">/</div>
            </template>

            <!-- <template #process="data">
              <CBadge color="secondary">{{ data.row.process }}</CBadge
              ><br />
            </template> -->

            <template #username="data">
              <b>{{ data.row.username }}</b>
              <div
                class="text-muted small"
                style="cursor: pointer"
                @click.stop="copyId(data.row.id)"
              >
                <i class="fa fa-copy" />
                {{ data.row.id }}
              </div>
            </template>

            <template #first_name="data">
              {{ data.row.last_name }}
              <br />
              {{ data.row.first_name }}
            </template>

            <template #active="data">
              <label v-if="data.row.is_active" class="switch switch-pill switch-success">
                <input type="checkbox" class="switch-input" checked disabled />
                <span class="switch-slider" />
              </label>
              <label v-if="!data.row.is_active" class="switch switch-pill switch-success">
                <input type="checkbox" class="switch-input" disabled />
                <span class="switch-slider" />
              </label>
            </template>

            <template #material="{ row }">
              {{ row?.material?.name }}
            </template>

            <template #certificates="data">
              <span v-for="(certificate, i) in data.row.certificates" :key="i">
                <CBadge v-if="certificate == 'TYPE_22'" color="secondary">2.2</CBadge>
                <CBadge v-if="certificate == 'TYPE_31'" color="secondary">3.1</CBadge>
                <CBadge v-if="certificate == 'TYPE_32'" color="secondary">3.2</CBadge>
              </span>
            </template>

            <!-- This causing error -->
            <!-- <template #h__thickness>
              <span>Thickness</span>
              <Unit v-model="length_unit" :units="length_units"></Unit>
            </template> -->

            <template #thickness="data">
              <span>
                {{ ((data.row.thickness || 0) * length_unit.conversion).toFixed(2) }}
              </span>
            </template>

            <!-- This causing error -->
            <!-- <template #h__length>
                <span>Length</span>
                <Unit v-model="length_unit" :units="length_units"></Unit>
            </template> -->

            <template #length="data">
              <span>
                {{ ((data.row.length || 0) * length_unit.conversion).toFixed(2) }}
              </span>
            </template>

            <!-- This causing error -->
            <!-- <template #h__width>
                <span>Width</span>
                <Unit v-model="length_unit" :units="length_units"></Unit>
            </template> -->

            <template #width="data">
              <span>{{ ((data.row.width || 0) * length_unit.conversion).toFixed(2) }}</span>
            </template>

            <!-- This causing error -->
            <!-- <template #h__height>
                <span>Height</span>
                <Unit v-model="length_unit" :units="length_units"></Unit>
            </template> -->

            <template #height="data">
              <span>
                {{ ((data.row.height || 0) * length_unit.conversion).toFixed(2) }}
              </span>
            </template>

            <!-- This causing error -->
            <!-- <template #h__cost>
                <span>Cost</span>
                <Unit v-model="cost_unit" :units="cost_units"></Unit>
            </template> -->

            <template #cost="data">
              <span v-if="cost_unit.conversion == 0">
                {{ (data.row.cost || 0).toFixed(2).replace(".", ",") }}
              </span>
              <span v-if="cost_unit.conversion == 1">
                {{
                  (1e-6 * data.row.height * data.row.width * data.row.cost)
                    .toFixed(2)
                    .replace(".", ",")
                }}
              </span>
            </template>

            <!-- This causing error -->
            <!-- <template #h__rate>
                <span>Cost</span>
                <Unit v-model="rate_unit" :units="rate_units"></Unit>
            </template> -->

            <template #rate="data">
              <span>
                {{
                  ((data.row.cost || 0) * rate_unit.conversion).toFixed(2).replace(".", ",")
                }}
              </span>
            </template>

            <template #resource="data">
              <code>{{ data.row.resource }}</code>
            </template>

            <template #client_id="data">
              <code>{{ data.row.client_id }}</code>
            </template>

            <template #access_token="data">
              <code>{{ data.row.access_token }}</code>
            </template>

            <template #refresh_token="data">
              <code>{{ data.row.refresh_token }}</code>
            </template>

            <template #type="data">
              <CBadge v-if="data.row.type == 'STEEL'" color="secondary">
                {{ $t("steel") }}
              </CBadge>
              <CBadge v-if="data.row.type == 'STAINLESS'" color="secondary">
                {{ $t("stainless") }}
              </CBadge>
              <CBadge v-if="data.row.type == 'ALUMINIUM'" color="light">
                {{ $t("aluminium") }}
              </CBadge>
              <CBadge v-if="data.row.type == 'OTHER'" color="dark">
                {{ $t("other") }}
              </CBadge>

              <CBadge v-if="data.row.type == 'RECTANGULAR'" color="dark">
                {{ $t("rectangular") }}
              </CBadge>
              <CBadge v-if="data.row.type == 'SQUARE'" color="dark">
                {{ $t("square") }}
              </CBadge>
              <CBadge v-if="data.row.type == 'ROUND'" color="dark">
                {{ $t("round") }}
              </CBadge>

              <CBadge v-if="data.row.type == 'CUT'" color="dark">{{ $t("cut") }}</CBadge>
              <CBadge v-if="data.row.type == 'BEND'" color="secondary">
                {{ $t("bend") }}
              </CBadge>
            </template>

            <template #stock="data">
              <span style="">{{ data.row.stock }}</span>
            </template>

            <template #method="data">
              <CBadge v-if="data.row.method == 'OPTIONS'" color="secondary">
                {{ data.row.method }}
              </CBadge>
              <CBadge v-if="data.row.method == 'GET'" color="success">
                {{ data.row.method }}
              </CBadge>
              <CBadge v-if="data.row.method == 'POST'" color="primary">
                {{ data.row.method }}
              </CBadge>
              <CBadge v-if="data.row.method == 'PATCH'" color="warning">
                {{ data.row.method }}
              </CBadge>
              <CBadge v-if="data.row.method == 'DELETE'" color="danger">
                {{ data.row.method }}
              </CBadge>
            </template>

            <template #status="data">
              <CBadge v-if="Math.trunc(data.row.status / 100) == 2" color="success">
                {{ data.row.status }}
              </CBadge>
              <CBadge v-if="Math.trunc(data.row.status / 100) == 3" color="warning">
                {{ data.row.status }}
              </CBadge>
              <CBadge v-if="Math.trunc(data.row.status / 100) == 4" color="warning">
                {{ data.row.status }}
              </CBadge>
              <CBadge v-if="Math.trunc(data.row.status / 100) == 5" color="danger">
                {{ data.row.status }}
              </CBadge>

              <CBadge v-if="data.row.status == 'CREATED'" color="dark">
                {{ $t("created") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'IN_REVIEW'" color="secondary">
                {{ $t("in_review") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'CONFIRMED'" color="warning">
                {{ $t("confirmed") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'QUOTED'" color="warning">
                {{ $t("quoted") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'ORDERED'" color="success">
                {{ $t("ordered") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'PRODUCED'" color="secondary">
                {{ $t("produced") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'COMPLETED'" color="success">
                {{ $t("completed") }}
              </CBadge>
              <CBadge v-if="data.row.status == 'CANCELLED'" color="danger">
                {{ $t("cancelled") }}
              </CBadge>
            </template>
            <template #code="data">
              <span>{{ data.row.code }}</span>
            </template>

            <!-- <template #actions="data">
              <slot name="actions" :data="data">
                <span>{{ $t("no_actions") }}</span>
              </slot>
            </template> -->


            <!--
              id and actions column have the same handler
              code below combining them into a single handler
            -->
            <template
              v-for="(column, i) in ['id', 'actions']"
              :key="i"
              #[column]="data"
            >
              <div class="col-lg-12 button-box text-nowrap d-flex">
                <Popover
                  v-if="actionEdit"
                  :text="$t('preview')"
                >
                  <CButton
                    v-if="actionViewer"
                    class="btn-space btn-sm"
                    color="secondary"
                    @click.stop.prevent="
                      emit('action-clicked', { action: 'viewer', data: data.row })
                    "
                  >
                    <i class="fa fa-search" />
                  </CButton>
                </Popover>
                <Popover
                  v-if="actionEdit"
                  :text="`${$t('edit')} ${title}`"
                >
                  <CButton
                    class="btn-space btn-sm"
                    color="primary"
                    @click.stop.prevent="editRow(data)"
                  >
                    <i class="fa fa-edit" />
                  </CButton>
                </Popover>
                <Popover
                  v-if="actionDelete"
                  :text="`${$t('remove')} ${title}`"
                >
                  <CButton
                    v-if="actionDelete"
                    class="btn-space btn-sm text-white"
                    color="danger"
                    @click.stop.prevent="deleteRow(data.row)"
                  >
                    <i class="fa fa-trash" />
                  </CButton>
                </Popover>
              </div>
            </template>

            <!--
              Type based columns are the columns that grouped based on their types
              e.g. date__delivery_date means the column type is date and value is delivery_date
              This is very useful as we don't have to define each date columns to format them
            -->
            <template
              v-for="(column, i) in getTypeBasedColumns('date__')"
              :key="i"
              #[column]="{ row }"
            >
              <span>{{ dateFormat(row[column.replace("date__", "")]) }}</span>
              <div class="text-muted small">
                {{ daysAgo(row[column.replace("date__", "")]) }}
              </div>
              <span
                v-if="
                  column.includes('delivery_date') &&
                  row[column.replace('date__', '')] === null
                "
              >
                {{ $t("not_set") }}
              </span>
            </template>
            <template
              v-for="(column, i) in getTypeBasedColumns('boolean__')"
              :key="i"
              #[column]="{ row }"
            >
              <i
                :style="`color: ${row[column.replace('boolean__', '')] ? 'green' : 'red'}`"
                :class="`text-center w-100 fa fa-${
                  row[column.replace('boolean__', '')] ? 'check' : 'times'
                }`"
              />
            </template>

            <!--
              This is for handling slots added on component usage
            -->
            <template v-for="(_, slot) of $slots" #[slot]="scope">
              <slot :name="slot" v-bind="scope" />
            </template>
          </v-client-table>
        </CCardBody>
      </CCard>
    </CContainer>
    <FormModal
      :title="isEditing ? editText : addText"
      :show="showFormModal"
      :form="form"
      :ok-button-text="isEditing ? $t('save') : $t('add')"
      @reset="onFormReset"
      @submit="onFormSubmitted"
      @change="values => emit('formChanged', values)"
      @reload="onFormReload"
    />
  </div>
</template>

<script lang="ts" setup>
import { type UnwrapRef, type DefineComponent, onUnmounted } from "vue"
import { isEqual, pick, pickBy, values } from "lodash-es"
import sweetalert from "sweetalert2"
import { useI18n } from "vue-i18n"
import { watchEffect, watch } from "vue"
import type {
  OptimisticAddOptionInterface, OptimisticUpdateOptionInterface, MergerSchema
} from "@/interfaces"
import { mergerDefaultIdColumn } from "@/interfaces"
import FormModal from "@/components/modals/FormModal.vue"
import MergerModal from "@/components/modals/MergerModal.vue"
import { dateFormat, daysAgo, getFilterEmitsOptions } from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"
import { globalStore } from "@/store"

const i18n = useI18n()

const {
  title = "Title",
  subtitle = "Subtitle",
  columns = [],
  addText = "",
  editText = "",
  showAddButton = false,
  actionEdit = false,
  actionDelete = false,
  actionViewer = false,
  defaultSort = {
    column: "created",
    ascending: false,
  },
  rowClick,
  addButtonClick,
  mergeOptions = (() => ({
    idColumn: "id",
    whitelist: [] as string[],
    schema: {} as MergerSchema,
  }))() as Props["mergeOptions"],
  api,
  makeForm,
  showMergeOptions = false,
  dataFilter,
  tableOptions,
  data,
  add = () => {},
  update = () => {},
  remove = () => {},
} = defineProps<Props>()

let form: any = $ref()

const mergeModal = $ref(false)
const isLoading = $ref(false)
const key = $computed(() => (data || []).length || 0)

const emit = defineEmits([
  "add",
  "update",
  "formChanged",
  "rowClick",
  "reset",
  "delete",
  "action-clicked",
  "merge-done",
  "table-loaded",
])

let showFormModal = $ref(false)
const isEditing = $computed(() => !!form?.fields?.id?.defaultValue)
const tableData = $ref([])
const options = $computed(() => {
  const filterableColumns = tableOptions.filterable ? tableOptions.filterable : columns
  return {
    pagination: {
      chunk: 5,
      nav: "scroll",
      edge: false, 
      show: false // hide pagination because it moved into app footer
    },
    sortIcon: {
      base: "fa fa-lg",
      up: "fa-sort-asc",
      down: "fa-sort-desc",
      is: "fa-sort",
    },
    perPageValues: [1, 20, 30, 40, 50],
    perPage: 50,
    skin: "table table-hover",
    orderBy: defaultSort,
    page: 1,
    texts: {
      count: "",
      filter: "",
      filterPlaceholder: "",
      limit: "",
      page: "",
      noResults: i18n.t("no_results"),
      loading: i18n.t("loading"),
    },
    // commented because slots not handled if activated
    // templates:{
    //   created: function(h, row) {
    //     return moment(row.created).fromNow();
    //   },
    //   updated: function(h, row) {
    //     return moment(row.updated).fromNow();
    //   },
    //   start_time: function(h, row) {
    //     return moment.unix(row.start_time).fromNow();
    //   },
    //   expires: function(h, row) {
    //     return moment(row.expires).fromNow();
    //   },
    // },
    filterByColumn: true,
    customFilters: [
      {
        name: "all",
        callback(row: any, query: string) {
          const str = values(pick(row, filterableColumns))
            .join("#####") // ##### as separator
            .toLowerCase()
          return str.includes(query.toLowerCase())
        },
      },
    ],
    ...tableOptions,
  }
})
const length_unit = $ref({ name: "mm", conversion: 1 })
const length_units = $ref([
  { name: "mm", conversion: 1 },
  { name: "cm", conversion: 1e-1 },
  { name: "m", conversion: 1e-3 },
])
const cost_unit = $ref({ name: "€ / m<sup>2</sup>", conversion: 0 })
// let cost_units = $ref([
//   {"name": "€ / m<sup>2</sup>", "conversion": 0},
//   {"name": "€ / pcs.", "conversion": 1}
// ])
const rate_unit = $ref({ name: "€ / sec", conversion: 1 })
const rate_units = $ref([
  { name: "€ / sec", conversion: 1 },
  { name: "€ / min", conversion: 60 },
  { name: "€ / hr", conversion: 3600 },
])
const inputSearch = $ref("")
const tableElement = $ref(null)

const rowClickHandler = $computed(() => rowClick || editRow)
const addButtonClickHandler = $computed(() => addButtonClick || onAddButtonClick)
const computedData = $computed(() => {
  let filteredData = data
  if (dataFilter) filteredData = filteredData.filter(dataFilter)
  return filteredData
})

async function setRow(id, rowData) {
  const { whitelist, idColumn = mergerDefaultIdColumn } = mergeOptions
  let oldRow = data.find(d => id == d[idColumn])
  if (oldRow) oldRow = pick(oldRow, whitelist)
  rowData = pick(rowData, whitelist)

  if (!oldRow) return add(rowData, { withoutOptimistic: true })

  const hasChanged = (value, key) => oldRow[key] != value && !isEqual(oldRow[key], value)

  const changes = pickBy(rowData, hasChanged)
  if (!Object.keys(changes).length) return {}

  return update({ id: rowData.id, ...changes }, { withoutOptimistic: true })
}

function copyId(id) {
  console.log("copy id:", id)
}

watchEffect(() => {
  if (tableElement) {
    tableElement.setCustomFilters({ all: inputSearch })
    tableElement.$.emitsOptions = {
      ...tableElement.$.emitsOptions,
      ...getFilterEmitsOptions(tableOptions.filterable || []),
    }
  }
})
watch(
  () => globalStore.footer.pagination.currentPage,
  () => tableElement.setPage(globalStore.footer.pagination.currentPage)
)

function editRow(data: any) {
  form = makeForm(data.row)
  showFormModal = true
}
function deleteRow(row: any) {
  sweetalert
    .fire({
      title: i18n.t("remove"),
      text: i18n.t("delete_row_body"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: i18n.t("delete"),
      cancelButtonText: i18n.t("cancel"),
    })
    .then(result => {
      if (result.isConfirmed) {
        remove(row)
        emit("delete", row)
      }
    })
    .catch()
}
function onFormSubmitted(values: any) {
  showFormModal = false
  isEditing ? update(values) : add(values)
}
function onFormReset() {
  showFormModal = false
  form = makeForm()
}
function onFormReload(values) {
  form = makeForm(values)
}
function onAddButtonClick() {
  onFormReset()
  showFormModal = true
}
function getTypeBasedColumns(prefix: string) {
  return columns.filter((v: string) => v.includes(prefix))
}
function handleTableLoaded() {
  if (tableElement) {
    globalStore.changeFooter({
      totalData: (tableElement.allFilteredData || []).length,
      perPage: tableElement.options.perPage,
      currentPage: tableElement.$refs?.table?.page || 1,
      showPagination: true
    })
  }
}

onUnmounted(() => {
  globalStore.reset(["footer"])
})
</script>

<script lang="ts">
/**
 * Explanation of this code block:
 * We use this separate <script lang="ts" /> block
 * to be able to export types and constants.
 * Since we are not able to do that within the <script setup /> block.
 * Also, one important note is that this code block
 * HAS TO BE beneath the <script setup /> code block.
 * If you swap them around, then eslint will move
 * all the code from this block to the other block,
 * thereby breaking the code.
 **/

// eslint-disable-next-line import/order
import type { ArrayElement } from "@/interfaces"

interface VTableOrderBy {
  column: string
  ascending: boolean
}

interface TableCustomFilter {
  name: string
  callback: (row: any, query: string) => boolean
}

export interface VTableOptions {
  headings: Record<string, string>
  sortable?: string[]
  filterable?: string[]
  listColumns?: Record<string, Record<string, any>[]>
  filterAlgorithm?: Record<string, (row: any, query: string) => boolean>
  customSorting?: Record<string, (ascending: boolean) => (a: any, b: any) => number>
  customFilters?: TableCustomFilter[]
}

type GetApiData = () => Pick<Record<any, any>, "data" | "isLoading"> &
  Partial<Pick<Record<any, any>, "add" | "update" | "remove">>

interface Props<T extends GetApiData = GetApiData> {
  title?: string
  subtitle?: string
  columns?: string[]
  addText?: string
  editText?: string
  showAddButton?: boolean
  actionEdit?: boolean
  actionDelete?: boolean
  actionViewer?: boolean
  defaultSort?: VTableOrderBy
  rowClick?: (event: { index: number; event: PointerEvent; row: DataRow<T> }) => void

  addButtonClick?: (event: MouseEvent) => any
  mergeOptions?: {
    idColumn?: string
    whitelist: string[]
    schema: MergerSchema
    dataProcessor?: (data: any[]) => any[]
    valueProcessor?: (data: any[]) => any[]
    customValidation?: (value: any) => { errors: any; values: any }
  }
  api?: T
  makeForm: (data?: DataRow<T>) => object
  showMergeOptions?: boolean
  dataFilter?: () => void
  tableOptions: VTableOptions
  data: any[],
  add?: (values: any, options?: OptimisticAddOptionInterface) => void,
  update?: (values: any, options?: OptimisticUpdateOptionInterface) => void,
  remove?: (values: any) => void,
}

type UnwrappedData<T extends GetApiData> = UnwrapRef<ReturnType<T>["data"]>
type DataRow<T extends GetApiData> = ArrayElement<UnwrappedData<T>>

type A<T extends GetApiData> = {
  [key in keyof DataRow<T>]: { row: DataRow<T> }
}

type B<T extends GetApiData> = {
  [key in string]: { row: DataRow<T> }
}

export type VTablesType<T extends GetApiData> = DefineComponent<Props<T>> & {
  __VLS_slots: A<T> & B<T>
}

</script>

<style lang="scss">
.wrapped-with-fsghpnsidq {
  .card-header {
    background-color: white;
  }
  .button-box {
    align-items: center;
  }
  .btn-space {
    margin: 0px 5px 3px 0px;
  }
  .statusDiv {
    display: flex;
    flex-wrap: wrap;
    label {
      padding: 0 10px 0 0px;
      min-width: 120px;
      display: flex;
      align-items: center;
      input {
        margin-right: 4px;
      }
    }
  }
  table th {
    cursor: pointer;
  }
  p.loading {
    text-align: center;
  }
  .v-tables-component {
    .table-responsive {
      overflow-y: hidden;
    }
  }
}
</style>
