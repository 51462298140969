<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" size="lg" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>{{ profile?.name }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div class="text-center">
          <p v-html="image" />
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="isVisible = false">
          {{ $t("close") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { watchEffect } from "vue"
import type { Profile } from "@/interfaces"
import { profileStore } from "@/store"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

interface Props {
  modelValue: boolean
  profile?: Profile
}

const { modelValue, profile } = defineProps<Props>()
let image = $ref(null)

const emit = defineEmits(["update:modelValue"])

const isVisible: any = $computed({
  get() {
    return modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})

watchEffect(() => {
  if (profile && isVisible) {
    image = null
    profileStore
      .getImage(profile.id)
      .then((data) => image = data)
      .catch()
  }
})
</script>
