<template>
  <group :id="`group_${tree?.index}`" ref="model_group">
    <Transform
      v-if="tree?.reference !== null && tree?.reference !== undefined"
      class="tree-inline"
      :translation="treeTranslationText"
      :rotation="treeOrientationText"
    >
      <inline
        v-show="modelUrl && tree?.reference === tree?.index"
        :id="`model_${tree?.index}`"
        ref="inline_model"
        :d-e-f="`model_${tree?.index}`"
        :map-d-e-f-to-i-d="false"
        onload="console.log"
        :name-space-name="tree?.index"
        :url="modelUrl"
        content-type="model/x3d+xml"
      />

      <shape
        v-show="!modelUrl || tree?.reference !== tree?.index"
        :id="`model_${tree?.index}`"
        :use="`model_${tree?.reference}`"
      />
    </Transform>

    <tree-inline
      v-for="(component, index) in tree?.components"
      :key="index"
      :tree="component"
      :part-id="partId"
      @loaded="id => emit('loaded', id)"
      @clicked="e => emit('clicked', e)"
    />
  </group>
</template>

<script lang="ts" setup>
import { onMounted, watchEffect } from "vue"
import axios from "axios"
import type { ComponentTree } from "@/interfaces"

interface Props {
  tree: ComponentTree
  partId: number
}

const { tree, partId } = defineProps<Props>()

const emit = defineEmits(["loaded", "clicked"])

// $refs
const inline_model = $ref(null)
const model_group = $ref(null)
let modelUrl = $ref(null)

const treeTranslationText = $computed(() => tree?.translation.join(" "))
const treeOrientationText = $computed(() => tree?.orientation.join(" "))

watchEffect(() => {
  if (partId && tree?.reference) {
    if (!modelUrl) generateModelUrl()
    else if (!tree?.components.length) emit("loaded", tree?.index)
  }
})

function generateModelUrl() {
  // no need to fetch if already defined
  if (modelUrl) return

  axios
    .get(`/v1/parts/${partId}/components/${tree?.reference}/models/0`)
    .then(({ data }) => {
      const x3dBlob = new Blob([data], { type: "text/plain" })
      modelUrl = URL.createObjectURL(x3dBlob)
    })
    .catch()
}

onMounted(() => {
  model_group.onclick = (e: X3dMouseEvent) => emit("clicked", e)
})
</script>
