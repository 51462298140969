<template>
  <CCard>
    <CCardBody>
      <CButton
        class="btn-space btn-sm floatRight"
        color="secondary"
        @click="visible = false"
      >
        <i class="fa fa-times" />
      </CButton>
      <div class="successBlock">
        <i class="fa fa-times fa-5x" style="color: red" />
        <h3>{{ $t("upload_error") }}</h3>
        <p>
          <span v-html="message" />
        </p>
      </div>
    </CCardBody>
  </CCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "ErrorCard",
  props: {
    show: {
      required: true,
      type: Boolean
    },
    message: {
      required: true,
      type: String
    }
  },
  emits: ["update:model-value"],
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(v) {
        this.$emit("update:model-value", v)
      }
    }
  },
})
</script>
