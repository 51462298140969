<template>
  <div class="text-center pdf-viewer">
    <div
      v-if="pdfUrl"
      class="pdf-viewer__actions"
    >
      <div
        class="d-flex flex-1 justify-content-center"
      >
        <CButton
          class="border-radius__right__none"
          color="secondary"
          size="sm"
          @click.stop.prevent="calcScale('minus')"
        >
        <i class="fa fa-magnifying-glass-minus" />
        </CButton>
        <input
          v-model="formattedScale"
          class="form-control border-radius__none zoom-input"
          @click.stop
          @focus="showScalePercentage = false"
          @blur="showScalePercentage = true"
        />
        <CButton
          class="border-radius__left__none"
          color="secondary"
          size="sm"
          @click.stop.prevent="calcScale('plus')"
        >
          <i class="fa fa-magnifying-glass-plus" />
        </CButton>

        <CButton
          class="ms-3"
          color="secondary"
          size="sm"
          @click.stop.prevent="fitTo('width')"
        >
          <i class="fa fa-arrows-left-right" />
          {{ $t("fit_to_width") }}
        </CButton>
        <CButton
          class="ms-3"
          color="secondary"
          size="sm"
          @click.stop.prevent="fitTo('height')"
        >
          <i class="fa fa-arrows-up-down" />
          {{ $t("fit_to_height") }}
        </CButton>
      </div>
      <div
        class="position-absolute"
        style="right: 1rem;"
      >
        <CButton
          color="primary"
          size="sm"
          @click.stop.prevent="download()"
        >
          <i class="fa fa-download" />
          {{ $t("download") }}
        </CButton>
      </div>
    </div>
    <div
      v-for="(page, index) in pages"
      :key="index"
      :id="`page-${index + 1}`"
    >
      <VuePDF
        :pdf="pdf"
        :page="page"
        :scale="scale / 100"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { saveAs } from "file-saver"
import { usePDF, VuePDF } from "@tato30/vue-pdf"

export default defineComponent({
  name: "PdfViewer",
  components: {
    VuePDF
  },
  props: {
    filename: {
      type: String,
      default: "file.pdf"
    },
    pdfUrl: {
      type: String,
      required: true
    }
  },
  data: () => ({
    pdf: null,
    pages: [],
    scale: 150,
    showScalePercentage: true,
  }),
  computed: {
    formattedScale: {
      get() {
        return `${this.scale}${this.showScalePercentage ? "%" : ""}`
      },
      set(v: string) {
        if (!v) return 0
        let scale = parseFloat(v)
        scale = scale > 300 ? 300 : scale
        this.scale = Math.abs(scale)
      }
    },
  },
  watch: {
    pdfUrl() {
      this.computePdf()
    }
  },
  methods: {
    computePdf() {
      if (!this.pdfUrl) return
      const { pdf, pages }: any = usePDF(this.pdfUrl);
      this.pdf = pdf
      this.pages = pages as unknown as any[]
    },
    download() {
      saveAs(this.pdfUrl, this.filename)
    },
    calcScale(operation: "minus" | "plus" = "plus") {
      const multiplier = Math.floor(this.scale / 25)
      let scale = 25 * (multiplier + (operation === "plus" ? 1 : -1))
      scale = scale > 300 ? 300 : scale
      this.scale = scale < 25 ? 25 : scale
    },
    fitTo(target: "width" | "height" = "width") {
      const container = document.querySelector(".pdf-viewer") as any
      const element = document.querySelector("#page-1") as any
      if (!container || !element) return
      const containerLength = target === "width" ? container.parentElement.offsetWidth : container.parentElement.offsetHeight - 33.6
      const elementLength = target === "width" ? element.offsetWidth : element.offsetHeight
      this.scale = Math.floor(containerLength / elementLength * this.scale)
    }
  },
  mounted() {
    if (this.pdfUrl) this.computePdf()
  }
})
</script>

<style lang="scss">
.pdf-viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    height: 3rem;
    width: 100%;
    background: white;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    position: sticky;
    top: 0px;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0px 1rem;
  }
  input.zoom-input {
    width: 60px;
    height: 27.5px;
  }
}
</style>