<template>
  <div class="app d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :lg="5" :md="6" :sm="8">
          <CCard class="p-4">
            <CCardBody v-if="!authStore.isRegistered">
              <CForm>
                <h1>{{ $t("register") }}</h1>
                <p class="text-muted">{{ $t("all_fields_required") }}</p>

                <CInputGroup class="mb-3">
                  <CInputGroupText>
                    <CIcon icon="fa-user" />
                  </CInputGroupText>
                  <CFormInput
                    v-model="model.username"
                    :placeholder="$t('username')"
                    :invalid="!!errors.username"
                    autocomplete="username"
                  />
                  <CFormFeedback invalid class="text-start">
                    {{ errors.username }}
                  </CFormFeedback>
                </CInputGroup>
                <CInputGroup class="mb-3">
                  <CInputGroupText>
                    <CIcon icon="fa-envelope" />
                  </CInputGroupText>
                  <CFormInput
                    v-model="model.email"
                    :placeholder="$t('email')"
                    :invalid="!!errors.email"
                    autocomplete="email"
                  />
                  <CFormFeedback invalid class="text-start">
                    {{ errors.email }}
                  </CFormFeedback>
                </CInputGroup>
                <CInputGroup class="mb-3">
                  <CInputGroupText>
                    <CIcon icon="fa-key" />
                  </CInputGroupText>
                  <CFormInput
                    v-model="model.password"
                    type="password"
                    :placeholder="$t('password')"
                    :invalid="!!errors.password"
                    autocomplete="current-password"
                  />
                  <CFormFeedback invalid class="text-start">
                    {{ errors.password }}
                  </CFormFeedback>
                </CInputGroup>
                <CInputGroup>
                  <CInputGroupText>
                    <CIcon icon="fa-arrows-rotate" />
                  </CInputGroupText>
                  <CFormInput
                    v-model="model.retype_password"
                    type="password"
                    :placeholder="$t('confirm_password')"
                    :invalid="!!errors.retype_password"
                  />
                  <CFormFeedback invalid class="text-start">
                    {{ errors.retype_password }}
                  </CFormFeedback>
                </CInputGroup>
                <CInputGroup class="mb-3">
                  <PasswordMeter :password="model.password" />
                </CInputGroup>
                <CInputGroup class="mb-3">
                  <CFormCheck
                    class="mb-1"
                    inline
                    type="checkbox"
                    :label="$t('register_as_organization')"
                    :checked="model.is_company"
                    @click="model.is_company = !model.is_company"
                  />
                  <small>{{ $t("register_as_organization_text") }}</small>
                </CInputGroup>
                <CInputGroup class="mb-3 d-flex flex-column">
                  <CRow>
                    <CCol class="d-flex align-items-center">
                      <CFormCheck
                        class="mb-0 me-1"
                        inline
                        type="checkbox"
                        :label="$t('accept_the')"
                        :checked="model.is_accepted"
                        @click="model.is_accepted = !model.is_accepted"
                      />
                      <a href="/terms" target="_blank">
                        {{ $t("terms_and_conditions") }}
                      </a>
                    </CCol>
                  </CRow>
                  <small v-if="errors.is_accepted" style="color: #f86c6b">
                    {{ errors.is_accepted }}
                  </small>
                </CInputGroup>
                <CRow class="mt-4">
                  <CCol>
                    <Recaptcha @verified="token => (model.recaptcha = token)" />
                    <small v-if="errors.recaptcha" style="color: #f86c6b">
                      {{ errors.recaptcha }}
                    </small>
                  </CCol>
                </CRow>
                <CRow class="mt-4">
                  <CCol class="text-end d-grid">
                    <CButton block color="primary" @click="checkForm">
                      <span v-if="!isLoading">{{ $t("create_account") }}</span>
                      <CSpinner v-else size="sm" color="white" />
                    </CButton>
                  </CCol>
                </CRow>
                <CRow class="mt-3">
                  <CCol class="text-start d-grid">
                    <a class="text-nowrap" :href="login_url">
                      <CIcon icon="fa-arrow-left" class="me-2" />
                      {{ $t("back_to_login") }}
                    </a>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
            <CCardBody v-else>
              <div class="d-flex justify-content-between mb-4 align-items-center">
                <h1>{{ $t("almost_done") }}</h1>
                <CIcon icon="fa-paper-plane" size="4xl" />
              </div>
              <p class="text-muted">{{ $t("verification_email_send") }}</p>
              <CRow class="mt-4">
                <CCol class="text-end d-grid">
                  <CButton block color="primary" @click="router.push(login_url)">
                    {{ $t("login") }}
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import PasswordMeter from "vue-simple-password-meter"
import { onMounted } from "vue"
import { authStore } from "@/store"
import Recaptcha from "@/components/Recaptcha.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("register") })

const route = useRoute()
const router = useRouter()

const model = $ref({
  username: (route.query.username || "") as string,
  password: "",
  retype_password: "",
  nickname: (route.query.username || "") as string,
  email: "",
  is_company: false,
  is_accepted: false,
  recaptcha: "",
})
let errors = $ref(
  {} as {
    username?: string
    password?: string
    email?: string
    retype_password?: string
    is_accepted?: string
    recaptcha?: string
  }
)

const manufacturer_nickname = $computed(() => (route.params.nickname || "") as string)
const prefix = manufacturer_nickname ? `/${manufacturer_nickname}` : ""

const login_url = $computed(() => {
  const url = `${prefix}/login`
  return model.username ? `${url}?username=${model.username}` : url
})
let isLoading = $ref(false)

const checkForm = async (event: UIEvent) => {
  if (isLoading) return
  event.preventDefault()

  errors = {}

  if (!model.username) errors.username = i18n.t("username_required")

  if (!model.email) {
    errors.email = i18n.t("enter_email")
  } else if (!isEmailValid(model.email)) {
    errors.email = i18n.t("invalid_email")
  }

  if (!model.password) errors.password = i18n.t("fill_new_password")

  if (model.password !== model.retype_password)
    errors.retype_password = i18n.t("retype_pass_not_same")

  if (!model.is_accepted) errors.is_accepted = i18n.t("accept_term_and_condition")
  if (!model.recaptcha) errors.recaptcha = i18n.t("accept_recaptcha")

  if (Object.keys(errors).length === 0) {
    submit()
  }
}

function isEmailValid(email: string) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

const submit = () => {
  isLoading = true
  authStore.register({
    username: model.username,
    recaptcha: model.recaptcha,
    password: model.password,
    nickname: model.nickname,
    email: model.email,
    is_company: model.is_company,
    is_manufacturer: model.is_accepted,
  })
    .finally(() => isLoading = false)
}

onMounted(() => {
  authStore.isRegistered = false
})
</script>
<style scoped lang="scss">
.app {
  background-image: url("/img/bg/pattern.svg");
  background-size: cover;
}
</style>
