<template>
  <div 
    class="wrapped-with-gnaarmyqvz tooltip-box"
    :class="[`tooltip-box__justify-${justify}`]"
    @mouseover="handleMouseHover"
    @mouseleave="handleMouseLeave"
  >
    <slot />
    <div
      :id="id"
      class="tooltip tooltip__theme-dark top"
      :class="{
        'position-fixed': position === 'fixed',
      }"
      :style="{
        width: `${width}px`,
        left,
        top,
        visibility: visible ? 'visible' : 'hidden',
      }"
    >
      <span class="text" v-html="text" />
    </div>
    <div
      ref="placeholder_element"
      style="
        visibility: hidden;
        white-space: nowrap;
        position: absolute;
        width: auto;
        height: auto;
      "
      v-html="text"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
  name: 'Popover',
  props: {
    text: {
      type: String,
      default: 'Tooltip'
    },
    justify: {
      type: String,
      default: 'center'
    },
    position: {
      type: String,
      default: "absolute"
    }
  },
  data() {
    return {
      width: 0,
      visible: false,
      top: "auto",
      left: "50%"
    }
  },
  computed: {
    id() {
      return `tooltip-${uuidv4()}`
    }
  },
  methods: {
    handleMouseHover(e: Event) {
      if (this.position === "fixed") {
        const boundingRect = (e.target as HTMLElement).getBoundingClientRect()
        const tooltip = document.getElementById(this.id)
        const tooltipBoundingRect = tooltip.getBoundingClientRect()

        // this is calculated based on trial and error
        this.top = `${boundingRect.top * 1.25 - tooltipBoundingRect.height * 1.25 - 10}px`
        this.left = `${boundingRect.left * 1.25 - tooltipBoundingRect.width * 3/5 + 4}px`
      }

      this.visible = true
    },
    handleMouseLeave() {
      this.visible = false
    }
  },
  mounted() {
    const placeholder_element: any = this.$refs.placeholder_element
    if (!placeholder_element) {
      this.width = 0
      return
    }
    placeholder_element.style.fontSize = 12;
    this.width = placeholder_element.clientWidth + 12;
  },
  setup() {},
})
</script>


<style lang="scss">
.wrapped-with-gnaarmyqvz {
  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  &.tooltip-box {
    position: relative;
    display: flex;
    justify-content: center;
    &__justify-left {
      justify-content: flex-start;
      .tooltip {
        left: 1.1rem;
        transform: translateX(-100%);
      }
    }
  }

  .tooltip {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    border-radius: 3px;
    padding: 0.5rem;
    left: 50%;
    opacity: 1;
    transition: opacity 1s linear;
    position: absolute;
    z-index: var(--popover-z-index);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
    .text {
      white-space: nowrap;
      font-size: 12px;
    }
    &.top:not(.position-fixed) {
      bottom: 100%;
      margin-bottom: 8px;
      transform: translateX(-47.5%);
      .text::after {
        top: 100%;
        transform: translateX(-47.5%);
      }
    }
    &.bottom:not(.position-fixed) {
      top: 100%;
      margin-top: 8px;
      transform: translateX(-50%);
      .text::after {
        bottom: 100%;
        transform: translate(0, 0) rotate(180deg);
      }
    }
    &__theme-light{
      background-color: #ffffff;
      color: #3f4254;
      & .text::after{
        border-color: #fff transparent transparent transparent;
      }
    }
    &__theme-dark{
      color: #ffffff;
      background-color: #393a3e;
      & .text::after{
        border-color: #393a3e transparent transparent transparent;
      }
    }
    &.position-fixed {
      position: fixed;
    }
  }

  .text::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
  }
}
</style>
