<template>
	<Datepicker
		v-bind="$attrs"
    :alt-position="calculatePosition"
  >
    <!--
      This is for handling slots added on component usage
    -->
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Datepicker>
</template>

<script lang="ts" setup>
import "@vuepic/vue-datepicker/dist/main.css"
import Datepicker from "@vuepic/vue-datepicker"

function calculatePosition(el: HTMLElement) {
  const appElement = document.getElementById("app-body")
  const appElementBoundingRect = appElement.getBoundingClientRect()

  const boundingRect = el.getBoundingClientRect()
  const calendarElement = document.querySelector(".dp__menu.dp__menu_index")
  const calendarElementBoundingRect = calendarElement?.getBoundingClientRect()

  const scaledTop = boundingRect.top * 1.25

  let top = scaledTop + boundingRect.height + 20
  if (top + calendarElementBoundingRect.height > appElementBoundingRect.height) {
    top = scaledTop - calendarElementBoundingRect.height - 2 * boundingRect.height - 20
    const arrowElement = calendarElement.querySelector(".dp__arrow_top")
    if (arrowElement) {
      arrowElement.classList.remove("dp__arrow_top")
      arrowElement.classList.add("dp__arrow_bottom")
    }
  }
  return {
    top: `${top}px`,
    left: `${boundingRect.left + 4}px`,
    transform: "transformX(0)"
  }
} 
</script>