import { defineStore } from "pinia"
import { forEach } from "lodash-es"
import type { CreateMaterial, Material } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import type { OptimisticAddOptionInterface, OptimisticUpdateOptionInterface } from "@/interfaces"
import { addOrUpdate, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"

const cacheKey = "materials"

export default defineStore("material", {
  state: () => ({
    all: [],
  }),
  getters: {},
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Material[]
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: "/v1/materials",
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchByManufacturerId(manufacturerId: number, { persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: `/v1/manufacturers/${manufacturerId}/materials`,
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    add(values: CreateMaterial, options: OptimisticAddOptionInterface = {}) {
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/materials",
        onSuccess: () => {
          if (!options?.withoutOptimistic) setCache(cacheKey, this.all)
        },
        ...options
      })
    },
    update(values: Material, options: OptimisticUpdateOptionInterface) {
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/materials/${values.id}`,
        onSuccess: () => {
          if (!options?.withoutOptimistic) setCache(cacheKey, this.all)
        },
        ...options
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/materials/${id}`,
        id,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
  },
})
