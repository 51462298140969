<template>
  <i
    v-if="iconIsFontAwesomeType"
    :class="['fa-icon', type ? type : 'fa-solid', icon, size]"
  />

  <BaseCIcon
    v-else-if="icon"
    :icon="icon"
    :size="size"
    :custom-classes="customClasses"
  />
</template>

<script lang="ts" setup>
import { CIcon as BaseCIcon } from "@coreui/icons-vue"
import { asyncComputed } from "@vueuse/core"
import { dynamicallyImportIcon } from "@/libraries/helpers"

const {
  icon: iconName,
  size,
  customClasses = "",
  type = null
} = defineProps<{
  icon: string
  size?: string
  customClasses?: string | string[] | object
  type?: string
}>()

let icon: string | string[] = $(
  asyncComputed(async () => {
    if (iconName.startsWith("fa")) return iconName
    return await dynamicallyImportIcon(iconName)
  }, "")
)

const iconIsFontAwesomeType = $computed(
  () => typeof icon === "string" && icon.startsWith("fa")
)
</script>
