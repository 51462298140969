<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" @close="close">
      <CModalHeader>
        <CModalTitle>
          {{ $t(`${data ? "edit" : "add"}_pricing`) }}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <DynamicFormWrapper :form="form" @submit="onSubmit" />
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <div class="d-flex flex-grow-1">
          <CButton class="me-4" color="secondary" @click="close">
            {{ $t("cancel") }}
          </CButton>
        </div>
        <CButton v-if="data" class="me-4" color="danger">
          {{ $t("remove") }}
        </CButton>
        <CButton color="success" type="submit" :form="form.id">
          {{ $t("save") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { PRICING_EQUATION_VARIABLES } from "@/constants"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

export default defineComponent({
  name: "PricingFormModal",
  components: {
    DynamicFormWrapper,
    ModalWrapper
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "submit"],
  computed: {
    isVisible: {
      get() {
        return this.show
      },
      set(v: boolean) {
        if (v == false) this.$emit("close")
      },
    },
    form() {
      return {
        id: "pricings-form",
        fields: {
          id: {
            type: "hidden",
            defaultValue: this.data?.id,
          },
          startup_amount: {
            type: "equation",
            label: this.$t("startup_amount"),
            defaultValue: this.data?.startup_amount,
            validations: [],
            variables: PRICING_EQUATION_VARIABLES
          },
          discount_amount: {
            type: "equation",
            label: this.$t("discount_amount"),
            defaultValue: this.data?.discount_amount,
            validations: [],
            variables: PRICING_EQUATION_VARIABLES
          },
          shipping_amount: {
            type: "equation",
            label: this.$t("shipping_amount"),
            defaultValue: this.data?.shipping_amount,
            validations: [],
            variables: PRICING_EQUATION_VARIABLES
          },
          tax_rate: {
            type: "number",
            label: this.$t("tax_rate"),
            defaultValue: this.data?.tax_rate,
            validations: [],
          },
        },
      }
    },
  },
  methods: {
    onSubmit(values: any) {
      this.isVisible = false
      this.$emit("submit", values)
    },
    close() {
      this.isVisible = false
    },
  },
  setup() {},
})
</script>
