<template>
  <div
    v-show="globalStore.components.popover.show"
    id="global-popover"
    class="wrapped-with-pcraoqcfsf global-popover"
  >
    <div
      class="d-flex p-4"
    >
      
      <div
        v-if="batch?.part?.source?.extension !== 'dxf'"
        class="pe-4 ms-4 border-right"
      >
        <h3 class="d-flex flex-1">3D</h3>
        <div class="h-100 pb-5 mb-5 d-flex align-items-center">
          <span
            v-if="batch?.part?.source?.extension && image3D"
            class="bigger"
            v-html="image3D"
          />
          <CSpinner v-else />
        </div>
      </div>
      <div v-if="batch?.part?.type === 'SHEET'" class="pe-4 ms-4 border-right">
        <h3 class="d-flex flex-1">2D</h3>
        <div class="h-100 pb-5 mb-5 d-flex align-items-center">
          <span
            v-if="batch?.part?.type === 'SHEET' && image2D"
            class="bigger"
            v-html="image2D"
          />
          <CSpinner v-else />
        </div>
      </div>
      <div class="pe-4 ms-4">
        <h3 class="d-flex flex-1">{{ data?.name }}</h3>
        <CRow class="ms-4 description-container">
          <CCol class="d-flex flex-column justify-content-center">
            <span v-for="key in list" :key="key" class="mt-2 text-nowrap">
              {{ $t(key) }}
            </span>
          </CCol>
          <CCol class="d-flex flex-column justify-content-center ms-4">
            <span v-for="key in list" :key="key" class="mt-2 text-nowrap">
              {{ data[key] }}
            </span>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import type { Sheet, Profile, Batch } from "@/interfaces"
import { partTypeOptions } from "@/interfaces"
import { authStore, globalStore, partStore } from "@/store"

const i18n = useI18n()

const batch = $computed<Batch>(() => globalStore.components.popover.batch as Batch)

type Map = Record<string, (is_manufacturer: boolean) => typeof partTypeOptions[number][]>

// This map defines what properties will be visible for what part types.
// Just as a reminder, the possible types are TUBE, SHEET, ASSEMBLY, and OTHER.
const map: Map = {
  section: () => ["TUBE"],
  thickness: () => ["SHEET", "TUBE"],
  length: () => ["TUBE"],
  width: () => [],
  height: () => [],
  pattern_width: () => ["SHEET"],
  pattern_height: () => ["SHEET"],
  material: () => ["OTHER", "SHEET", "TUBE"],
  quantity: () => [...partTypeOptions], // This means quantity will be shown for all types
  process: () => ["OTHER", "SHEET", "TUBE"],
  operations: () => ["OTHER", "SHEET", "TUBE"],
  source: () => [...partTypeOptions],
  weight: () => ["OTHER", "SHEET", "TUBE"],

  // for manufacturer only
  stock: is_manufacturer => (is_manufacturer ? ["SHEET", "TUBE"] : []),
  stock_cost: is_manufacturer => (is_manufacturer ? ["SHEET", "TUBE"] : []),
  total_contour_length: is_manufacturer => (is_manufacturer ? ["SHEET", "TUBE"] : []),
  total_contour_group: is_manufacturer => (is_manufacturer ? ["SHEET", "TUBE"] : []),
  process_cycle_time: is_manufacturer =>
    is_manufacturer ? ["OTHER", "SHEET", "TUBE"] : [],
  total_operation_cycle_time: is_manufacturer =>
    is_manufacturer ? ["OTHER", "SHEET", "TUBE"] : [],
  total_manufacturing_time: is_manufacturer =>
    is_manufacturer ? ["OTHER", "SHEET", "TUBE"] : [],
}

// This list is computed from the map defined above.
// This list will only contain the keys that fit the part type and the user role.
const list = $computed<string[]>(() =>
  Object.keys(map).reduce((list, key) => {
    const types = map[key](authStore.authenticatedUser?.is_manufacturer)
    if (types.includes(batch?.part?.type)) list.push(key)
    return list
  }, [])
)

// Another kind of map used later
const thicknesses: Record<typeof batch["part"]["type"], number | null> = $computed(() => ({
  TUBE: batch?.thickness?.value || batch?.part?.section?.thickness,
  SHEET: batch?.thickness?.value,
  ASSEMBLY: null,
  OTHER: null,
}))

function isProfile(material: Sheet | Profile): material is Profile {
  return "length" in material
}

function round(n: number, unit = "") {
  // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
  return n == null || isNaN(n) ? "" : `${+n.toFixed(2)} ${unit}`
}

const data = $computed(() => ({
  section: batch?.part?.section?.hash,
  name: batch?.part?.name,
  thickness: round(thicknesses[batch?.part?.type], "mm"),
  length: round(batch?.part?.section?.length || batch?.part?.length, "mm"),
  width: round(batch?.part?.width, "mm"),
  height: round(batch?.part?.height, "mm"),
  pattern_width: round(batch?.part?.pattern?.width, "mm"),
  pattern_height: round(batch?.part?.pattern?.height, "mm"),
  material: batch?.stock?.name,
  quantity: batch?.quantity,
  source: `${batch?.part?.source?.name}.${batch?.part?.source?.extension}`,
  weight: round(
    batch?.stock?.material.density * (batch?.part?.volume / 1_000_000_000),
    "kg"
  ),
  process: batch?.process?.process,
  operations:
    batch?.operations?.map(o => o.name).join(", ") ||
    `<${i18n.t("no_operations_selected")}>`,
  stock: batch?.stock
    ? isProfile(batch?.stock)
      ? round(batch?.stock.length, "mm")
      : `${round(batch?.stock.width)} mm x ${round(batch?.stock.height)} mm`
    : "",
  stock_cost: batch?.stock
    ? isProfile(batch?.stock)
      ? `${batch?.stock.cost.toFixed(2)} € / m`
      : `${batch?.stock.cost.toFixed(2)} € / m²`
    : "",
  total_contour_length: round(
    (batch?.part?.pattern?.contour?.boundary || 0) +
      batch?.part?.pattern?.holes.reduce((boundary, hole) => boundary + hole.boundary, 0),
    "mm"
  ),
  total_contour_group:
    batch?.part?.pattern?.holes.length + (batch?.part?.type === "TUBE" ? 0 : 1),
  process_cycle_time: batch?.stock
    ? round(
        batch?.times
          .filter(time => time.machine.type === "CUT")
          .reduce((total, time) => total + time.cycle_time, 0),
        "s"
      )
    : "",
  total_operation_cycle_time: batch?.stock
    ? round(
        batch?.times
          .filter(time => time.machine.type !== "CUT")
          .reduce((total, time) => total + time.cycle_time, 0),
        "s"
      )
    : "",
  get total_manufacturing_time() {
    return batch?.stock
      ? round(
          parseFloat(this.process_cycle_time) +
            parseFloat(this.total_operation_cycle_time) +
            batch?.times.reduce((total, time) => total + time.startup_time, 0),
          "s"
        )
      : ""
  },
}))

const image3D = $computed(() => partStore.all3DImages[batch?.part?.id])
const image2D = $computed(() => partStore.all2DImages[batch?.part?.id])
</script>

<style lang="scss">
.wrapped-with-pcraoqcfsf {
  &.global-popover {
    position: absolute;
    background: white;
    z-index: calc(var(--popover-z-index) - 1);
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 0.5rem;
    min-width: 400px;
    min-height: 400px;
  }

  .bigger svg {
    max-width: 400px;
    max-height: 400px;
    min-height: 200px;
    min-width: 200px;
  }

  .description-container {
    display: flex;
    flex-wrap: nowrap;
    max-width: 600px;
    span {
      height: 15px;
    }
  }
}
</style>
