<template>
  <div 
    :id="id"
    class="table-field"
  >
    <v-client-table
      ref="table"
      :columns="options.columns"
      :data="datas"
      :options="options.options"
    >
      <template #afterBody>
        <tr class="VueTables__no-results">
          <td class="text-center pt-2" colspan="11">
            <a class="cursor-pointer" @click="addRow()">
              {{ $t("add") + " " + $t("row") }}
              <i style="" class="fa fa-plus ms-1" />
            </a>
          </td>
        </tr>
      </template>

      <template #thickness="data">
        <CFormInput
          v-model.number="data.row.thickness"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #radius="data">
        <CFormInput
          v-model.number="data.row.radius"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #k_factor="data">
        <CFormInput
          v-model.number="data.row.k_factor"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #pierce_time="data">
        <CFormInput
          v-model.number="data.row.pierce_time"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #area_small="data">
        <CFormInput
          v-model.number="data.row.area_small"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #area_medium="data">
        <CFormInput
          v-model.number="data.row.area_medium"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #area_large="data">
        <CFormInput
          v-model.number="data.row.area_large"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #feed_small="data">
        <CFormInput
          v-model.number="data.row.feed_small"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #feed_medium="data">
        <CFormInput
          v-model.number="data.row.feed_medium"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #feed_large="data">
        <CFormInput
          v-model.number="data.row.feed_large"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>
    </v-client-table>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from "lodash-es"
import { v4 as uuidv4 } from "uuid"

interface Props {
  modelValue: any[]
  tableOptions: any
  id: string
}

const { modelValue = [], tableOptions, id = uuidv4() } = defineProps<Props>()

const emit = defineEmits(["update:modelValue"])

let value: any = $computed({
  get: () => modelValue,
  set: v => emit("update:modelValue", v),
})
let datas = $computed({
  get() {
    if (!value) return []
    return cloneDeep(value).map((d, index) => ({ ...d, index }))
  },
  set(newValue: any) {
    value = newValue.map(v => {
      delete v.index
      return v
    })
  },
})
const options = $computed(() => ({
  ...tableOptions,
  options: {
    ...tableOptions.options,
    editableColumns: tableOptions.columns,
  },
}))

function addRow() {
  const row = {}
  for (let i = 0; i < options.columns.length; i++) {
    const element = options.columns[i]
    row[options.columns[i]] = null
  }
  let newValue = cloneDeep(value)
  if (newValue) newValue.push(row)
  else newValue = [row]
  value = newValue
}
function updateRow(newValue) {
  const newDatas = cloneDeep(datas)
  newDatas[newValue.index] = newValue
  datas = newDatas
}
</script>

<style lang="scss">
.wrapped-with-tquheswgrd {
  &.field {
    tr:not(.VueTables__no-results) td {
      padding: 0;
    }
    input {
      border: none;
    }
  }
}
</style>
