<template>
  <div class="table-footer wrapped-with-zhndxrfisfsfs">
    <span v-html="text" />
    <div v-if="pagination.show && pagination.totalData > pagination.perPage">
      <CPagination
        aria-label="Page navigation example"
        class="ms-3 pagination"
      >
        <CPaginationItem
          aria-label="Previous"
          :disabled="!allowPrev"
          @click="allowPrev ? $emit('page-changed', pagination.currentPage - 1) : () => ({})"
        >
          <span aria-hidden="true">&laquo;</span>
        </CPaginationItem>
        <CPaginationItem
          v-for="(page, index) in visiblePages"
          :active="page === pagination.currentPage"
          :key="index"
          @click="$emit('page-changed', page)"
        >
          {{ page }}
        </CPaginationItem>
        <CPaginationItem
          aria-label="Next"
          :disabled="!allowNext"
          @click="allowNext ? $emit('page-changed', pagination.currentPage + 1) : () => ({})"
        >
          <span aria-hidden="true">&raquo;</span>
        </CPaginationItem>
      </CPagination>
    </div>
  </div>
</template>

<script lang="ts">
import { last } from 'lodash-es'
import { defineComponent } from 'vue'

interface Pagination {
  currentPage: number
  perPage: number
  totalData: number
  show: boolean
}

export default defineComponent({
  name: "TableFooter",
  props: {
    pagination: {
      type: Object as () => Pagination,
      default: () => ({}),
      required: false
    },
    text: {
      type: String,
      required: true
    },
  },
  emits: ["page-changed"],
  computed: {
    allowPrev() {
      return this.pagination.currentPage > 1
    },
    allowNext() {
      return this.pagination.totalData > this.pagination.perPage * this.pagination.currentPage
    },
    visiblePages() {
      // only show 2 pages before and after current page
      if (!this.pagination.show) return []
      const totalPage = Math.ceil(this.pagination.totalData / this.pagination.perPage)

      const { currentPage } = this.pagination
      let start = currentPage - 2
      if (start <= 0) start = 1

      let end = currentPage + 2
      if (end >= totalPage) end = totalPage
      
      const twoPagesBeforeAndAfterCurrentPage = []
      for (let i = start; i <= end; i++) {
        twoPagesBeforeAndAfterCurrentPage.push(i)
      }
      while (twoPagesBeforeAndAfterCurrentPage.length < 5 && !twoPagesBeforeAndAfterCurrentPage.includes(totalPage)) {
        twoPagesBeforeAndAfterCurrentPage.push((last(twoPagesBeforeAndAfterCurrentPage) || 0) + 1)  
      }

      return twoPagesBeforeAndAfterCurrentPage
    },
  },
  setup() {},
})
</script>

<style lang="scss">
.wrapped-with-zhndxrfisfsfs {
  &.table-footer {
    display: flex;
    align-items: center;
    .pagination {
      ul {
        margin-bottom: 0px
      }
      .page-item {
        .page-link:not(:disabled) {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
