<template>
  <CCard
    class="cardHover cursor-pointer customer-info-card"
    :class="[`text-${color}`]"
  >
    <CCardBody
      @click="$emit('click')"
    >
      <CRow>
        <CCol>
          <h5 class="pt-1">
            <i class="mr-1 fa fa-building" />
            {{ data.name }}
          </h5>
          <div class="small">#{{ data.company_number }}</div>
        </CCol>
        <CCol
          class="d-flex flex-column justify-content-end align-items-end"
        >
          <div v-if="data.phone" class="mb-1 small">
            {{ data.phone }}
            <i class="ms-2 fa fa-phone" />
          </div>
          <div v-if="data.email" class="mb-1 small">
            {{ data.email }}
            <i class="ms-2 fa fa-envelope" />
          </div>
          <div v-if="data.domain" class="small">
            {{ data.domain }}
            <i class="ms-2 fa fa-globe" />
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "CustomerInfoCard",
  props: {
    data: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "secondary",
    }
  },
  emits: ["click"],
  setup() {},
})
</script>

<style lang="scss" scoped>
.cardHover {
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
}
.customer-info-card {
  &.text-primary {
    border-color: var(--primary)
  }
}
</style>
