<template>
  <div class="customer-multiple-picker-modal">
    <ModalWrapper
      v-model="isVisible"
    >
      <CModal
        :visible="isVisible"
        backdrop="static"
        @close="() => (isVisible = false)"
      >
        <CModalHeader>
          <CModalTitle>{{ $t("apply_price_to_customers") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow class="mb-2">
            <CCol
              class="d-flex column flex-wrap align-items-center"
              style="gap: 0.5rem"
            >
              <h6 class="my-0">
                {{ $t("selected_customers") }}:
              </h6>
              <CButton
                v-for="customer in selectedCustomers"
                :key="customer.id"
                color="primary"
                variant="outline"
                class="py-1 cursor-initial selected-customer-button"
              >
                <span class="me-2">{{ customer.name }}</span>
                <span
                  class="fa fa-close cursor-pointer text-danger"
                  @click.stop="toggle(customer)"
                />
              </CButton>
            </CCol>
          </CRow>
          <hr class="bigHr" />
          <CRow class="mb-2">
            <CCol :sm="7">
              <CFormInput
                v-model="search"
                :placeholder="$t('search')"
                aria-label="Search"
              />
            </CCol>
            <CCol :sm="5" class="d-grid">
              <CButton
                color="success"
                @click="() => (showForm = true)"
              >
                <i class="fa fa-plus" />
                {{ $t("add_new_customer") }}
              </CButton>
            </CCol>
          </CRow>
          <CRow
            v-if="filteredDatas.length > 0"
            style="max-height: 75vh; overflow-y: scroll"
          >
            <CCol>
              <CustomerInfoCard
                v-for="(customer, index) in filteredDatas"
                :key="index"
                :data="customer"
                class="mt-2"
                :color="inputSelected.includes(customer.id) ? 'primary' : 'secondary'"
                @click="toggle(customer)"
              />
            </CCol>
          </CRow>
          <CRow v-if="filteredDatas.length == 0" class="py-3">
            <CCol>
              <div class="text-muted text-center">
                {{ $t("no_results") }}
              </div>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" @click="save">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>

    <CustomerAddFormModal
      :show="showForm"
      @close="() => (showForm = false)"
      @submit="v => customerStore.addWithAddress(v).catch().finally(() => (showForm = false))"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ModalWrapper from "@/components/modals/ModalWrapper.vue"
import { customerStore } from '@/store'
import CustomerInfoCard from '@/components/CustomerInfoCard.vue'
import { cloneDeep } from 'lodash-es'
import CustomerAddFormModal from './CustomerAddFormModal.vue'

export default defineComponent({
  name: "CustomerMultiplePickerModal",
  components: {
    ModalWrapper,
    CustomerInfoCard,
    CustomerAddFormModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ["update:modelValue", "save"],
  data() {
    return {
      inputSelected: [],
      search: "",
      showForm: false
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.modelValue && !this.showForm
      },
      set(v: boolean) {
        this.$emit("update:modelValue", v)
      }
    },
    filteredDatas() {
      return customerStore.all.filter((customer) => {
        return (
          (customer.name || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (customer.company_number || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (customer.email || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (customer.phone || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (customer.domain || "").toLowerCase().includes(this.search.toLowerCase())
        )
      }).sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
    selectedCustomers() {
      return customerStore.all.filter((customer) => this.inputSelected.includes(customer.id))
    }
  },
  watch: {
    isVisible() {
      /* 
        Have to be put under $nextTick to avoid values changes during save
        Have to be cloneDeep to avoid reactivity and change parent values
      */
      this.$nextTick(() => {
        this.inputSelected = cloneDeep(this.selected)
        this.search = "" // reset search
      })
    },
  },
  methods: {
    toggle(customer: any) {
      if (this.inputSelected.includes(customer.id)) {
        this.inputSelected = this.inputSelected.filter((id) => id != customer.id)
      } else {
        this.inputSelected.push(customer.id)
      }
    },
    save() {
      this.$emit("save", this.inputSelected)
      this.isVisible = false
    }
  },
  setup() {
    return {
      customerStore
    }
  },
})
</script>

<style lang="scss" scoped>
.selected-customer-button {
  // disable hover effect
  &:hover {
    border-color: var(--cui-btn-color) !important;
    background: none !important;
    color: var(--cui-btn-color) !important;
  }
  span.fa:hover {
    transform: scale(1.25);
  }
}
</style>
