<template>
  <div class="wrapped-with-asdffyvxqssvw button-switch">
    <CButton
      v-for="(item, index) in items"
      :key="index"
      :color="item.value === inputValue ? 'primary' : 'secondary'"
      type="button"
      @click="inputValue = item.value"
    >
      {{ item.text }}
    </CButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "ButtonSwitch",
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    value: {
      required: true
    }
  },
  emits: ["update:model-value"],
  data: () => ({

  }),
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("update:model-value", v)
      }
    }
  },
  setup() {
    
  },
})
</script>
