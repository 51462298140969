<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" size="lg" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>{{ $t("upload_file") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Dropzone
          id="upload-modal-dropzone"
          :options="dropzoneOptions"
          @success="(original, file) => emit('uploaded', original, file)"
          @error="file => emit('error', file)"
        />
        <div class="text-muted small">{{ $t("supported_files") }}</div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="isVisible = false">
          {{ $t("close") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import Dropzone from "@/components/Dropzone.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

interface Props {
  modelValue: boolean
  options?: any
}

const { modelValue, options = () => ({}) } = defineProps<Props>()

const emit = defineEmits(["update:modelValue", "uploaded", "error"])

const isVisible: any = $computed({
  get() {
    return modelValue
  },
  set(v) {
    emit("update:modelValue", v)
  },
})
const dropzoneOptions = $computed(() => ({
  addRemoveLinks: false,
  acceptedFiles: ".step,.stp,.dxf",
  dictDefaultMessage: i18n.t("upload_modal_body"),
  thumbnailWidth: 150,
}))
</script>
