<template>
  <div class="d-flex flex-column">
    <span class="mb-2">{{ text }}</span>
    <div class="image-input__container">
      <input
        ref="fileInput"
        class="file-upload"
        type="file"
        accept="image/png"
        @change="onFileChanged"
      />
      <div
        class="image-input__wrapper"
        :style="{
          'background-image': `url(${fileData})`,
        }"
      />
      <div class="image-input__overlay" :class="{ 'd-flex': loading }">
        <CButton v-if="!loading" color="primary" @click="fileInput.click()">
          <strong>{{ uploadText }}</strong>
        </CButton>
        <CSpinner v-else />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watchEffect } from "vue"
import axios from "axios"
import { useI18n } from "vue-i18n"
import { fileStore, toastStore } from "@/store"
import { errorHandler } from "@/libraries/helpers"

const i18n = useI18n()

interface Props {
  text: string
  uploadText: string
  defaultImage: any
  imageId: number
}

const {
  text = "Image",
  uploadText = "Upload Image",
  defaultImage,
  imageId,
} = defineProps<Props>()

const emit = defineEmits(["uploaded"])

// $refs
const fileInput = $ref(null)

let fileData = $ref(defaultImage)
let loading = $ref(false)

watchEffect(() => {
  if (imageId) {
    loading = true
    axios
      .get(`/v1/files/${imageId}/data`, {
        responseType: "blob",
        headers: {
          "x-no-sw-cache": true,
        },
      })
      .then(response => {
        fileData = window.URL.createObjectURL(response.data)
      })
      .catch()
      .finally(() => (loading = false))
  }
})

async function onFileChanged(event: any) {
  const file = event.target.files[0]
  if (!file) return
  try {
    loading = true
    const data = await fileStore.add(file)
    emit("uploaded", data)
  } catch (error: any) {
    const { response } = error
    const message = errorHandler.generateErrorMessage(response.status) || response?.data?.status

    toastStore.toasts.push({ color: "danger", message: i18n.t("failed_to_upload", { message }) })
    loading = false
  }
}
</script>

<style scoped lang="scss">
.file-upload {
  display: none;
}
.image-input {
  &__container {
    position: relative;
    display: flex;
    &:hover {
      .image-input__overlay {
        display: flex;
      }
    }
  }
  &__wrapper {
    height: 150px;
    border: 1px dashed;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    img {
      height: 150px;
    }
  }
  &__text {
    font-size: 1.25rem;
    font-weight: 500;
  }
  &__overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
  }
}
</style>
