<template>
  <CRow
    style="row-gap: 2rem"
  >
    <CCol
      v-for="(order, key) in summary"
      :key="key"
      sm="3"
      class="widget"
    >
      <CWidgetStatsD
        color="primary"
        :values="[
          { title: $t('total'), value: order.total },
          { title: $t('overall_price'), value: order.price },
        ]"
      >
        <template #icon>
          <CIcon :icon="order.icon" class="my-3 text-white" style="font-size: 1.5rem;" />
          <span class="ms-4 text-white" style="font-size: 1.5rem;">{{ $t(order.status) }}</span>
        </template>
      </CWidgetStatsD>
      <div
        @click="onClicked(order.status)"
        class="widget-overlay"
      >
        <span>View All</span>
        <CIcon icon="fa-long-arrow-right" class="text-white ms-3" />
      </div>
    </CCol>
  </CRow>
</template>

<script lang="ts">
import { toCurrency } from '@/libraries/helpers'
import { orderStore } from '@/store'
import { defineComponent } from 'vue'

const icons = {
  created: 'fa-plus',
  in_review: 'fa-search',
  confirmed: 'fa-clipboard-check',
  quoted: 'fa-quote-right',
  ordered: 'fa-shopping-cart',
  produced: 'fa-gear',
  completed: 'fa-check',
  cancelled: 'fa-close',
}

export default defineComponent({
  name: "OrderWidgets",
  computed: {
    summary() {
      const totals = []
      for (const key in orderStore.summary) {
        const value = orderStore.summary[key]
        totals.push({
          status: key,
          total: value.count,
          price: toCurrency(parseFloat(value.total_price)),
          icon: icons[key]
        })
      }
      return totals
    }
  },
  methods: {
    onClicked(status: string) {
      orderStore.all = []
      this.$router.push({ name: 'orders', query: { status } })
    }
  },
  setup() {},
})
</script>
