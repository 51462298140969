<template>
  <div class="animated fadeIn">
    <VTables
      :title="$t('clients')"
      :subtitle="$t('platform_oauth2_clients')"
      :add-text="$t('add_client')"
      :edit-text="$t('edit_client')"
      :columns="tableColumns"
      action-edit
      action-delete
      show-add-button
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="clientStore.mappedData"
      :add="clientStore.add"
      :update="clientStore.update"
      :remove="({ client_id }) => clientStore.remove(client_id)"
      :default-sort="{ column: 'date__created', ascending: true }"
    >
      <template #client_id="{ row }">
        <code>{{ row.client_id }}</code>
      </template>
      <template #client_secret="{ row }">
        <code>{{ row.client_secret }}</code>
      </template>
      <template #username="{ row }">
        <b>{{ row.user?.username }}</b>
        <div class="text-muted small text-nowrap" style="cursor: pointer">
          <i class="fa fa-copy" />
          {{ row.user?.id }}
        </div>
      </template>
      <template #scope="{ row }">
        <span>{{ row.scope }}</span>
      </template>
      <template #redirect_uris="{ row }">
        <span>{{ row.redirect_uris?.join(", ") }}</span>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { capitalize, onMounted } from "vue"
import type { Client } from "@/interfaces"
import { clientStore, userStore } from "@/store"
import VTables from "@/components/VTables.vue"
import { dateFilterAlgorithm, dateToFilterString } from "@/libraries/helpers"
import oauth2Scopes from "@/datas/oauth2-scopes"

const i18n = useI18n()

useMeta({ title: i18n.t("clients") })

const tableColumns = [
  "date__created",
  "date__updated",
  "username",
  // "client_type",
  "client_id",
  "client_secret",
  "scope",
  "redirect_uris",
  "actions",
]

const tableOptions: VTableOptions = {
  sortable: [
    "date__created",
    "date__updated",
    "username",
    // "client_type",
    "client_id",
    "client_secret",
    "scope",
    "redirect_uris",
  ],
  headings: {
    date__created: i18n.t("created"),
    date__updated: i18n.t("updated"),
    username: i18n.t("username"),
    // client_type: i18n.t("client_type"),
    client_id: i18n.t("client_id"),
    client_secret: i18n.t("client_secret"),
    scope: i18n.t("scope"),
    redirect_uris: i18n.t("redirect_uris"),
  },
  filterable: [
    "date__created",
    "date__updated",
    "username",
    // "client_type",
    "client_id",
    "client_secret",
    "scope",
    "redirect_uris",
  ],
  filterAlgorithm: {
    date__created: (row: Client, query: string) => dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Client, query: string) => dateFilterAlgorithm(row, query, "updated"),
    username(row: Client, query: string) {
      const str = `${row.user?.username || ""}###${row.user?.id || ""}`.toLowerCase()
      return str.includes(query.toLowerCase())
    },
  },
  customFilters: [
    {
      name: "all",
      callback(row: Client, query: string) {
        return [
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
          row.user || "",
          // row.client_type || "",
          row.client_id || "",
          row.client_secret || "",
          row.scope || "",
          row.redirect_uris || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const makeForm = data => {
  const scopeDefaultValue = (data?.scope || "").split(" ")
  const scope = oauth2Scopes.filter(s => scopeDefaultValue.includes(s.code))
  const response_types = data?.response_types || ["bearer", "code", "token"]
  return {
    id: "admin-clients-page-form",
    fields: {
      id: {
        type: "hidden",
        defaultValue: data?.id,
      },
      user: {
        type: "multiselect",
        label: i18n.t("user"),
        placeholder: i18n.t("select_or_start_typing"),
        defaultValue: data?.user?.id || data?.user,
        validations: ["required"],
        options: userStore.all?.map(o => ({
          ...o,
          text: o.username,
        })),
        mapResultTo: "id",
        selectOptions: {
          multiple: false,
          trackBy: "id",
          label: "text",
          hideSelected: true,
          searchable: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: true,
          openDirection: "bottom",
        },
        visible: !data?.id,
      },
      client_name: {
        type: "text",
        label: i18n.t("client_name"),
        validations: !data?.id ? ["required"] : [],
        defaultValue: data?.client_name,
      },
      client_uri: {
        type: "text",
        label: i18n.t("client_uri"),
        validations: !data?.id ? ["required"] : [],
        defaultValue: data?.client_uri,
      },
      token_endpoint_auth_method: {
        type: "select",
        label: i18n.t("auth_method"),
        defaultValue: data?.token_endpoint_auth_method || "none",
        validations: !data?.id ? ["required"] : [],
        options: [
          {
            label: i18n.t("none"),
            value: "none",
            disabled: false,
          },
          {
            label: i18n.t("http_basic"),
            value: "client_secret_basic",
            disabled: false,
          },
          {
            label: i18n.t("http_post"),
            value: "client_secret_post",
            disabled: false,
          },
        ],
        // visible: !data?.id,
      },
      response_types: {
        type: "multiselect",
        defaultValue: Array.isArray(response_types)
          ? response_types.map(tag => ({ text: capitalize(tag), value: tag }))
          : (response_types.split(/\r?\n/) || ["bearer", "code", "token"]),
        label: i18n.t("response_types"),
        placeholder: i18n.t("select_or_start_typing"),
        options: [
          {
            text: "Bearer",
            value: "bearer",
          },
          {
            text: "Code",
            value: "code",
          },
          {
            text: "Token",
            value: "token",
          },
        ],
        mapResultTo: "value",
        selectOptions: {
          multiple: true,
          trackBy: "value",
          label: "text",
          hideSelected: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: false,
          allowEmpty: false,
          taggable: false,
        }
      },
      redirect_uris: {
        type: "multiselect",
        label: i18n.t("redirect_uris"),
        placeholder: i18n.t("select_or_start_typing"),
        options: [],
        defaultValue: Array.isArray(data?.redirect_uris)
          ? data?.redirect_uris.map(tag => ({ text: tag, value: tag }))
          : (data?.redirect_uris?.split(/\r?\n/) || []),
        mapResultTo: "value",
        reloadOnChange: true,
        selectOptions: {
          multiple: true,
          trackBy: "value",
          label: "text",
          hideSelected: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: true,
          allowEmpty: false,
          taggable: true,
        },
      },
      scope: {
        type: "multiselect",
        defaultValue: scope,
        label: i18n.t("scope"),
        placeholder: i18n.t("select_or_start_typing"),
        options: oauth2Scopes,
        selectOptions: {
          multiple: true,
          trackBy: "code",
          label: "code",
          hideSelected: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: false,
          allowEmpty: false,
          taggable: true,
        }
      }
    },
    valueProcessor: values => {
      return {
        ...values,
        scope: (values.scope || []).map(s => s.code).join(" ")
      }
    }
  }
}

onMounted(() => {
  clientStore.fetchAll()
  if ((userStore.all || []).length === 0) userStore.fetchAll()
})
</script>
