<template>
  <li v-if="tree.index !== null && tree.index !== undefined" class="tree-item">
    <div
      :class="{
        bold: isFolder,
        underline: tree.index === selected,
      }"
    >
      <span
        class="part-name"
        @click="isFolder ? toggleFolder() : emit('clicked', tree.index)"
      >
        {{ tree.name }}
      </span>
      <i
        v-if="!isFolder"
        class="fa ms-2 icon"
        :class="[show ? 'fa-eye' : 'fa-eye-slash']"
        @click="toggleItem"
      />

      <i
        v-if="isFolder"
        class="fa ms-2 icon"
        :class="[collapsed ? 'fa-circle-plus' : 'fa-circle-minus']"
        @click="toggleFolder"
      />

      <i
        v-if="!isFolder"
        class="fa ms-2 icon"
        :class="[tree.index === selected ? 'fa-circle-check' : 'fa-circle-info']"
        @click="emit('clicked', tree.index)"
      />
    </div>
    <ul v-if="isFolder" v-show="!collapsed">
      <tree-item
        v-for="(component, index) in tree.components"
        :key="index"
        class="item"
        :tree="component"
        :selected="selected"
        @clicked="v => emit('clicked', v)"
        @toggled="v => emit('toggled', v)"
      />
    </ul>
  </li>
</template>

<script lang="ts" setup>
import type { ComponentTree } from "@/interfaces"

interface Props {
  tree: ComponentTree
  selected: number
}

const { tree, selected } = defineProps<Props>()

const emit = defineEmits(["clicked", "toggled"])

let collapsed: boolean = $ref(true)
let show: boolean = $ref(true)

const isFolder: boolean = $computed(() => tree.components?.length > 0)

function toggleItem() {
  show = !show
  emit("toggled", tree.index)
}

function toggleFolder() {
  if (isFolder) collapsed = !collapsed
}
</script>

<style scoped lang="scss">
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
ul,
ul ul {
  list-style-type: none;
  background: url(/img/tree/vline.png) repeat-y;
  margin: 0 1em;
  padding: 0;
}

ul ul {
  margin-left: 10px;
}

ul li {
  margin: 0;
  padding: 0 12px;
  line-height: 20px;
  background: url(/img/tree/node.png) no-repeat;
}

ul li:last-child {
  background: url(/img/tree/lastnode.png) no-repeat;
}

.svg-icon,
.icon {
  width: 1em;
  height: 1em;
  position: relative;
  cursor: pointer;
  pointer-events: visible;
}

.part-name {
  pointer-events: visible;
}
</style>
