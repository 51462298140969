<template>
  <div>
    <ModalWrapper v-model="isVisible">
      <CModal
        size="lg"
        :visible="isVisible"
        backdrop="static"
        @close="() => (isVisible = false)"
      >
        <CModalHeader>
          <div class="flex">
            <CModalTitle>{{ title }}</CModalTitle>
            <div class="small text-muted">{{ subtitle }}</div>
          </div>
        </CModalHeader>
        <CModalBody class="d-flex flex-column">
          <div class="mb-2">
            <CButton style="float: right" color="success" @click="changeFormVisibility()">
              <b>+</b>
              {{ $t("add_new_limit") }}
            </CButton>
          </div>
          <div v-if="limits.length > 0">
            <CCard v-for="(limit, i) in limits" :key="i" class="mb-2">
              <CCardHeader
                class="justify-content-between d-flex bg-light cursor-pointer"
                @click="visibleCollapseIndex = visibleCollapseIndex == i ? null : i"
              >
                <div>
                  <CBadge
                    v-for="(material, im) in limit.materials"
                    :key="im"
                    class="badgeRight"
                    color="success"
                  >
                    {{ material.name }}
                  </CBadge>
                </div>
                <i
                  class="fa floatRight"
                  :class="{
                    'fa-chevron-down': visibleCollapseIndex != i,
                    'fa-chevron-up': visibleCollapseIndex == i,
                  }"
                />
              </CCardHeader>
              <CCollapse :visible="i == visibleCollapseIndex">
                <CCardBody class="d-flex justify-content-between">
                  <div>
                    <template v-for="(key, ik) in checkKeys">
                      <CCard
                        v-if="limit[key] != null"
                        :key="ik"
                        class="d-inline-block inlineBlock p-0 mb-0"
                      >
                        <CCardHeader class="bg-light">
                          {{ $t(`${key}_mm`) }}
                        </CCardHeader>
                        <CCardBody>
                          <CCardText>
                            Min:
                            <span style="color: red">{{ limit[key].min }}</span>
                            <br />
                            Max:
                            <span style="color: green">{{ limit[key].max }}</span>
                          </CCardText>
                        </CCardBody>
                      </CCard>
                    </template>
                  </div>
                  <div class="d-flex align-items-center flex-column">
                    <CButton
                      class="w-100"
                      color="primary" 
                      @click="changeFormVisibility(true, limit)"
                    >
                      {{ $t("edit") }}
                    </CButton>
                    <CButton
                      class="w-100 mt-2"
                      color="danger" 
                      @click="remove(limit)"
                    >
                      {{ $t("remove") }}
                    </CButton>
                  </div>
                </CCardBody>
              </CCollapse>
            </CCard>
          </div>
          <CCard v-else>
            <CCardHeader class="justify-content-between d-flex bg-light" />
            <CCardBody>
              <p class="text-center">{{ $t("no_data") }}</p>
            </CCardBody>
          </CCard>
        </CModalBody>
        <CModalFooter class="justify-content-start">
          <CButton color="secondary" @click="() => (isVisible = false)">
            {{ $t("close") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>

    <ModalWrapper v-model="formVisible">
      <CModal
        size="lg"
        :visible="formVisible"
        backdrop="static"
        @close="changeFormVisibility(false)"
      >
        <CModalHeader>
          <CModalTitle>
            {{ edit.active ? $t("edit") : $t("add_new_limit") }}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <DynamicFormWrapper :form="form" @submit="onSubmit" @change="formChanged" />
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <div class="d-flex flex-grow-1">
            <CButton class="me-4" color="secondary" @click="onFormCancel">
              {{ $t("cancel") }}
            </CButton>
          </div>
          <CButton
            v-if="edit.active"
            class="me-4"
            color="danger"
            @click="remove(edit.data)"
          >
            {{ $t("remove") }}
          </CButton>
          <CButton color="success" type="submit" :form="form.id">
            {{ edit.active ? $t("save") : $t("add") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import { nextTick } from "vue"
import { useI18n } from "vue-i18n"
import {
  pick,
  omitBy,
  isNil,
  isEqual,
  cloneDeep,
  keys,
  isObject,
  toArray,
} from "lodash-es"
import sweetalert from "sweetalert2"
import type { Limit } from "@/interfaces"
import { materialStore } from "@/store"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

interface Props {
  modelValue: boolean
  title: string
  subtitle?: string
  data: Limit[]
}

const {
  modelValue = false,
  title = "",
  subtitle = "",
  data: limits = [],
} = defineProps<Props>()

const emit = defineEmits(["update:modelValue", "add", "update", "remove"])

let formValues: any = $ref({})
let validValues: any = $ref({})
let formVisible = $ref(false)
let visibleCollapseIndex = $ref(0)
const edit: any = $ref({
  active: false,
  data: null,
  values: {},
})

let isVisible: boolean = $computed({
  get() {
    return modelValue
  },
  set(v) {
    visibleCollapseIndex = 0
    emit("update:modelValue", v)
  },
})

const form = $computed(() => {
  const parameters =
    formValues.parameters?.map(parameter => {
      if (typeof parameter === "object") return parameter
      return { id: parameter, name: i18n.t(parameter) }
    }) || []

  return {
    id: "limitation-modal-form",
    fields: {
      id: {
        type: "hidden",
        defaultValue: formValues.id,
      },
      materials: {
        type: "multiselect",
        label: i18n.t("raw_materials"),
        placeholder: i18n.t("select_or_start_typing"),
        defaultValue: formValues.materials,
        options: materialStore.all,
        selectOptions: {
          multiple: true,
          trackBy: "id",
          label: "name",
          hideSelected: true,
          searchable: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: false,
        },
      },
      parameters: {
        type: "multiselect",
        label: "Parameters",
        placeholder: i18n.t("select_or_start_typing"),
        defaultValue: parameters,
        options: [
          { id: "width", name: i18n.t("width") },
          { id: "height", name: i18n.t("height") },
          { id: "thickness", name: i18n.t("thickness") },
          { id: "diameter", name: i18n.t("diameter") },
          { id: "length", name: i18n.t("length") },
        ],
        mapResultTo: "id",
        selectOptions: {
          multiple: true,
          trackBy: "id",
          label: "name",
          hideSelected: true,
          searchable: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: false,
        },
      },
      width: {
        type: "range",
        label: i18n.t("width_mm"),
        defaultValue: formValues.width,
        visible: !!parameters.map(p => p.id).includes("width"),
      },
      height: {
        type: "range",
        label: i18n.t("height_mm"),
        defaultValue: formValues.height,
        visible: !!parameters.map(p => p.id).includes("height"),
      },
      thickness: {
        type: "range",
        label: i18n.t("thickness_mm"),
        defaultValue: formValues.thickness,
        visible: !!parameters.map(p => p.id).includes("thickness"),
      },
      diameter: {
        type: "range",
        label: i18n.t("diameter_mm"),
        defaultValue: formValues.diameter,
        visible: !!parameters.map(p => p.id).includes("diameter"),
      },
      length: {
        type: "range",
        label: i18n.t("length_mm"),
        defaultValue: formValues.length,
        visible: !!parameters.map(p => p.id).includes("length"),
      },
    },
  }
})
const checkKeys = $computed(() => ["width", "height", "diameter", "thickness", "length"])

function formChanged(values: any) {
  validValues = omitBy(
    {
      ...pick(values, ["id", "materials", "parameters"].concat(values.parameters)),
    },
    isNil
  )

  if (!validValues.id) delete validValues.id
  delete validValues.isTrusted

  if (!isEqual(formValues.parameters, values.parameters)) formValues = values

  return validValues
}
function onSubmit(v) {
  changeFormVisibility(false)
  let values = {
    ...checkKeys.reduce((value: any, key: string) => ({ ...value, [key]: null }), {}),
    ...formChanged(v)
  }
  delete values.parameters

  if (!values.id) values = omitBy(values, isNil)

  emit(!values.id ? "add" : "update", values)
}
function onFormCancel() {
  if (!edit.active || isEqual(validValues, edit.values)) {
    changeFormVisibility(false)
    return
  }
  sweetalert
    .fire({
      title: i18n.t("unsaved_changes"),
      text: i18n.t("unsaved_changes_body"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: i18n.t("yes_please"),
      cancelButtonText: i18n.t("no_thanks"),
    })
    .then(result => {
      if (result.isConfirmed) {
        const values = formChanged(validValues)
        delete values.parameters
        emit("update", values)
      }
    })
    .then(() => {
      changeFormVisibility(false)
    })
    .catch()
}
function changeFormVisibility(show = true, data = null) {
  formVisible = show
  isVisible = !show
  if (!show) return

  edit.active = !!data
  edit.data = data
  formValues = edit.values = {}
  if (edit.active) {
    edit.values = cloneDeep(omitBy(pick(data, keys(form.fields)), isNil))
    if (isObject(edit.values.materials))
      edit.values.materials = toArray(edit.values.materials).sort()
    edit.values.parameters = keys(edit.values)
      .filter(k => checkKeys.includes(k))
      .sort()
    formValues = cloneDeep(edit.values)
  }
}
function remove(limit) {
  if (!limit) return

  sweetalert
    .fire({
      title: i18n.t("remove"),
      text: i18n.t("limit_remove_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: i18n.t("yes_sure"),
      cancelButtonText: i18n.t("cancel"),
    })
    .then(result => {
      if (result.isConfirmed) {
        visibleCollapseIndex = null
        if (edit.active) changeFormVisibility(false)
        nextTick(() => emit("remove", limit))
      }
    })
    .catch()
}
</script>

<style lang="scss" scoped>
legend {
  display: block;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 1.2em;
}
.cardTop {
  margin-top: 35px;
}
.badgeRight {
  margin-right: 5px;
}
.inlineBlock {
  margin: 0px 10px 10px 0;
  padding: 10px;
  width: 110px;
}
// .no-result {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0.75rem;
//   border: 1px solid rgba(0,0,0,0.2);
//   border-radius: 4px;
//   p {
//     margin-bottom: 0px;
//   }
// }
</style>
