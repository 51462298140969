<template>
  <v-client-table
    ref="tableElement"
    :columns="tableOptions.columns"
    :data="batches"
    :options="tableOptions.options"
    @select="selectedBatchIds = $event.map(b => b.id)"
    @pagination="calculateTotalVisibleChildren"
    @filter="$nextTick(calculateTotalVisibleChildren)"
    @row-click="v => $emit('row-click', v)"
  >
    <template #index="{ row }">
      <div
        class="d-flex h-100 align-items-center review-batch-index"
      >
        <TableTreeExpander
          v-show="treeView"
          class="me-3"
          :is-expanded="!hideChildOfBatchIds.includes(row.id)"
          :isChild="!!row.parent"
          :deep="row.treeDeep"
          :total-children="row.childrenIds.length"
          :total-visible-children="totalVisibleChildren[row.id]"
          @click.stop
          @expand="hideChildOfBatchIds = hideChildOfBatchIds.filter(id => id !== row.id)"
          @collapse="hideBatchId(row.id)"
        />
        <span>{{ getBatchIndex(row.id) }}</span>
      </div>
    </template>

    <template #preview="{ row }">
      <div style="width: 50px; height: 50px">
        <PartImage :batch="row" @click.stop="" />
      </div>
    </template>

    <template #name="{ row }">
      <b class="white-space__nowrap">{{ row.part?.name }}</b>
      <div
        v-if="row.part?.type == 'SHEET'"
        class="small text-muted white-space__nowrap"
      >
        <span>
          {{ row.part?.width.toFixed(2) }}&times;
          {{ row.part?.height.toFixed(2) }}
        </span>
        <span v-if="row.part.length">
          &times; {{ row.part?.length.toFixed(2) }}
        </span>
      </div>
    </template>

    <template #stock="{ row }">
      <div v-if="row.stock">
        <b>{{ row.stock?.name }}</b>
        <div class="small text-muted">
          <span v-if="row.part?.type == 'SHEET'">
            {{ row.stock?.thickness.toFixed(2) }} mm
          </span>
          <span v-if="row.part?.type == 'TUBE'">{{ row.stock?.hash }}</span>
        </div>
      </div>
    </template>

    <template #operations="{ row }">
      <span v-for="(time, i) in row.times" :key="i">
        <CBadge color="primary">{{ time.machine?.name }}</CBadge>
      </span>
    </template>

    <template #reference="{ row }">
      {{ row.part?.reference }}
    </template>

    <template #boolean__is_valid="{ row }">
      <i
        :style="`color: ${row.is_valid === true ? 'green' : 'red'}`"
        :class="['fa', row.is_valid === true ? 'fa-check' : 'fa-times']"
      />
    </template>

    <template #price="{ row }">
      <span>
        {{
          toCurrency(
            !isNaN(row.unit_amount) && row.unit_amount != null
              ? row.unit_amount
              : 0
          )
        }}
      </span>
    </template>

    <template #total="{ row }">
      <span>
        {{
          toCurrency(
            !isNaN(row.total_amount) && row.total_amount != null
              ? row.total_amount
              : 0
          )
        }}
      </span>
    </template>

    <template #actions="{ row }">
      <div class="button-groups">
        <CButton
          v-if="row.part?.type === 'SHEET'"
          class="me-2"
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'DXF')"
        >
          <b>DXF</b>
        </CButton>

        <CButton
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'STP')"
        >
          <b>STP</b>
        </CButton>

        <CButton
          class="ms-2"
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'CYCAD')"
        >
          <b>CyCAD</b>
        </CButton>

        <!-- PDF only available for SHEET part -->
        <CButton
          v-if="row.part?.type === 'SHEET'"
          class="ms-2"
          size="sm"
          color="primary"
          @click.stop="batchStore.exports(row, 'PDF', 'preview-pdf')"
        >
          <b>PDF</b>
        </CButton>

        <CButton
          class="ms-2"
          size="sm"
          color="warning"
          @click.stop="fileStore.download(getId(row.part?.source))"
        >
          <b>SOURCE</b>
        </CButton>
      </div>
    </template>
  </v-client-table>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PartImage from "@/components/part-image/PartImage.vue"
import { batchStore, authStore, fileStore } from "@/store"
import { generateFilterAlgorithm, getFilterEmitsOptions, getListColumns, sleep, toCurrency } from "@/libraries/helpers"
import TableTreeExpander from '@/components/TableTreeExpander.vue'
import { treeHelpers, getBatchesSortStrings } from "../helpers"
import type { MappedInjectedBatchInterface } from '@/interfaces'
import { getId } from '@/interfaces'

export default defineComponent({
  name: "ReviewBatchTable",
  components: {
    PartImage,
    TableTreeExpander
  },
  props: {
    orderId: {
      type: Number
    },
    loading: {
      type: Boolean,
      default: false
    },
    treeView: {
      type: Boolean,
      default: false
    }
  },
  emits: ["row-click"],
  data: () => ({
    selectedBatchIds: [],
    hideChildOfBatchIds: [],
    totalVisibleChildren: {}
  }),
  computed: {
    batches() {
      return batchStore.getBatchesByOrderId(this.orderId)
    },
    tableOptions() {
      const batches = this.batches

      const batchesSortStrings = getBatchesSortStrings(batches)

      const columns = [
        "index",
        "preview",
        "name",
        "stock",
        "operations",
        "boolean__is_valid",
        "reference",
        "quantity",
        "price",
        "total",
      ]
      if (authStore.authenticatedUser?.is_admin || authStore.authenticatedUser?.is_manufacturer) columns.push("actions")
      return {
        columns,
        options: {
          perPage: 100,
          texts: {
            count: "",
            filter: "",
            filterPlaceholder: "",
            limit: "",
            page: "",
            noResults: this.$t("no_results"),
            loading: this.$t("loading"),
          },
          skin: "table table-hover",
          rowAttributesCallback: row => ({ id: row.id }),
          headings: {
            index: "#",
            boolean__is_valid: this.$t("valid"),
            name: this.$t("name"),
            material: this.$t("material"),
            operations: this.$t("operations"),
            stock: this.$t("stock"),
            quantity: this.$t("quantity"),
            price: this.$t("price"),
            total: this.$t("total"),
            actions: "Downloads",
          },
          sortable: [
            "index",
            "boolean__is_valid",
            "name",
            "stock",
            "quantity",
            "reference",
            "operations",
            "price",
            "total",
          ],
          sortIcon: {
            base: "fa fa-lg",
            up: "fa-sort-asc",
            down: "fa-sort-desc",
            is: "fa-sort",
          },
          orderBy: {
            column: "index",
            ascending: false,
          },
          customSorting: {
            index: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText: string = (this.getBatchIndex(a.id) || "").toString().padStart(5, "0")
                let bText: string = (this.getBatchIndex(b.id) || "").toString().padStart(5, "0")
                if (this.treeView) {
                  aText = `${batchesSortStrings[a.id]?.index || ""}${!ascending ? "~" : ""}`
                  bText = `${batchesSortStrings[b.id]?.index || ""}${!ascending ? "~" : ""}`
                }
                if (ascending) return aText >= bText ? 1 : -1

                return aText <= bText ? 1 : -1
              }
            },
            boolean__is_valid: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText: string | number = +a.is_valid
                let bText: string | number = +b.is_valid
                if (this.treeView) {
                  aText = batchesSortStrings[a.id]?.[`is_valid_${ascending ? "ascending" : "descending"}`] || ""
                  bText = batchesSortStrings[b.id]?.[`is_valid_${ascending ? "ascending" : "descending"}`] || ""
                }
                if (ascending) return aText >= bText ? 1 : -1

                return bText >= aText ? 1 : -1
              }
            },
            name: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = a.part.name
                let bText = b.part.name
                if (this.treeView) {
                  aText = `${batchesSortStrings[a.id]?.name || ""}${!ascending ? "~" : ""}`
                  bText = `${batchesSortStrings[b.id]?.name || ""}${!ascending ? "~" : ""}`
                }

                if (ascending) return aText >= bText ? 1 : -1

                return aText <= bText ? 1 : -1
              }
            },
            stock: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = a.stock?.name || ""
                let bText = b.stock?.name || ""
                if (this.treeView) {
                  aText = batchesSortStrings[a.id]?.[`stock_${ascending ? "ascending" : "descending"}`] || ""
                  bText = batchesSortStrings[b.id]?.[`stock_${ascending ? "ascending" : "descending"}`] || ""
                }

                if (ascending) return aText >= bText ? 1 : -1

                return bText >= aText ? 1 : -1
              }
            },
            quantity: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = (a.quantity || "").toString().padStart(5, "0")
                let bText = (b.quantity || "").toString().padStart(5, "0")
                if (this.treeView) {
                  aText = `${batchesSortStrings[a.id]?.quantity || ""}${!ascending ? "~" : ""}`
                  bText = `${batchesSortStrings[b.id]?.quantity || ""}${!ascending ? "~" : ""}`
                }
                if (ascending) return aText >= bText ? 1 : -1

                return aText <= bText ? 1 : -1
              }
            },
            reference: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = a.part?.reference || ""
                let bText = b.part?.reference || ""
                if (this.treeView) {
                  aText = batchesSortStrings[a.id]?.[`reference_${ascending ? "ascending" : "descending"}`] || ""
                  bText = batchesSortStrings[b.id]?.[`reference_${ascending ? "ascending" : "descending"}`] || ""
                }
                if (ascending) return aText >= bText ? 1 : -1

                return bText >= aText ? 1 : -1
              }
            },
            operations: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = (a.times?.length || "").toString().padStart(5, "0")
                let bText = (b.times?.length || "").toString().padStart(5, "0")
                if (this.treeView) {
                  aText = `${batchesSortStrings[a.id]?.times || ""}${!ascending ? "~" : ""}`
                  bText = `${batchesSortStrings[b.id]?.times || ""}${!ascending ? "~" : ""}`
                }
                if (ascending) return aText >= bText ? 1 : -1

                return aText <= bText ? 1 : -1
              }
            },
            price: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = (a.unit_amount || "").toString().padStart(10, "0")
                let bText = (b.unit_amount || "").toString().padStart(10, "0")
                if (this.treeView) {
                  aText = `${batchesSortStrings[a.id]?.unit_amount || ""}${!ascending ? "~" : ""}`
                  bText = `${batchesSortStrings[b.id]?.unit_amount || ""}${!ascending ? "~" : ""}`
                }
                if (ascending) return aText >= bText ? 1 : -1

                return aText <= bText ? 1 : -1
              }
            },
            total: (ascending: boolean) => {
              return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
                let aText = (a.total_amount || "").toString().padStart(10, "0")
                let bText = (b.total_amount || "").toString().padStart(10, "0")
                if (this.treeView) {
                  aText = `${batchesSortStrings[a.id]?.total_amount || ""}${!ascending ? "~" : ""}`
                  bText = `${batchesSortStrings[b.id]?.total_amount || ""}${!ascending ? "~" : ""}`
                }
                if (ascending) return aText >= bText ? 1 : -1

                return aText <= bText ? 1 : -1
              }
            },
          },
          filterByColumn: true,
          filterable: [
            "index",
            "name",
            "stock",
            "quantity",
            "reference",
            "operations",
            "price",
            "total",
            "boolean__is_valid"
          ],
          get filterAlgorithm() {
            return {
              ...generateFilterAlgorithm(this.filterable, "boolean"),
              index: (row: MappedInjectedBatchInterface, query: string) => {
                let index = batches.findIndex(b => b.id === row.id)
                if (index >= 0) index++
                let str = (index || "").toString().toLowerCase()
                if (this.treeView && row.allChildrenIds.length > 0) {
                  const childrenIndexes = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => this.getBatchIndex(batch.id))
                  str += `###${childrenIndexes.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              name: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.part?.name || "").toLowerCase()
                if (this.treeView && row.allChildrenIds.length > 0) {
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => (batch.part?.name || "").toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              stock: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.stock?.name || "").toLowerCase()
                if (this.treeView && row.allChildrenIds.length > 0) {
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => (batch.stock?.name || "").toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              quantity: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.quantity || 0).toString().toLowerCase()
                if (this.treeView && row.allChildrenIds.length > 0) {
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => (batch.quantity || 0).toString().toLowerCase())
                  str += `###${children.join("###")}`
                }
                return str.includes(query.toLowerCase())
              },
              operations: (row: MappedInjectedBatchInterface, query: string) => {
                let str = row.times?.map(o => o.machine?.name || "").join("###") || ""
                if (this.treeView && row.allChildrenIds.length > 0) {
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => batch.times?.map(o => o.machine?.name || "").join("###") || "")
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              reference: (row: MappedInjectedBatchInterface, query: string) => {
                let str = (row.part?.reference || "").toLowerCase()
                if (this.treeView && row.allChildrenIds.length > 0) {
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => batch.part?.reference || "")
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              price: (row: MappedInjectedBatchInterface, query: string) => {
                // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                let str = toCurrency(isNaN(row.unit_amount) || row.unit_amount === 0 ? 0 : row.unit_amount)
                if (this.treeView && row.allChildrenIds.length > 0) {
                  // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => toCurrency(isNaN(batch.unit_amount) || batch.unit_amount === 0 ? 0 : batch.unit_amount))
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
              total: (row: MappedInjectedBatchInterface, query: string) => {
                // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                let str = toCurrency(isNaN(row.total_amount) || row.total_amount === 0 ? 0 : row.total_amount)
                if (this.treeView && row.allChildrenIds.length > 0) {
                  // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
                  const children = this.batches.filter(batch => row.allChildrenIds.includes(batch.id)).map(batch => toCurrency(isNaN(batch.total_amount) || batch.total_amount === 0 ? 0 : batch.total_amount))
                  str += `###${children.join("###")}`
                }
                return str.toLowerCase().includes(query.toLowerCase())
              },
            }
          },
          get listColumns() {
            return getListColumns(this.filterable)
          },
          clientMultiSorting: false
        },
      }
    }
  },
  watch: {
    batches: {
      async handler() {
        treeHelpers.handleBatchTree(this.batches, this.hideChildOfBatchIds)
        await sleep(100)
        this.calculateTotalVisibleChildren()
      },
      deep: true
    },
    hideChildOfBatchIds: {
      async handler() {
        treeHelpers.handleBatchTree(this.batches, this.hideChildOfBatchIds)
        await sleep(100)
        this.calculateTotalVisibleChildren()
      },
      deep: true
    },
    treeView() {
      this.hideChildOfBatchIds = []
    }
  },
  methods: {
    hideBatchId(id: number) {
      this.hideChildOfBatchIds = treeHelpers.hideBatchId(id, this.batches, this.hideChildOfBatchIds)
    },
    calculateTotalVisibleChildren() {
      this.totalVisibleChildren = treeHelpers.calculateTotalVisibleChildren(this.batches, this.totalVisibleChildren)
    },
    getBatchIndex(id: number) {
      return this.batches.findIndex(x => x.id == id) + 1
    },
  },
  mounted() {
    if (this.$refs.tableElement) {
      (this.$refs.tableElement as any).$.emitsOptions = {
        ...(this.$refs.tableElement as any).$.emitsOptions,
        ...getFilterEmitsOptions(this.tableOptions.options.filterable),
      }
    }
  },
  setup() {
    return {
      batchStore,
      fileStore,
      getId,
      toCurrency
    }
  }
})
</script>

<style lang="scss">
.review-batch-index {
  .table-tree-expander {
    &__expanded::before {
      top: 1rem;
      height: calc(100% + 3rem + (var(--total-children) - 1) * 4.645rem);
    }
    &__children {
      &::after {
        width: 2rem;
      }
    }
  }
}
</style>
