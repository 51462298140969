<template>
  <div class="animated fadeIn">
    <VTables
      :title="$t('manufacturers')"
      :subtitle="$t('manufacturing_organizations')"
      :add-text="$t('add_manufacturer')"
      :edit-text="$t('edit_manufacturer')"
      :columns="tableColumns"
      :action-delete="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="manufacturerStore.mappedData"
      :add="manufacturerStore.add"
      :update="manufacturerStore.update"
      :remove="({ id }) => manufacturerStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
    >
      <template #reference="{ row }">
        <span v-if="row.reference">{{ row.reference }}</span>
        <div v-else class="small text-muted">{{ $t("no_reference") }}</div>
      </template>

      <template #addresses="{ row }">
        <Popover
          :text="$t('view_addresses')"
        >
          <CButton
            v-if="authStore.authenticatedUser?.is_admin"
            class="btn-space btn-sm"
            color="secondary"
            @click.stop.prevent="showAddressModal(row)"
          >
            <i class="fa fa-map-marker" />
          </CButton>
        </Popover>
      </template>
    </VTables>
    <AddressListModal
      :show="showModals.address"
      :data="selectedManufacturer?.addresses"
      :add="
        v => organizationStore.addAndActivateAddress(selectedManufacturerId, v).then(
          () => {
            manufacturerStore.fetchOne(selectedManufacturerId)
            addressStore.fetchByOrganizationId(selectedManufacturerId)
          }
        )
      "
      :update="
        v => addressStore.update(v, {
          withoutOptimistic: true,
          onSuccess: () => addressStore.fetchByOrganizationId(selectedManufacturerId)
        })
      "
      @close="showModals.address = false"
      @open="showModals.address = true"
    />
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted } from "vue"
import type { Manufacturer } from "@/interfaces"
import { authStore, manufacturerStore, addressStore, organizationStore, globalStore } from "@/store"
import VTables from "@/components/VTables.vue"
import AddressListModal from "@/components/modals/address/AddressListModal.vue"
import {
  booleanCustomSorting,
  dateToFilterString,
  generateFilterAlgorithm,
  getListColumns
} from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("manufacturers") })

const tableColumns = [
  "name",
  "nickname",
  "domain",
  "email",
  "phone",
  "company_number",
  "tax_number",
  "boolean__is_active",
  "boolean__is_verified",
  "boolean__is_company",
  "boolean__is_customer",
  "boolean__is_internal",
  "date__created",
  "date__updated",
  "addresses",
  "actions",
]

const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    date__created: i18n.t("created"),
    date__updated: i18n.t("updated"),
    actions: i18n.t("actions"),
    nickname: i18n.t("nickname"),
    domain: i18n.t("domain"),
    email: i18n.t("email"),
    phone: i18n.t("phone"),
    company_number: i18n.t("company_number"),
    tax_number: i18n.t("tax_number"),
    boolean__is_active: i18n.t("is_active"),
    boolean__is_verified: i18n.t("is_verified"),
    boolean__is_company: i18n.t("is_company"),
    boolean__is_customer: i18n.t("is_customer"),
    boolean__is_internal: i18n.t("is_internal"),
  },
  sortable: [
    "name",
    "nickname",
    "domain",
    "email",
    "phone",
    "company_number",
    "tax_number",
    "boolean__is_active",
    "boolean__is_verified",
    "boolean__is_company",
    "boolean__is_customer",
    "boolean__is_internal",
    "date__created",
    "date__updated",
  ],
  filterable: tableColumns.filter(column => !["addresses", "actions"].includes(column)),
  get listColumns() {
    return getListColumns(this.filterable)
  },
  get filterAlgorithm() {
    return {
      ...generateFilterAlgorithm(this.filterable, "boolean"),
      ...generateFilterAlgorithm(this.filterable, "date"),
    }
  },
  customSorting: {
    boolean__is_active: (ascending: boolean) => (a: Manufacturer, b: Manufacturer) =>
      booleanCustomSorting(ascending, a, b, "is_active"),
    boolean__is_verified: (ascending: boolean) => (a: Manufacturer, b: Manufacturer) =>
      booleanCustomSorting(ascending, a, b, "is_verified"),
    boolean__is_customer: (ascending: boolean) => (a: Manufacturer, b: Manufacturer) =>
      booleanCustomSorting(ascending, a, b, "is_customer"),
    boolean__is_company: (ascending: boolean) => (a: Manufacturer, b: Manufacturer) =>
      booleanCustomSorting(ascending, a, b, "is_company"),
    boolean__is_internal: (ascending: boolean) => (a: Manufacturer, b: Manufacturer) =>
      booleanCustomSorting(ascending, a, b, "is_internal"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: Manufacturer, query: string) {
        return [
          row.name || "",
          row.nickname || "",
          row.domain || "",
          row.email || "",
          row.phone || "",
          row.company_number || "",
          row.tax_number || "",
          row.is_active,
          row.is_verified,
          row.is_company,
          row.is_customer,
          row.is_internal,
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
          row.addresses || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}
const showModals = $ref({
  address: false,
})
let selectedManufacturerId = $ref(null)

const makeForm = (data: any) => ({
  id: "admin-manufacturers-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      validations: ["required"],
      defaultValue: data?.name,
    },
    nickname: {
      type: "text",
      label: i18n.t("nickname"),
      validations: ["required"],
      defaultValue: data?.nickname,
    },
    domain: {
      type: "text",
      label: i18n.t("domain"),
      validations: ["required"],
      defaultValue: data?.domain,
    },
    email: {
      type: "email",
      label: i18n.t("email"),
      validations: ["required"],
      defaultValue: data?.email,
    },
    phone: {
      type: "text",
      label: i18n.t("phone"),
      validations: ["required"],
      defaultValue: data?.phone,
    },
    company_number: {
      type: "text",
      label: i18n.t("company_number"),
      defaultValue: data?.company_number,
    },
    tax_number: {
      type: "text",
      label: i18n.t("tax_number"),
      defaultValue: data?.tax_number,
    },
    is_active: {
      type: "checkbox",
      label: i18n.t("is_active"),
      defaultValue: data?.is_active,
    },
    is_verified: {
      type: "checkbox",
      label: i18n.t("is_verified"),
      defaultValue: data?.is_verified,
    },
    is_company: {
      type: "checkbox",
      label: i18n.t("is_company"),
      defaultValue: data?.is_company,
    },
    is_customer: {
      type: "checkbox",
      label: i18n.t("is_customer"),
      defaultValue: data?.is_customer,
    },
    is_internal: {
      type: "checkbox",
      label: i18n.t("is_internal"),
      defaultValue: data?.is_internal,
    },
  },
})

const selectedManufacturer: Manufacturer = $computed(() =>
  manufacturerStore.mappedData?.find(o => o.id == selectedManufacturerId)
)

function showAddressModal(row: Manufacturer) {
  showModals.address = true
  selectedManufacturerId = row.id
}

onMounted(() => {
  addressStore.fetchAll()
  manufacturerStore.fetchAll()
})
</script>
