<template>
  <div class="animated fadeIn wrapped-with-hkebrymyyb">
    <VTables
      ref="table"
      :title="$t('plans')"
      :subtitle="$t('nestings_queue')"
      :columns="tableColumns"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :data="planStore.all"
    >
      <template #id="{ row }">
        <div class="small text-muted">{{ row.id }}</div>
      </template>

      <template #parts="data">
        {{ data.row.placements.length }}
      </template>

      <template #stock="{ row }">
        {{ row.stock.name }}
        <div class="small text-muted">{{ row.stock }}</div>
      </template>

      <template #actions="{ row }">
        <div class="col-lg-12 button-box text-nowrap d-flex">
          <Popover
            :text="$t('download_nesting_file', { ext: '.dxf' })"
          >
            <CButton
              class="btn-space btn-sm"
              color="primary"
              @click.stop="exportPlan(row.id, 'dxf')"
            >
              <strong>DXF</strong>
            </CButton>
          </Popover>
          <Popover
            :text="$t('download_nesting_file', { ext: '.pdf' })"
          >
            <CButton
              class="btn-space btn-sm"
              color="info"
              @click.stop="exportPlan(row.id, 'pdf')"
            >
              <strong>PDF</strong>
            </CButton>
          </Popover>
          <Popover
            :text="$t('download_nesting_zipped', { ext: '.pdf' })"
          >
            <CButton
              class="btn-space btn-sm"
              color="secondary"
              @click.stop="exportStickers(row.id, row.placements)"
            >
              <strong>STICKERS</strong>
            </CButton>
          </Popover>
        </div>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { ref, onMounted } from "vue"
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import type { Plan } from "@/interfaces"
import { planStore } from "@/store"
import VTables from "@/components/VTables.vue"
import { dateFilterAlgorithm, dateToFilterString, convertUnit } from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("plans") })

const from_date = ref(null)
const to_date = ref(null)
const tableColumns = $ref([
  "date__created",
  "date__updated",
  "stock",
  "length",
  "parts",
  "quantity",
  "actions",
])

const tableOptions: VTableOptions = {
  headings: {
    date__created: i18n.t("added"),
    date__updated: i18n.t("updated"),
    stock: i18n.t("stock"),
    length: i18n.t("length"),
    parts: i18n.t("parts"),
    quantity: i18n.t("quantity"),
    actions: i18n.t("actions"),
  },
  sortable: ["date__created", "date__updated", "stock", "length", "parts", "quantity"],
  filterable: ["date__created", "date__updated", "stock", "length", "parts", "quantity"],
  filterAlgorithm: {
    date__created: (row: Plan, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Plan, query: string) =>
      dateFilterAlgorithm(row, query, "updated"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: Plan, query: string) {
        return [
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
          row.stock?.name || "",
          convertUnit({ value: row.length, conversionRate: 1 }).toFixed(2),
          (row.placements || []).length,
          row.quantity || "",
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

function exportPlan(id, type) {
  console.log("export plan", id, type)
  // var fileName = id + "." + type
  // this.$store.dispatch('API_GET', '/v1/plans/' + id + '/exports?type=' + type)
  //   .then(resp => this.forceFileDownload(resp, fileName))
}

function forceFileDownload(response, fileName) {
  console.log("forcefiledownload", response, fileName)
  // const url = window.URL.createObjectURL(new Blob([response.data]))
  // const link = document.createElement('a')
  // link.href = url
  // link.setAttribute('download', fileName) //or any other extension
  // document.body.appendChild(link)
  // link.click()
}

// Donwload Plan DXF
function exportStickers(id, placements) {
  console.log("exportstickers", id, placements)
  // var zip = new JSZip()
  // var sticker_files = zip.folder("id")

  // var fileUrls = [];
  // var fileNames = [];
  // for (var i = 0; i < placements.length; i++) {
  //     fileUrls.push('/v1/batches/' + placements[i].batch + '/exports?type=STICKER')
  //     fileNames.push(id + "_" + i + '.pdf')
  // }

  // this.$store.dispatch('API_GET_ALL', fileUrls)
  // .then(resp => {
  //   for (var i = 0; i < resp.length; i++) {
  //     sticker_files.file(fileNames[i], resp[i].data)
  //   }

  //   return sticker_files
  // })
  // .then(archive => archive.generateAsync({
  //   type: "blob"
  // }))
  // .then(blob => {
  //   const url = window.URL.createObjectURL(new Blob([blob]))
  //   const link = document.createElement('a')
  //   link.href = url
  //   link.setAttribute('download', id + ".zip") //or any other extension
  //   document.body.appendChild(link)
  //   link.click()
  // })
  // .catch(error => {
  //   this.$notify({
  //     type: "error",
  //     title: i18n.t('failed'),
  //     text: i18n.t('failed_alert')
  //   })
  // })
}

onMounted(() => {
  console.log("mounted")

  // this.$root.$on('openAddModal' + this.title, () => {
  //   this.$router.push("/nesting/new");
  // });
  planStore.fetchAll()
})
</script>

<style lang="scss">
.wrapped-with-hkebrymyyb {
  .dateInput {
    width: 300px !important;
    max-width: 300px !important;
  }
}
</style>
