<template>
  <div v-if="batch.part" class="wrapped-with-gnaarmyqvy">
    <span
      v-if="image3D"
      :id="`element-${batch.id}`"
      class="part-tooltip"
      :class="{
        openable
      }"
      @click="showModal = openable"
      @mouseenter="handleMouseEvent('enter', batch)"
      @mouseleave="handleMouseEvent('leave', batch)"
      v-html="image3D"
    />
    <CSpinner
      v-else
      :id="`element-${batch.id}`"
      :class="{
        openable
      }"
      @mouseenter="handleMouseEvent('enter', batch)"
      @mouseleave="handleMouseEvent('leave', batch)"
      @click="showModal = openable"
    />
    <PartImageModal
      v-if="showModal"
      :batch="batch"
      @close="showModal = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch } from "vue"
import { debounce } from "lodash-es"
import type { Batch } from "@/interfaces"
import { partStore, globalStore } from "@/store"
import PartImageModal from "@/components/part-image/PartImageModal.vue"
import { sleep } from "@/libraries/helpers"

interface Props {
  batch: Batch
  clickable?: boolean
}

const { batch, clickable = true } = defineProps<Props>()

const openable: boolean = $computed(
  () => clickable && batch.part?.source?.extension !== "dxf"
)

let isFetchingImages = $ref(false)
let isImageFetched = $ref(false)
const showModal = $ref(false)

const image3D = $computed(() => partStore.all3DImages[batch?.part?.id])

watch(
  () => batch.part,
  () => {
    fetchImages()
  }
)

const handleMouseEvent = debounce(async (type: string, batch: Batch) => {
  const show = type === "enter"
  globalStore.setComponent("popover", { show, batch })

  const element = document.getElementById(`element-${batch.id}`)
  const popoverElement = document.getElementById("global-popover")

  await sleep(10) // make the ui update first

  const elementBoundingRect = element.getBoundingClientRect()
  const popoverElementBoundingRect = popoverElement.getBoundingClientRect()
  const top = (elementBoundingRect.top - (popoverElementBoundingRect.height / 2)) * 1.25
  const left = (elementBoundingRect.left + elementBoundingRect.width + 20) * 1.25
  popoverElement.style.left = `${left > 0 ? left : 0}px`
  popoverElement.style.top = `${top > 0 ? top : 0}px`
}, 100)

async function fetchImages() {
  if (batch.part && !isFetchingImages && !isImageFetched) {
    isFetchingImages = true
    isImageFetched = false
    await partStore.get3DImagesByIds([batch.part.id])
    if (batch.part.type === "SHEET") await partStore.get2DImagesByIds([batch.part.id])
    isFetchingImages = false 
    isImageFetched = true
  }

}

onMounted(() => {
  fetchImages()
})
</script>

<style lang="scss">
.wrapped-with-gnaarmyqvy {
  .part-tooltip {
    height: 100%;
    width: 100%;
    display: flex;
  }
  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  .part-tooltip svg {
    max-width: 50px;
    max-height: 50px;
  }
  .openable {
    cursor: pointer;
  }
}
</style>
