<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('tokens')"
      :subtitle="$t('platform_oauth2_tokens')"
      :columns="tableColumns"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :data="tokenStore.all"
      :row-click="() => {}"
    />
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted } from "vue"
import type { Token } from "@/interfaces"
import { tokenStore } from "@/store"
import VTables from "@/components/VTables.vue"
import { dateFilterAlgorithm, dateToFilterString } from "@/libraries/helpers"

const i18n = useI18n()

useMeta({ title: i18n.t("tokens") })


const tableColumns = [
  "date__created",
  "date__updated",
  "date__expires",
  "token_type",
  "client_id",
  "user",
  "access_token",
  "refresh_token",
]
const tableOptions: VTableOptions = {
  headings: {
    date__created: i18n.t("created"),
    date__updated: i18n.t("updated"),
    date__expires: i18n.t("expires"),
    user: i18n.t("user"),
    token_type: i18n.t("token_type"),
    client_id: i18n.t("client_id"),
    access_token: i18n.t("access_token"),
    refresh_token: i18n.t("refresh_token"),
  },
  sortable: [
    "date__created",
    "date__updated",
    "date__expires",
    "token_type",
    "client_id",
    "user",
    "access_token",
    "refresh_token",
  ],
  filterAlgorithm: {
    date__created: (row: Token, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Token, query: string) =>
      dateFilterAlgorithm(row, query, "updated"),
    date__expires: (row: Token, query: string) =>
      dateFilterAlgorithm(row, query, "expires"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: Token, query: string) {
        return [
          row.token_type || "",
          row.client_id || "",
          row.user || "",
          row.access_token || "",
          row.refresh_token || "",
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
          dateToFilterString(row, "expires"),
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

onMounted(() => {
  tokenStore.fetchAll()
})
</script>
