<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('users')"
      :subtitle="$t('platform_oauth2_users')"
      :add-text="$t('add_user')"
      :edit-text="$t('edit_user')"
      :columns="options.columns"
      :action-edit="authStore.authenticatedUser?.is_admin || authStore.authenticatedUser?.is_moderator"
      :show-add-button="authStore.authenticatedUser?.is_admin || authStore.authenticatedUser?.is_moderator"
      :make-form="makeForm"
      :data-filter="options.dataFilter"
      :table-options="options.tableOptions"
      :data="userStore.mappedData"
      :add="userStore.add"
      :update="userStore.update"
      :default-sort="{ column: 'username', ascending: true }"
    >
      <template #organization="{ row }">
        {{ row.organization?.name }}
        <br />
        <div class="text-muted small" style="cursor: pointer">
          <i class="fa fa-copy" />
          {{ row.organization?.id }}
        </div>
      </template>
      <template #roles="{ row }">
        <CBadge v-if="row.is_admin" class="me-1" color="danger">admin</CBadge>
        <CBadge v-if="row.is_moderator" class="me-1" color="warning">moderator</CBadge>
        <CBadge v-if="row.is_customer" class="me-1" color="primary">customer</CBadge>
        <CBadge v-if="row.is_manufacturer" color="info">manufacturer</CBadge>
      </template>
      <template #login="{ row }">
        <Popover
          :text="`${$t('login_as')} ${row.username}`"
        >
          <CButton
            v-if="!row.is_admin && typeof row.organization === 'object'"
            class="btn-space btn-sm white-space__nowrap"
            color="secondary"
            @click.stop="showLoginAs(row)"
          >
            <i class="fa fa-user" />
            {{ $t("login") }}
          </CButton>
        </Popover>
      </template>
    </VTables>
    <ModalWrapper v-model="showLoginAsModal">
      <CModal
        :visible="showLoginAsModal"
        backdrop="static"
        @close="showLoginAsModal = false"
      >
        <CModalHeader>
          <CModalTitle>
            {{ $t("login_as") + " " + selectedUser.username }}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p v-html="$t('login_as_username_confirmation', { msg: selectedUser.username })" />
        </CModalBody>

        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="showLoginAsModal = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" @click="applyLoginAs">
            {{ $t("login") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import type { VTableOptions } from "@/components/VTables.vue"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted } from "vue"
import type { User } from "@/interfaces"
import { userStore, organizationStore, authStore } from "@/store"
import VTables from "@/components/VTables.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"
import {
  booleanCustomSorting,
  dateToFilterString,
  generateFilterAlgorithm,
  getListColumns,
} from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("users") })

let showLoginAsModal = $ref(false)
let selectedUser = $ref(null)

const options = $computed(() => {
  let columns = [
    "username",
    "first_name",
    "organization",
    "roles",
    "boolean__is_active",
    "boolean__is_verified",
    "boolean__is_customer",
    "boolean__is_moderator",
    "boolean__is_manufacturer",
    "boolean__is_internal",
    "boolean__is_admin",
    "date__created",
  ]
  if (authStore.authenticatedUser?.role === "admin") columns.push("login")
  columns.push("actions")

  let dataFilter = d => d
  if (!authStore.authenticatedUser?.is_admin) {
    columns = columns.filter(c => c !== "login")
    if (authStore.authenticatedUser?.is_manufacturer) {
      columns = columns.filter(c => !["is_moderator", "is_manufacturer"].includes(c))
      dataFilter = d =>
        d.is_manufacturer === true &&
        d.is_moderator === false &&
        d.organization?.id === authStore.authenticatedUser?.organization?.id
    } else if (authStore.authenticatedUser?.is_customer) {
      columns = columns.filter(c => !["is_customer", "is_moderator"].includes(c))
      dataFilter = d =>
        d.is_customer === true &&
        d.is_moderator === false &&
        d.organization?.id === authStore.authenticatedUser?.organization?.id
    }
  }
  const tableOptions: VTableOptions = {
    headings: {
      username: i18n.t("username"),
      first_name: i18n.t("name"),
      organization: i18n.t("organization"),
      roles: i18n.t("roles"),
      date__created: i18n.t("created"),
      boolean__is_active: i18n.t("active"),
      boolean__is_verified: i18n.t("verified"),
      boolean__is_customer: i18n.t("customer"),
      boolean__is_moderator: i18n.t("moderator"),
      boolean__is_manufacturer: i18n.t("manufacturer"),
      boolean__is_admin: i18n.t("admin"),
      boolean__is_internal: i18n.t("internal"),
      login: i18n.t("login_as"),
      actions: i18n.t("actions"),
    },
    sortable: columns.filter(column => !["actions", "login"].includes(column)),
    get filterable() {
      return this.sortable
    },
    get filterAlgorithm() {
      return  {
        ...generateFilterAlgorithm(this.filterable, "boolean"),
        ...generateFilterAlgorithm(this.filterable, "date"),
        roles(row: User, query: string) {
          let roles = []
          if (row.is_customer) roles.push("customer")
          if (row.is_moderator) roles.push("moderator")
          if (row.is_manufacturer) roles.push("manufacturer")
          if (row.is_admin) roles.push("admin")
          const str = roles.join("###")
          return str.includes(query.toLowerCase())
        }
      }
    },
    get listColumns() {
      return getListColumns(this.filterable)
    },
    customSorting: {
      boolean__is_active: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_active"),
      boolean__is_verified: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_verified"),
      boolean__is_customer: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_customer"),
      boolean__is_moderator: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_moderator"),
      boolean__is_manufacturer: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_manufacturer"),
      boolean__is_admin: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_admin"),
      boolean__is_internal: (ascending: boolean) => (a: User, b: User) => booleanCustomSorting(ascending, a, b, "is_internal"),
    },
    customFilters: [
      {
        name: "all",
        callback(row: User, query: string) {
          let roles = []
          if (row.is_customer) roles.push("customer")
          if (row.is_moderator) roles.push("moderator")
          if (row.is_manufacturer) roles.push("manufacturer")
          if (row.is_admin) roles.push("admin")
          return [
            [row.username || "", row.id || ""].join("###"),
            [row.last_name || "", row.first_name || ""].join("###"),
            [row.organization?.name || "", row.organization?.id || ""].join("###"),
            roles.join("###"),
            row.is_active,
            row.is_verified,
            row.is_customer,
            row.is_moderator,
            row.is_manufacturer,
            row.is_internal,
            row.is_admin,
            dateToFilterString(row, "created"),
          ]
            .join("###")
            .toLowerCase()
            .includes(query.toLowerCase())
        },
      },
    ],
  }
  return {
    columns,
    dataFilter,
    tableOptions,
  }
})
const makeForm = data => {
  const fields: any = {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    username: {
      type: "text",
      label: i18n.t("username"),
      validations: ["required"],
      defaultValue: data?.username,
      visible: !data?.id,
    },
    first_name: {
      type: "text",
      label: i18n.t("first_name"),
      defaultValue: data?.first_name,
    },
    last_name: {
      type: "text",
      label: i18n.t("last_name"),
      defaultValue: data?.last_name,
    },
    middle_name: {
      type: "text",
      label: i18n.t("middle_name"),
      defaultValue: data?.middle_name,
    },
    email: {
      type: "email",
      label: i18n.t("email"),
      defaultValue: data?.email,
      validations: ["required"],
      visible: !data?.id,
    },
    organization: {
      type: "multiselect",
      label: i18n.t("organization"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.organization?.id || data?.organization,
      validations: ["required"],
      options: organizationStore.all?.map(o => ({
        ...o,
        text: `${o.id} ${o.name ? ` - ${o.name}` : ""}`,
      })),
      mapResultTo: "id",
      selectOptions: {
        multiple: false,
        trackBy: "id",
        label: "text",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
      visible: !data?.id,
    },
    password: {
      type: "text",
      label: i18n.t("password"),
      defaultValue: data?.password,
      validations: !data?.id ? ["required"] : [],
      visible: !data?.id,
    },
    is_active: {
      type: "checkbox",
      label: i18n.t("is_active"),
      defaultValue: data?.is_active,
    },
    is_verified: {
      type: "checkbox",
      label: i18n.t("is_verified"),
      defaultValue: data?.is_verified,
    },
    // this field causing error when sent to the api
    // is_company: {
    //   type: 'checkbox',
    //   label: i18n.t('is_company'),
    //   defaultValue: data?.is_company
    // },
    is_customer: {
      type: "checkbox",
      label: i18n.t("is_customer"),
      defaultValue: data?.is_customer,
    },
    is_moderator: {
      type: "checkbox",
      label: i18n.t("is_moderator"),
      defaultValue: data?.is_moderator,
    },
    is_manufacturer: {
      type: "checkbox",
      label: i18n.t("is_manufacturer"),
      defaultValue: data?.is_manufacturer,
    },
    is_internal: {
      type: "checkbox",
      label: i18n.t("is_internal"),
      defaultValue: data?.is_internal,
    },
    is_admin: {
      type: "checkbox",
      label: i18n.t("is_admin"),
      defaultValue: data?.is_admin,
    },
  }
  if (!authStore.authenticatedUser?.is_admin) {
    fields.organization = {
      type: "hidden",
      defaultValue: authStore.authenticatedUser?.organization?.id,
    }
    fields.is_moderator = {
      type: "hidden",
      defaultValue: false,
    }
    fields.is_admin = {
      type: "hidden",
      defaultValue: false,
    }
    if (authStore.authenticatedUser?.is_manufacturer) {
      fields.is_manufacturer = {
        type: "hidden",
        defaultValue: true,
      }
    } else if (authStore.authenticatedUser?.is_customer) {
      fields.is_manufacturer = {
        type: "hidden",
        defaultValue: false,
      }
      fields.is_customer = {
        type: "hidden",
        defaultValue: true,
      }
    }
  }
  return {
    id: "users-page-form",
    fields,
    valueProcessor: values => {
      if (values.isTrusted) delete values.isTrusted
      if (values._vts) delete values._vts
      if (values?.id) {
        delete values.email
        delete values.username
        delete values.organization
        delete values.password
      }
      return values
    },
  }
}

function showLoginAs(row) {
  selectedUser = row
  showLoginAsModal = true
}

function applyLoginAs() {
  showLoginAsModal = false
  authStore.loginAs(selectedUser.id)
}

onMounted(() => {
  userStore.fetchAll()
})
</script>
