<template>
  <div class="btn_row">
    <Popover
      :text="$t('import_and_merge_data')"
    >
      <CButton
        class="me-2"
        width="100%"
        color="secondary"
        variant="outline"
        :disabled="disableMerger"
        @click="open"
      >
        <i class="fa fa-clone fa-lg" />
      </CButton>
    </Popover>

    <Popover
      :text="$t('export_data')"
    >
      <CDropdown color="secondary">
        <CDropdownToggle
          class="no-caret"
          color="secondary"
          variant="outline"
          :disabled="show"
        >
          <i class="fa fa-download fa-lg" />
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem @click="exportXLSX(exportableData) && close()">
            {{ $t("merge-component-xlsx") }}
          </CDropdownItem>

          <DownloadCSV
            :data="exportableData"
            class="dropdown-item"
            :name="title + '.csv'"
            @click="close"
          >
            {{ $t("merge-component-csv") }}
          </DownloadCSV>
        </CDropdownMenu>
      </CDropdown>
    </Popover>

    <CModal
      size="xl"
      :visible="isVisible"
      backdrop="static"
      @close="close"
    >
      <CModalHeader :close-button="!loading">
        <CModalTitle>{{ $t("import_and_merge_data") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Stepper
          v-model="step"
          class="mb-4 pb-3"
          :items="stepperItems"
          :is-done="step === STEPS.FINISHED"
          :is-finished="checkFinishedSteps"
          :is-current="item => item.key === step"
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
        />

        <template v-if="loading">
          <CProgress v-if="step === STEPS.PROCESSING" class="align-middle me-2 mb-3">
            <CProgressBar
              color="danger"
              variant="striped"
              animated
              :value="settledPercentages.failed"
            />
            <CProgressBar
              color="success"
              variant="striped"
              animated
              :value="settledPercentages.success"
            />
          </CProgress>
          <CSpinner v-else class="align-middle me-2 mb-3" />
          <strong v-if="!totalRequests" class="m-1">
            {{ $t("merge-component-loading") }}
          </strong>
          <strong v-else class="m-1">
            {{ Object.keys(failedRows).length + successfullyChanged }} /
            {{ totalRequests }}
          </strong>
        </template>
        <strong v-if="hint" class="mb-4 d-flex" v-html="hint" />

        <template v-if="step === STEPS.UPLOAD">
          <p v-html="$t('merger_component_explanation_text')" />
          <p v-html="$t('merger_component_warning_text', { idColumn })" />
          <Dropzone
            :options="dropzoneOptions"
            @addedfile="uploadFile"
          />
        </template>

        <template v-else-if="step === STEPS.REVIEW">
          <div
            class="table-container"
          >
            <MergerTable
              :current-data="currentData"
              :uploaded-data="uploadedData"
              :columns="columns"
              :identifier="idColumn"
              :schema="schema"
              :allow-new-row="allowNewRow"
              :custom-validation="customValidation"
              @initialized="onTableInitialized"
            />
          </div>
        </template>

        <template v-else-if="step === STEPS.FINISHED">
          <p
            v-if="!Object.keys(failedRows).length"
            v-html="$t('merger_component_success_text')"
          />
          <div v-else>
            <p
              v-html="
                $t('merger_component_totals_text', {
                  totalSuccess: Object.keys(succeededRows).length,
                  totalFailed: Object.keys(failedRows).length,
                })
              "
            />
            <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>
                    {{ $t("download_report_overview") }}
                  </CTableHeaderCell>
                  <CTableHeaderCell />
                  <CTableHeaderCell />
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableDataCell>CSV</CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="danger"
                      class="finalBtn"
                      @click="exportCSV(formatToExport(Object.values(failedRows)))"
                    >
                      {{ $t("failed") }}
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="success"
                      class="finalBtn"
                      @click="exportCSV(Object.values(succeededRows))"
                    >
                      {{ $t("succeeded") }}
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>XLSX</CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="danger"
                      class="finalBtn"
                      @click="exportXLSX(formatToExport(Object.values(failedRows)))"
                    >
                      {{ $t("failed") }}
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="success"
                      class="finalBtn"
                      @click="exportXLSX(Object.values(succeededRows))"
                    >
                      {{ $t("succeeded") }}
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </div>
        </template>
      </CModalBody>
      <CModalFooter class="d-flex justify-content-between">
        <CButton
          v-show="prevButton.show"
          :color="prevButton.color"
          :disabled="prevButton.disabled"
          @click="prevButton.onClick"
        >
          <i :class="prevButton.iconClass" />
          {{ prevButton.text }}
        </CButton>
        <span v-if="step === STEPS.REVIEW && hasErrors()">
          <CIcon icon="fa-left" />
          &nbsp;{{ $t("there_are_errors") }}&nbsp;
          <CIcon icon="fa-triangle-exclamation" />
        </span>
        <span style="flex-grow: 1" />
        <span v-if="step === STEPS.REVIEW">{{ totalData }} {{ $t("rows") }}</span>
        <CButton
          v-show="nextButton.show"
          :color="nextButton.color"
          :variant="nextButton.variant"
          :disabled="nextButton.disabled"
          @click="nextButton.onClick"
        >
          {{ nextButton.text }}
          <i v-if="nextButton.iconClass" :class="nextButton.iconClass" />
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { omit, cloneDeep } from "lodash-es"
import DownloadCSV from "vue-json-csv"
import * as XLSX from "xlsx"
import Papa from "papaparse"
import { globalStore } from "@/store"
import Dropzone from "../Dropzone.vue"
import Stepper from "../Stepper.vue"
import { sleep, MergerHelpers } from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"
import {
  type MergerSchema, mergerDefaultIdColumn, type MergerRawUploadedRows,
  type MergerParsedUploadedRows
} from "@/interfaces"
import MergerTable from "@/components/table/MergerTable.vue"

const i18n = useI18n()

interface Props {
  title?: string
  idColumn?: string
  setRow: (id: number, data: any) => Promise<any>
  show?: boolean
  canValidate?: boolean
  schema: MergerSchema
  data: any[]
  dataProcessor?: (data: any[]) => any[]
  valueProcessor?: (values: any[]) => any[]
  customValidation?: (value: any, errors: any) => { errors: any; values: any }
  allowNewRow?: boolean
  requestBatch?: number,
  disableMerger?: boolean
}
const {
  title = "data",
  idColumn = mergerDefaultIdColumn,
  setRow,
  show = false,
  canValidate = true,
  schema,
  data,
  dataProcessor,
  valueProcessor,
  customValidation,
  allowNewRow = true,
  requestBatch = 4,
  disableMerger = false
} = defineProps<Props>()
const emit = defineEmits(["close", "open", "done"])

/** Handling Stepper */
const STEPS = $computed(() => ({
  UPLOAD: "UPLOAD",
  REVIEW: "REVIEW",
  PROCESSING: "PROCESSING",
  FINISHED: "FINISHED",
}))
let step = $ref(STEPS.UPLOAD)

function checkFinishedSteps(item: any) {
  const finishedStepsBasedOnCurrentStep = {
    UPLOAD: [],
    REVIEW: [STEPS.UPLOAD],
    PROCESSING: [STEPS.UPLOAD, STEPS.REVIEW],
    FINISHED: [STEPS.UPLOAD, STEPS.REVIEW, STEPS.PROCESSING, STEPS.FINISHED]
  }

  return finishedStepsBasedOnCurrentStep[step].includes(item.key)
}

const hints = {
  REVIEW: i18n.t("double_check_your_changes_before_using_the_next_button"),
  PROCESSING: i18n.t("no_turning_back")
}
const hint = $computed(() => hints[step] || "")
/** END Handling Stepper */

let isSure = $ref(null)
let loading = $ref(false)
let failedRows = $ref({})
let uploadedFile = $ref(null)
let returnedData = $ref({})

let uploadedData = $ref<MergerParsedUploadedRows>(null)
let processedUploadedData = $ref({})
let uploadedDataErrors = $ref({})
let changedData = $ref([])
let totalData = $ref(0)
let validatedData = $ref([])

const currentData = $computed(() => 
  (dataProcessor ? dataProcessor(data) : data).reduce((object, d) => {
    object[d[idColumn]] = d
    return object
  }, {})
)

const formatToExport = (data: any[]) => {
  return data.map(row => {
    if (!row) return row

    // idColumn will be on the 1st column, then next to it is schema keys without the idColumn
    const keys = [idColumn, ...columns.filter(c => c != idColumn)]
    return keys.reduce((result, key) => {
      if (row[key] == null) {
        result[key] = ""
        return result
      }

      switch (schema[key]?.type) {
        case "array":
          result[key] = row[key].join(",")
          break
        case "namedObject":
          result[key] = row[key].name
          break
        case "boolean":
          result[key] = i18n.t(row[key] ? "yes" : "no").toUpperCase()
          break
        case "integer":
        case "number":
          result[key] = row[key].toString()
          break
        default:
          result[key] = row[key]
          break
      }

      return result
    }, {})
  })
}

const exportableData = $computed(() => formatToExport(Object.values(currentData)))

const processedChangedData = $computed(() => {
  const values = changedData.map(d => validatedData[d[idColumn] as number])
  return valueProcessor ? valueProcessor(values) : values
})

const succeededRows = $computed(() => omit(processedUploadedData, Object.keys(failedRows)))
const columns = $computed(() => Object.keys(schema))
const isVisible: boolean = $computed({
  get() {
    return show
  },
  set(value) {
    emit(value === false ? "close" : "open")
  },
})
const dropzoneOptions = $computed(() => ({
  url: window.location.href,
  autoProcessQueue: false,
  acceptedFiles: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".csv",
  ].join(","),
  maxFiles: 1, // only allow 1 file
  maxFilesize: 10, // MB
  dictDefaultMessage: i18n.t("upload_modal_body"),
}))
const stepperItems = $computed(() => [
  {
    key: STEPS.UPLOAD,
    title: i18n.t("upload"),
    description: null,
    icon: "fa fa-cloud-upload",
  },
  {
    key: STEPS.REVIEW,
    title: i18n.t("review"),
    description: null,
    icon: "fa fa-list-alt",
  },
  {
    key: STEPS.PROCESSING,
    title: i18n.t("processing"),
    description: null,
    icon: "fa fa-spinner",
  },
  {
    key: STEPS.FINISHED,
    title: i18n.t("finished"),
    description: null,
    icon: "fa fa-check",
  },
])
const prevButton = $computed(() => ({
  show: [STEPS.REVIEW, STEPS.FINISHED].includes(step),
  text: i18n.t("merge-component-back"),
  disabled: loading,
  color: step === STEPS.REVIEW && hasErrors() ? "primary" : "secondary",
  iconClass: "fa fa-chevron-left me-2",
  onClick: () => (step = STEPS.UPLOAD),
}))
const nextButton = $computed(() => {
  const button = {
    show: step !== STEPS.UPLOAD,
    text: i18n.t("merge-component-next"),
    disabled: loading,
    color: "success",
    variant: null,
    iconClass: "fa fa-chevron-right ms-2",
    onClick: () => {
      next()
    },
  }
  if (step === STEPS.REVIEW) {
    if (changedData.length == 0) {
      button.show = false
    } else if (isSure === false) {
      button.color = "danger"
      button.variant = "outline"
      button.text = i18n.t("merge-component-sure")
      button.onClick = () => (isSure = true && next())
    } else if (isSure === null) {
      button.show = !hasErrors()
      button.onClick = () => {
        isSure = false
      }
    }
  }
  if (step === STEPS.PROCESSING) {
    button.show = false
  }
  if (step === STEPS.FINISHED) {
    button.onClick = () => close()
    button.text = i18n.t("merge-component-finish")
    button.iconClass = "fa fa-check ms-2"
  }
  return button
})

function init() {
  step = STEPS.UPLOAD
  isSure = null
  loading = false
  uploadedData = null
  uploadedDataErrors = {}
  failedRows = {}
  returnedData = {}
}

function hasErrors(): boolean {
  for (const id in uploadedDataErrors) {
    const row = uploadedDataErrors[id]
    if (!row) continue
    if (Object.keys(row).length) return true
  }
  return false
}

function open() {
  init()
  emit("open")
}

let successfullyChanged = $ref(0)
let totalRequests = $ref(0)

const settledPercentages = $computed(() => ({
  success: (successfullyChanged / totalRequests) * 100,
  failed: (Object.keys(failedRows).length / totalRequests) * 100,
}))

const doRequests = async (
  rows: Record<string, unknown>[]
): Promise<Record<"failed" | "success", Record<string, unknown>[]>> => {
  totalRequests = rows.length
  const firstChunk = rows.splice(0, requestBatch)

  const toRequest = row =>
    row &&
    setRow(row[idColumn], row)
      .then((data) => (successfullyChanged++, delete failedRows[row[idColumn]], returnedData[row[idColumn]] = data))
      .catch(() => (failedRows[row[idColumn]] = row, delete returnedData[row[idColumn]]))
      .then(() => sleep(10).then(() => toRequest(rows.shift())))

  await Promise.allSettled(firstChunk.map(toRequest))

  return { failed: Object.values(failedRows), success: Object.values(returnedData) }
}

async function next() {
  const nextSteps = {
    UPLOAD: STEPS.REVIEW,
    REVIEW: STEPS.PROCESSING,
    PROCESSING: STEPS.FINISHED,
    FINISHED: STEPS.FINISHED
  }
  step = nextSteps[step]
  if (step === STEPS.PROCESSING) {
    failedRows = {}
    returnedData = {}
    successfullyChanged = 0
    totalRequests = 0

    loading = true
    
    await sleep(10) // This line allows the UI to actually update
    var { failed, success } = await doRequests(cloneDeep(processedChangedData))
    let retries = 0

    while (failed.length && retries++ < 3) {
      await sleep(3000)
      var { failed, success } = await doRequests(failed)
    }
    
    loading = false
    emit("done", success)
    next()
  }
}
function close() {
  init()
  emit("close")
}
function uploadFile(file) {
  loading = true
  uploadedFile = file
  const extension = file.name.split(".").pop().toLowerCase()

  const reader = new FileReader()
  reader.onload = function (event) {
    const file = event.target.result

    if (extension === "xlsx") {
      parseXLSX(file).then(result => {
        parseFileData(result)
      }).catch()
    } else if (extension === "csv") {
      parseCSV(file).then(result => {
        parseFileData(result)
      }).catch()
    }
  }

  extension === "xlsx" ? reader.readAsArrayBuffer(file) : reader.readAsText(file)
}
function parseFileData(values: MergerRawUploadedRows) {
  // Sometimes when a user deletes rows in excel,
  // the rows still exist in the file, but they're just empty.
  // With this code we just make sure that we ignore rows like that
  values = values.filter(
    columns => !Object.values(columns).every(v => v == null || v === "")
  )

  const newIds = values.map(row => +row[idColumn]).sort()
  const oldIds = Object.keys(currentData)
    .map(id => +id)
    .sort()

  // TODO: figure out why xlsx exported by the Apple Numbers app
  // completely fucks up the ids of many rows. For now
  // it means Apple Numbers is unusable with .xlsx files.

  uploadedData = values.reduce((obj, row) => {
    const id = +row[idColumn] || Math.round(Math.random() * 1e16)
    obj[id] = { ...row, [idColumn]: id }
    return obj
  }, {} as MergerParsedUploadedRows)
  loading = false
  next()
}
function parseXLSX(file) {
  return new Promise<MergerRawUploadedRows>((resolve, reject) => {
    const res = XLSX.read(file)
    const sheet = Object.values(res.Sheets)[0]
    if (!sheet) reject(i18n.t("no_sheet_found"))
    resolve(
      XLSX.utils.sheet_to_json(sheet, {
        blankrows: false,
        defval: null,
        raw: true,
        rawNumbers: true,
      })
    )
  })
}
function exportXLSX(values) {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(values, {
    WTF: true,
    cellStyles: true,
  })
  XLSX.utils.book_append_sheet(wb, ws, title)
  XLSX.writeFile(wb, `${title}.xlsx`)
  return true
}
function parseCSV(values) {
  return new Promise<MergerRawUploadedRows>((resolve, reject) => {
    Papa.parse(values, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: results => {
        const theData = []
        if (results.data) {
          results.data.forEach(row => {
            const result = {}
            Object.keys(row).forEach(item => {
              result[item.toLowerCase()] = row[item]
            })
            theData.push(result)
          })
        }
        resolve(theData)
      },
      error(error) {
        reject(error)
      },
    })
  })
}
function exportCSV(rawData) {
  const results = []
  const headers = []
  Object.values(rawData).forEach(item => {
    const result = []
    // Assign data
    Object.keys(item).forEach(x => result.push(item[x]))

    results.push(result)
    // Create headers
    Object.keys(item).forEach(x => {
      if (!headers.includes(x)) {
        headers.push(x)
      }
    })
  })
  const rows = [headers, ...results]
  let csvContent = "data:text/csv;charset=utf-8,"
  csvContent += [...rows.map(item => Object.values(item).join(";"))]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "")

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", `${title}.csv`)
  document.body.appendChild(link) // Required for FF
  link.click()
  document.body.removeChild(link)
}

function onTableInitialized(
  data: Record<"processedUploadedData" | "errors" | "changedData" | "data" | "validatedData", any>
) {
  processedUploadedData = data.processedUploadedData
  uploadedDataErrors = data.errors
  changedData = data.changedData
  totalData = data.data.length
  validatedData = data.validatedData
}
</script>

<style lang="scss" scoped>
.btn_row {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

table ul.dashed {
  margin: 0;
  padding-left: 0.5rem;
  list-style: none;

  & > li:before {
    content: "-";
    margin-right: 0.25rem;
  }
}

.changed {
  background: skyblue;
}

.canValidate .changed {
  background: lightgreen;
}

.center_row {
  flex: 1;
  justify-content: center;
}

.valid {
  border: 2px solid green;
  background-color: lightgreen;
}

.invalid {
  border: 2px solid red;
  background-color: salmon;
}

.unknown {
  border: 2px solid lightslategray;
  background-color: lightness($color: lightgray);
}

.finalBtn,
.exportBtn {
  width: 100%;
}

.modal-title {
  width: 100% !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
.table-container {
  overflow-x: auto;
  max-height: 80vh;
}
</style>