<template>
  <CContainer fluid>
    <h3 class="mb-4">{{ $t("order_summary") }}</h3>
    <OrderWidgets />
  </CContainer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { orderStore } from '@/store'
import OrderWidgets from '@/components/OrderWidgets.vue'

export default defineComponent({
  name: "Dashboard",
  components: {
    OrderWidgets
  },
  mounted() {
    orderStore.getSummary()
  },
  setup() {
    const i18n = useI18n()
    useMeta({ title: i18n.t("dashboard") })
  },
})
</script>
