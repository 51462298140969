<template>
  <div class="customer-multiple-picker-modal">
    <ModalWrapper
      v-model="isVisible"
    >
      <CModal
        :visible="isVisible"
        backdrop="static"
        @close="() => (isVisible = false)"
      >
        <CModalHeader>
          <CModalTitle>{{ $t("pick_pricing") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow class="mb-2">
            <CCol :sm="7">
              <CFormInput
                id="pricing-picker-search"
                v-model="search"
                :placeholder="$t('search')"
                aria-label="Search"
              />
            </CCol>
            <CCol :sm="5" class="d-grid">
              <CButton
                color="success"
                @click="() => (showForm = true)"
              >
                <i class="fa fa-plus" />
                {{ $t("add_new_pricing") }}
              </CButton>
            </CCol>
          </CRow>
          <CRow
            v-if="filteredDatas.length > 0"
            style="max-height: 75vh; overflow-y: scroll"
          >
            <CCol>
              <ClickableCard
                v-for="(pricing, index) in filteredDatas"
                :key="index"
                :data="pricing"
                class="mt-2"
                :color="inputSelected === pricing.id ? 'primary' : 'secondary'"
                @click="toggle(getId(pricing))"
              >
                <CRow>
                  <CCol
                    class="d-flex flex-column justify-content-start"
                  >
                    <div v-if="pricing.startup_amount" class="mb-1 small">
                      <span class="me-2">{{ $t("startup_amount") }}:</span>
                      <span>{{ pricing.startup_amount }}</span>
                    </div>
                    <div v-if="pricing.discount_amount" class="mb-1 small">
                      <span class="me-2">{{ $t("discount_amount") }}:</span>
                      <span>{{ pricing.discount_amount }}</span>
                    </div>
                    <div v-if="pricing.shipping_amount" class="small">
                      <span class="me-2">{{ $t("shipping_amount") }}:</span>
                      <span>{{ pricing.shipping_amount }}</span>
                    </div>
                    <div v-if="pricing.tax_rate" class="small">
                      <span class="me-2">{{ $t("tax_rate") }}:</span>
                      <span>{{ pricing.tax_rate }}</span>
                    </div>
                  </CCol>
                </CRow>
              </ClickableCard>
            </CCol>
          </CRow>
          <CRow v-if="filteredDatas.length == 0" class="py-3">
            <CCol>
              <div class="text-muted text-center">
                {{ $t("no_results") }}
              </div>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" @click="save">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>

    <PricingFormModal
      :show="showForm"
      @close="() => (showForm = false)"
      @submit="pricingStore.add"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ModalWrapper from "@/components/modals/ModalWrapper.vue"
import { pricingStore } from '@/store'
import ClickableCard from '@/components/ClickableCard.vue'
import { cloneDeep } from 'lodash-es'
import { getId } from "@/interfaces"
import PricingFormModal from "./PricingFormModal.vue"

export default defineComponent({
  name: "PricingPickerModal",
  components: {
    ModalWrapper,
    ClickableCard,
    PricingFormModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Number,
      required: false
    }
  },
  emits: ["update:modelValue", "save"],
  data() {
    return {
      inputSelected: null,
      search: "",
      showForm: false
    }
  },
  computed: {
    isVisible: {
      get() {
        return this.modelValue && !this.showForm
      },
      set(v: boolean) {
        this.$emit("update:modelValue", v)
      }
    },
    filteredDatas() {
      return pricingStore.all.filter((pricing) => {
        return (
          (pricing.startup_amount || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (pricing.discount_amount || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (pricing.shipping_amount || "").toLowerCase().includes(this.search.toLowerCase()) ||
          (pricing.tax_rate || "").toString().toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },
  },
  watch: {
    isVisible() {
      /* 
        Have to be put under $nextTick to avoid values changes during save
        Have to be cloneDeep to avoid reactivity and change parent values
      */
      this.$nextTick(() => {
        this.inputSelected = cloneDeep(this.selected)
        this.search = "" // reset search
      })
    },
  },
  methods: {
    toggle(pricingId: number) {
      this.inputSelected = !this.inputSelected || this.inputSelected !== pricingId ? pricingId : null
    },
    save() {
      this.$emit("save", this.inputSelected)
      this.isVisible = false
    }
  },
  setup() {
    return {
      getId,
      pricingStore
    }
  },
})
</script>